import { forwardRef, useId, useRef, useState } from "react";
import { TypographyMuted, TypographyP } from "../ui/typography";
import { FileUpIcon, ImagePlusIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

interface Props {
  onFilesSelected: (files: File[]) => void;
  maxFileSizeMB?: number;
  multiple?: boolean;
  accept?: string[];
  uploadType?: "file" | "image";
}

function mbToBytes(mb: number) {
  return mb * 1024 * 1024;
}
export const FileUploadInput = forwardRef<HTMLInputElement, Props>(
  ({ multiple, accept, onFilesSelected, uploadType = "file", maxFileSizeMB = 50 }, ref) => {
    const { t } = useTranslation();
    const [dragCount, setDragCount] = useState(0);
    const hiddenFileInputRef = useRef<HTMLInputElement>(null);

    function validateFile(file: File | null): file is File {
      if (!file) return false;

      const hasValidType =
        !accept ||
        accept.some((type) => file.type.startsWith(type.replace("*", ""))) ||
        accept.some((type) => file.name.endsWith(type));
      if (!hasValidType) {
        toast.error(`${file.name}: ${t("invalid_file_type")}`);
        return false;
      }
      const hasValidSize = file.size <= mbToBytes(maxFileSizeMB);
      if (!hasValidSize) {
        const currentFileSizeMB = (file.size / mbToBytes(1)).toFixed(1);
        toast.error(
          `${file.name}: ${t("invalid_file_size", { maxFileSizeMB, fileSizeMB: currentFileSizeMB })}`
        );
        return false;
      }
      return hasValidType && hasValidSize;
    }

    const id = useId();

    return (
      <div className="no-drag">
        {/* Add a hidden input so we can trigger a .focus() call from outside */}
        <input className="h-0 w-0 opacity-0" ref={ref} />
        <input
          type="file"
          accept={accept?.length ? accept.join(",") : undefined}
          multiple={multiple}
          className="hidden"
          id={id}
          ref={hiddenFileInputRef}
          onChange={(ev) => {
            onFilesSelected(Array.from(ev.target.files!).filter(validateFile));
            hiddenFileInputRef.current!.value = "";
          }}
        />

        <label
          htmlFor={id}
          className="no-drag h-32 w-full cursor-pointer p-0"
          data-testid="file-upload-input"
          onDragOver={(ev) => {
            ev.preventDefault();
          }}
          onDrop={(ev) => {
            ev.preventDefault();

            if (ev.dataTransfer.items) {
              // Use DataTransferItemList interface to access the file(s)
              const files = [...ev.dataTransfer.items]
                .map((item) => {
                  // If dropped items aren't files, reject them
                  if (item.kind === "file") {
                    const file = item.getAsFile();
                    return file;
                  }
                  return null;
                })
                .filter(validateFile);

              if (files.length > 0) onFilesSelected(files);
            } else {
              // Use DataTransfer interface to access the file(s)
              const files = [...ev.dataTransfer.files].filter(validateFile);
              if (files) onFilesSelected(files);
            }
            setDragCount(0);
          }}
          onDragEnter={() => {
            setDragCount((cnt) => cnt + 1);
          }}
          onDragLeave={() => {
            setDragCount((cnt) => cnt - 1);
          }}
        >
          <div
            className={cn(
              "flex h-full w-full flex-col items-center justify-center rounded-md border-2 border-dashed py-2",
              dragCount > 0 ? "border-primary/70" : "border-primary/20"
            )}
          >
            {uploadType === "file" && <FileUpIcon className="h-12 w-12" strokeWidth={1} />}
            {uploadType === "image" && <ImagePlusIcon className="h-12 w-12" strokeWidth={1} />}
            <TypographyP className="text-sm font-semibold">
              {uploadType === "image" && (
                <span className="text-blue-700">
                  {t("upload_image", { count: multiple ? 2 : 1 })}
                </span>
              )}
              {uploadType === "file" && (
                <span className="text-blue-700">
                  {t("upload_file", { count: multiple ? 2 : 1 })}
                </span>
              )}
              <span> {t("upload_or_drag_and_drop")}</span>
            </TypographyP>
            <TypographyMuted className="text-sm">
              {t("max_file_size", { size: `${maxFileSizeMB}MB` })}
            </TypographyMuted>
          </div>
        </label>
      </div>
    );
  }
);
