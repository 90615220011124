import * as z from "zod";

export const sysadminUsersInsertInputSchema = z.object({
  isSysAdmin: z.boolean(),
  firstName: z.string().min(1).max(250).trim().optional(),
  lastName: z.string().min(1).max(250).trim().optional(),
  email: z.string().email().toLowerCase().trim(),
  language: z.enum(["en", "no"]).optional(),
});

export const sysadminUsersInsertOutputSchema = z.object({
  userId: z.string().uuid(),
});
