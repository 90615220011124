import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { tenantLocationsInsertInputSchema } from "@timp/server/src/schemas/tenant-locations-insert.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "@/components/buttons/link-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { Page, PageTitle, PageToolbar, PageContent } from "@/components/layouts/page";

export function TenantSettingsLocationsCreatePage() {
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.tenantLocationsInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const form = useForm<z.infer<typeof tenantLocationsInsertInputSchema>>({
    resolver: zodResolver(tenantLocationsInsertInputSchema),
  });

  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle backLink>{t("location_create")}</PageTitle>
      </PageToolbar>
      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) =>
              mutate({
                ...data,
                tenantId,
              })
            )}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("location_name")}</FormLabel>
                  <FormControl>
                    <Input placeholder="Demo" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={isLoading} type="submit">
                {t("location_create")}
              </Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
