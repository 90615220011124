import { cn } from "@/lib/utils";
import { Link } from "@/components/links/Link";

interface Props {
  to: string;
  children: React.ReactNode;
  className?: string;
  target?: string;
}
export function DataTableLink({ to, children, className, target, ...props }: Props) {
  return (
    <Link {...props} to={to} className={cn("hover:underline", className)} target={target}>
      {children}
    </Link>
  );
}
