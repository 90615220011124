import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { trpc } from "@/lib/providers/trpc";
import { useForm } from "react-hook-form";
import { usersLoginInputSchema } from "@timp/server/src/schemas/users-login.schema";
import type { z } from "zod";
import { Trans, useTranslation } from "react-i18next";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Separator } from "@/components/ui/separator";
import { useNavigate, useSearchParams } from "react-router-dom";

export function LoginPage() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const { mutate, isLoading } = trpc.usersLogin.useMutation({
    onSuccess: ({ challengeId }) => {
      navigate(`/auth/verify/${challengeId}`, { replace: true });
    },
  });

  const form = useForm<z.infer<typeof usersLoginInputSchema>>({
    resolver: zodResolver(usersLoginInputSchema),
    defaultValues: {
      email: params.get("prefill") ?? "",
    },
  });

  return (
    <main className="flex flex-col gap-6 p-4">
      <div className="flex flex-col gap-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">{t("login")}</h1>
        <p className="text-sm text-muted-foreground">{t("enter_your_email")}</p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit((data) => mutate(data))}>
          <div className="flex flex-col gap-4">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("email")}</FormLabel>
                  <FormControl>
                    <Input placeholder="name@example.com" {...field} type="email" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button isLoading={isLoading}>{t("login")}</Button>
          </div>
        </form>
      </Form>
      <Separator />

      <p className="px-8 text-center text-sm text-muted-foreground">
        <Trans i18nKey="tos_privacy_warning">
          By clicking continue, you agree to our
          <a
            href="https://www.timp.no/Brukervilkår/"
            className="underline underline-offset-4 hover:text-primary"
          >
            Terms of Service
          </a>
          <a
            href="https://www.timp.no/personvern/"
            className="underline underline-offset-4 hover:text-primary"
          >
            Privacy Policy
          </a>
        </Trans>
      </p>
    </main>
  );
}
