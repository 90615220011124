import { Circle } from "lucide-react";
import { RelativeDate } from "./relative-date";
import { cn } from "@/lib/utils";
import { TypographyMuted } from "./typography";

type TimelineItemProps = {
  date?: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  isFirst: boolean;
  isLast: boolean;
  icon?: React.ReactNode;
};

export function TimelineItem({
  date,
  title,
  description,
  isFirst,
  isLast,
  icon,
}: TimelineItemProps) {
  const Icon = icon;
  return (
    <div className="flex w-full items-center">
      <div className="relative flex min-h-14 w-full items-center">
        {/* Separator */}
        {!(isFirst && isLast) && (
          <div
            className={cn(
              "absolute left-4 z-20 w-0.5 -translate-x-1/2 bg-primary/20",
              !isFirst && !isLast && "h-full",
              isFirst && "bottom-0 top-1/2",
              isLast && "bottom-1/2 top-0"
            )}
          />
        )}

        {/* Circle */}
        <div className="absolute left-0 z-30 flex size-8 items-center justify-center rounded-full bg-background">
          {Icon || <Circle className="size-6 text-primary" strokeWidth={3} />}
        </div>

        {/* Content */}
        <div className="my-2 ml-12 flex w-full flex-col gap-y-2 rounded-lg bg-muted p-3 dark:bg-primary-foreground">
          <div className="flex flex-wrap-reverse items-center justify-between gap-y-2">
            <h4 className="mb-1 text-sm font-medium text-foreground">{title}</h4>
            {date && (
              <TypographyMuted>
                <RelativeDate date={date} className="text-sm" />
              </TypographyMuted>
            )}
          </div>
          <div>
            {typeof description === "string" ? (
              <p className="text-sm leading-snug text-muted-foreground">{description}</p>
            ) : (
              description
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface TimelineProps {
  children: React.ReactNode;
}

export function Timeline({ children }: TimelineProps) {
  return (
    <div className="h-full w-full">
      <div className="wrap relative h-full overflow-hidden">{children}</div>
    </div>
  );
}
