import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { type RouterInput, trpc } from "@/lib/providers/trpc";
import { useForm } from "react-hook-form";
import { usersCreateProfileInputSchema } from "@timp/server/src/schemas/users-create-profile.schema";
import type { z } from "zod";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

type Language = RouterInput["usersCreateProfile"]["language"];
export function CreateProfilePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();

  const user = trpc.usersMe.useQuery();

  useEffect(() => {
    if (!user.isFetching) {
      // Redirect to home if user is already logged in and user has a profile
      if (user.data?.profile) {
        navigate("/", { replace: true });
      } else if (!user.data || user.error) {
        navigate("/auth/login", { replace: true });
      }
    }
  }, [user.data, user.error, navigate, user.isFetching]);

  const { mutate: insertUser, isLoading } = trpc.usersCreateProfile.useMutation({
    onSuccess: () => navigate("/tenants", { replace: true }),
  });

  const { mutate: logout, isLoading: logoutIsLoading } = trpc.usersLogout.useMutation({
    onSuccess: async () => {
      await queryClient.cancelQueries();
      queryClient.removeQueries();
      navigate("/auth/login", { replace: true });
    },
    onError: () => navigate("/auth/login", { replace: true }),
  });

  const form = useForm<z.infer<typeof usersCreateProfileInputSchema>>({
    resolver: zodResolver(usersCreateProfileInputSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      language: i18n.language as Language,
    },
  });

  useEffect(() => {
    form.setValue("language", i18n.language as Language);
  }, [form, i18n.language]);

  return (
    <main>
      <div className="flex flex-col space-y-2 text-center">
        <h1 className="text-2xl font-semibold tracking-tight">{t("finish_profile")}</h1>
      </div>
      <div className="grid gap-6">
        <Form {...form}>
          <form onSubmit={form.handleSubmit((data) => insertUser(data))}>
            <div className="flex items-center justify-center">
              <div className="grid w-full gap-1">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t("first_name")}</FormLabel>
                      <FormControl>
                        <Input placeholder="Ola" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t("last_name")}</FormLabel>
                      <FormControl>
                        <Input placeholder="Nordmann" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="language"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel required>{t("language")}</FormLabel>
                      <Select
                        onValueChange={(value) => {
                          i18n.changeLanguage(value);
                          field.onChange(value);
                        }}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="en">{t("language_english")}</SelectItem>
                          <SelectItem value="no">{t("language_norwegian")}</SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="mt-4 flex justify-end">
                  <Button
                    variant="link"
                    type="button"
                    isLoading={logoutIsLoading}
                    onClick={() => logout()}
                  >
                    {t("logout")}
                  </Button>
                  <Button isLoading={isLoading}>{t("continue")}</Button>
                </div>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </main>
  );
}
