import { ExternalLinkButton, LinkButton } from "@/components/buttons/link-button";
import {
  type InspectionStatus,
  InspectionStatusIconLabel,
} from "@/components/labels/inspection-status-icon-label";
import { Page, PageTitle, PageToolbar, PageActions, PageContent } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { Badge } from "@/components/ui/badge";
import { DataTableLink } from "@/components/ui/data-table-link";
import { RelativeDate } from "@/components/ui/relative-date";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";

export function InspectionsPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();

  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("supplier_inspections")}</PageTitle>

        <PageActions>
          <LinkButton variant="outline" to={"../settings/inspection-forms"}>
            {t("manage_form_templates")}
          </LinkButton>
          <ExternalLinkButton target="_blank" href={`/inspector/tenants/${tenantId}/forms`}>
            {t("create_inspection")}
          </ExternalLinkButton>
        </PageActions>
      </PageToolbar>

      <PageContent>
        <ServerDataTable
          columnsStorageKey="tenant-inspections-page"
          query={trpc.tenantInspectionsList}
          params={{
            tenantId,
          }}
          searchColumns={["searchTags", "formTemplateName", "supplierName"]}
          columns={[
            {
              id: "formTemplateName",
              title: t("form_name"),
              filter: true,
              enableSorting: false,
              render(row) {
                return <DataTableLink to={`./${row.id}`}>{row.formTemplateName}</DataTableLink>;
              },
            },
            {
              id: "supplierName",
              title: t("supplier"),
              enableSorting: true,
              filter: true,
              render(row) {
                return (
                  <DataTableLink to={`../suppliers/${row.supplierId}/inspections`}>
                    {row.supplierName}
                  </DataTableLink>
                );
              },
            },
            {
              id: "departmentName",
              title: t("department"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "projectName",
              title: t("project"),
              filter: true,
              enableSorting: false,
            },

            {
              id: "locationName",
              title: t("location"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "equipmentName",
              title: t("equipment"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "status",
              title: t("status"),
              filter: true,
              enableSorting: false,
              render: (row) => <InspectionStatusIconLabel status={row.status} />,
              valueToLabel: (status: InspectionStatus) => t(`${status}`),
            },
            {
              id: "deviationsCount",
              title: t("deviations"),
              render: (row) => {
                if (row.deviationsCount) {
                  return (
                    <Badge variant="secondary">
                      {t("deviations_count", { count: row.deviationsCount })}
                    </Badge>
                  );
                }
                return null;
              },
            },
            {
              id: "processedAt",
              title: t("processed_at"),
              render: (row) => {
                if (!row.processedAt) return null;
                return <RelativeDate date={row.processedAt} />;
              },
              timeFilter: true,
              enableSorting: true,
            },
            {
              id: "createdByInspectorName",
              title: t("INSPECTOR"),
              filter: true,
              enableSorting: false,
            },

            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
              timeFilter: true,
              enableSorting: true,
            },
          ]}
          defaultSortColumn="createdAt"
          defaultSortOrder="desc"
          paginationPageSize={50}
        />
      </PageContent>
    </Page>
  );
}
