import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../ui/dialog";
import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { trpc } from "@/lib/providers/trpc";
import { useTenant, useTenantIdSafe } from "@/hooks/useTenant";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormControl, FormField, FormItem, FormLabel } from "../ui/form";
import { useDateFns } from "@/hooks/useDateFns";
import { useSearchParams } from "react-router-dom";
import { DataTable } from "../tables/data-table";
import { Checkbox } from "../ui/checkbox";
import { type CaseStatus, CaseStatusIconLabel } from "../labels/case-status-icon-label";
import { RelativeDate } from "../ui/relative-date";
import { TypographyH4, TypographyMuted, TypographyP, TypographySmall } from "../ui/typography";
import { TypographyH1, TypographyH2 } from "../ui/typography";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/table";
import { SupplierStatusIconLabel } from "../labels/supplier-status-icon-label";
import { DataTableLink } from "../ui/data-table-link";
import { RichTextEditor } from "../rich-text-editor/rich-text-editor";
import { Portal } from "@radix-ui/react-portal";
import { toast } from "sonner";
import { CheckIcon, ExternalLinkIcon } from "lucide-react";
import {
  type InspectionStatus,
  InspectionStatusIconLabel,
} from "../labels/inspection-status-icon-label";
import { LinkButton } from "../buttons/link-button";
import { Link } from "../links/Link";
import { Label } from "../ui/label";

const formSchema = z.object({
  caseIds: z.array(z.string().uuid()),
  documentIds: z.array(z.string().uuid()),
  inspectionIds: z.array(z.string().uuid()),
});

type FormValues = z.infer<typeof formSchema>;

interface SupplierStatusReportDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

enum StatusReportStep {
  INTRO = 0,
  ROUTINE_DESCRIPTION = 1,
  DOCUMENTS = 2,
  CASES = 3,
  INSPECTIONS = 4,
  SUMMARY = 5,
}
const LAST_STEP_COUNT = StatusReportStep.SUMMARY;
const EDITABLE_STEP_COUNT = 4; // number of steps that can be edited (we explicitly want to exclude INTRO and SUMMARY)

function useParsedSearchParams() {
  const [searchParams] = useSearchParams();

  return {
    exportType: searchParams.get("exportType") || null,
    caseIds: searchParams.get("caseIds")?.split(",") || null,
    documentIds: searchParams.get("documentIds")?.split(",") || null,
    inspectionIds: searchParams.get("inspectionIds")?.split(",") || null,
    defaultStep: searchParams.get("exportType") ? StatusReportStep.SUMMARY : StatusReportStep.INTRO,
  };
}

const InfoItem = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-col gap-y-2">
    <TypographyMuted className="text-sm font-medium text-muted-foreground">{label}</TypographyMuted>
    <TypographySmall className="text-base">{value}</TypographySmall>
  </div>
);

export function GenerateSupplierStatusReportPdfContent({
  open,
  setOpen,
}: SupplierStatusReportDialogProps) {
  const { caseIds, documentIds, inspectionIds, defaultStep, exportType } = useParsedSearchParams();
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const [currentStep, setCurrentStep] = useState<StatusReportStep>(
    defaultStep || StatusReportStep.INTRO
  );

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      caseIds: [],
      documentIds: [],
      inspectionIds: [],
    },
    values: {
      // try to use caseIds from search params, otherwise default to no cases
      caseIds: caseIds || [],
      // try to use documentIds from search params, otherwise default to no documents
      documentIds: documentIds || [],
      // try to use inspectionIds from search params, otherwise default to no inspections
      inspectionIds: inspectionIds || [],
    },
  });

  function onDialogOpenChange(val: boolean) {
    setOpen(val);
    if (!val) {
      // when dialog closes, reset form and step
      form.reset({
        caseIds: [],
        documentIds: [],
        inspectionIds: [],
      });
      setCurrentStep(StatusReportStep.INTRO);
    }
  }

  const exportMutation = trpc.tenantReportStatusExport.useMutation({
    onSuccess(data) {
      // first, download the file
      const link = document.createElement("a");
      link.href = data.content;
      link.download = data.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // then, close the dialog
      onDialogOpenChange(false);
    },
  });

  async function onSubmit(values: FormValues) {
    const isOnLastStep = currentStep === LAST_STEP_COUNT;

    // only export if on last step, otherwise we want to go to next step
    if (isOnLastStep) {
      exportMutation.mutate({
        tenantId,
        caseIds: values.caseIds,
        documentIds: values.documentIds,
        inspectionIds: values.inspectionIds,
      });
    } else {
      handleNext();
    }
  }

  async function handleNext() {
    switch (currentStep) {
      case StatusReportStep.INTRO:
        form.trigger().then((result) => {
          if (result) {
            setCurrentStep((prev) => prev + 1);
          }
        });
        break;
      case StatusReportStep.ROUTINE_DESCRIPTION:
        setCurrentStep((prev) => prev + 1);
        break;
      case StatusReportStep.DOCUMENTS:
        form.trigger().then((result) => {
          if (result) {
            setCurrentStep((prev) => prev + 1);
          }
        });
        break;
      case StatusReportStep.CASES:
        form.trigger(["caseIds"]).then((result) => {
          if (result) {
            setCurrentStep((prev) => prev + 1);
          }
        });
        break;
      case StatusReportStep.INSPECTIONS:
        form.trigger().then((result) => {
          if (result) {
            setCurrentStep((prev) => prev + 1);
          }
        });
        break;
    }
  }

  const getStepContent = (step: StatusReportStep) => {
    switch (step) {
      case StatusReportStep.INTRO:
        return <DescriptionStep />;
      case StatusReportStep.ROUTINE_DESCRIPTION:
        return <RoutineDescriptionStep />;
      case StatusReportStep.CASES:
        return <CasesStep form={form} />;
      case StatusReportStep.DOCUMENTS:
        return <DocumentsStep form={form} />;
      case StatusReportStep.INSPECTIONS:
        return <InspectionsStep form={form} />;
      case StatusReportStep.SUMMARY:
        return <SummaryStep form={form} />;
      default: {
        const _exhaustiveCheck: never = step;
        return _exhaustiveCheck;
      }
    }
  };

  const getStepDescription = (step: StatusReportStep): string | null => {
    switch (step) {
      case StatusReportStep.INTRO:
        return null;
      case StatusReportStep.ROUTINE_DESCRIPTION:
        return t("supplier_status_report_routine_description");
      case StatusReportStep.CASES:
        return t("supplier_status_report_cases_description");
      case StatusReportStep.DOCUMENTS:
        return t("supplier_status_report_documents_description");
      case StatusReportStep.INSPECTIONS:
        return t("supplier_status_report_inspections_description");
      case StatusReportStep.SUMMARY:
        return t("supplier_status_report_summary_description");
      default: {
        const _exhaustiveCheck: never = step;
        return _exhaustiveCheck;
      }
    }
  };
  const isFirstStep = currentStep === StatusReportStep.INTRO;
  const isLastStep = currentStep === LAST_STEP_COUNT;

  return (
    <Dialog open={open} onOpenChange={onDialogOpenChange}>
      <DialogContent className="sm:max-w-[1200px] h-screen md:max-h-[80vh] flex flex-col justify-between overflow-y-hidden flex-1">
        <div className="flex flex-col flex-1 overflow-y-hidden">
          <DialogHeader className="space-y-3">
            {isFirstStep && (
              <img src="/logo-arbeidstilsynet.svg" alt="Arbeidstilsynet" className="w-48 h-auto" />
            )}
            {!isFirstStep && !isLastStep && (
              <DialogTitle>
                {t("supplier_status_report")}
                {` (${currentStep}/${EDITABLE_STEP_COUNT})`}
              </DialogTitle>
            )}
            {isLastStep && <DialogTitle>{t("supplier_status_report")}</DialogTitle>}
            <DialogDescription>{getStepDescription(currentStep)}</DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <div className="overflow-y-hidden flex flex-1 flex-col mt-4">
              {getStepContent(currentStep)}
            </div>
          </Form>
        </div>
        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={() => setCurrentStep((prev) => prev - 1)}
            disabled={isFirstStep}
          >
            {t("back")}
          </Button>
          {!isLastStep && (
            <Button
              type="button"
              onClick={handleNext}
              disabled={isLastStep && exportMutation.isLoading}
            >
              {t("next")}
            </Button>
          )}
          {isLastStep && (
            <Button
              type="button"
              onClick={() =>
                form.trigger().then((isValid) => {
                  if (isValid) {
                    form.handleSubmit(onSubmit)();
                  }
                })
              }
              isLoading={exportMutation.isLoading}
            >
              {t("generate_report")}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
      {exportType ? <PdfContent form={form} /> : null}
    </Dialog>
  );
}

function RoutineDescriptionStep() {
  const { t } = useTranslation();
  const tenant = useTenant();
  const tenantId = useTenantIdSafe();
  return (
    <div className="flex flex-col h-full">
      <div className="flex-1 min-h-0">
        <div className="bg-gray-100 p-2 h-full overflow-y-auto">
          {tenant.data?.routineDescription && (
            <RichTextEditor
              key={tenant.dataUpdatedAt}
              initialValue={tenant.data?.routineDescription ?? []}
              readOnly={true}
            />
          )}
        </div>
      </div>
      <LinkButton
        to={`/tenants/${tenantId}/settings/edit`}
        target="_blank"
        variant="outline"
        className="mt-2"
      >
        <ExternalLinkIcon className="w-4 h-4" />
        {t("update_routine_description")}
      </LinkButton>
    </div>
  );
}

function PdfContent({
  form,
}: {
  form: ReturnType<typeof useForm<FormValues>>;
}) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const tenant = useTenant();
  const { format } = useDateFns();

  const caseIds = form.getValues("caseIds");
  const documentIds = form.getValues("documentIds");
  const inspectionIds = form.getValues("inspectionIds");
  const suppliersQuery = trpc.tenantSuppliersList.useQuery({
    tenantId,
    query: {
      sorting: [{ id: "name", desc: false }],
    },
  });
  const suppliers = suppliersQuery.data?.rows ?? [];

  const casesQuery = trpc.tenantCasesList.useQuery({
    tenantId,
  });
  const cases = casesQuery.data?.rows.filter((leCase) => caseIds.includes(leCase.id)) ?? [];

  const documentsQuery = trpc.tenantDocumentsList.useQuery({
    tenantId,
  });
  const documents =
    documentsQuery.data?.rows.filter((document) => documentIds.includes(document.id)) ?? [];

  const inspectionsQuery = trpc.tenantInspectionsList.useQuery({
    tenantId,
  });
  const inspections =
    inspectionsQuery.data?.rows.filter((inspection) => inspectionIds.includes(inspection.id)) ?? [];

  return (
    <Portal>
      <div id="report" className="hidden print:block">
        <div className="flex min-h-screen flex-col items-center justify-center p-8 print:break-after-page">
          <img
            src={`/api/rest/tenant-logo/${tenantId}`}
            alt="TIMP Logo"
            className="mb-8 h-auto w-48"
          />
          <TypographyH1 className="mb-4 text-3xl font-bold">
            {t("supplier_status_report")}
          </TypographyH1>
          <TypographyH2 className="mb-8 text-xl text-muted-foreground">
            {tenant.data?.name}
          </TypographyH2>

          <div className="w-full max-w-xl text-center">
            <InfoItem label={t("report_generated_at")} value={format(new Date(), "PPP")} />
          </div>
        </div>

        {/* <div className="print:break-after-page flex flex-col space-y-4">
          <TypographyH4 className="mb-4 text-center">{t("introduction")}</TypographyH4>

          <div className="prose prose-sm prose-slate">
            <TypographySmall className="font-bold block">{t("report_information")}</TypographySmall>

            <p>
              <Trans
                i18nKey="supplier_status_report_intro"
                components={{
                  u: <span className="underline" />,
                }}
                values={{
                  tenantName: tenant.data?.name,
                  supplierCount: suppliers.length,
                }}
              />
            </p>
          </div>
        </div> */}

        <div className="print:break-after-page flex flex-col space-y-4">
          <TypographyH4 className="text-center print:break-after-avoid">
            {t("routine_description")}
          </TypographyH4>

          {tenant.data?.routineDescription && (
            <RichTextEditor initialValue={tenant.data?.routineDescription} readOnly />
          )}
        </div>

        {suppliers.length > 0 && (
          <div className="flex flex-col space-y-4 print:break-after-page">
            <TypographyH4 className="mb-4 text-center">
              {t("supplier_register")} ({suppliers.length})
            </TypographyH4>
            <Table className="print:break-before-avoid">
              <TableHeader>
                <TableRow>
                  <TableHead>{t("supplier")}</TableHead>
                  <TableHead>{t("risk_evaluation")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {suppliers.map((supplier) => {
                  return (
                    <TableRow key={supplier.id}>
                      <TableCell>
                        <div className="flex flex-col">
                          <TypographySmall>{supplier.name}</TypographySmall>
                          <TypographyMuted className="text-xs">
                            {supplier.organizationNumber}
                          </TypographyMuted>
                        </div>
                      </TableCell>
                      <TableCell>
                        <SupplierStatusIconLabel status={supplier.status} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        {documents.length > 0 && (
          <div className="flex flex-col space-y-4 print:break-after-page">
            <TypographyH4 className="mb-4 text-center">
              {t("documents")} ({documents.length})
            </TypographyH4>

            <Table className="print:break-before-avoid">
              <TableHeader>
                <TableRow>
                  <TableHead>{t("supplier")}</TableHead>
                  <TableHead>{t("document_name")}</TableHead>
                  <TableHead>{t("file_name")}</TableHead>
                  <TableHead>{t("document_category")}</TableHead>
                  <TableHead>{t("expires_at")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {documents.map((document) => {
                  return (
                    <TableRow key={document.id}>
                      <TableCell>{document.supplierName}</TableCell>
                      <TableCell>{document.name}</TableCell>
                      <TableCell>{document.fileName}</TableCell>
                      <TableCell>{document.documentCategoryName}</TableCell>
                      <TableCell>
                        {document.expiresAt ? format(document.expiresAt, "PPP") : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        {cases.length > 0 && (
          <div className="flex flex-col space-y-4 print:break-after-page">
            <TypographyH4 className="mb-4 text-center">
              {t("cases")} ({cases.length})
            </TypographyH4>

            <Table className="print:break-before-avoid">
              <TableHeader>
                <TableRow>
                  <TableHead>{t("supplier")}</TableHead>
                  <TableHead>{t("case_title")}</TableHead>
                  <TableHead>{t("form_template")}</TableHead>
                  <TableHead>{t("status")}</TableHead>
                  <TableHead>{t("processed_at")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {cases.map((leCase) => {
                  return (
                    <TableRow key={leCase.id}>
                      <TableCell>{leCase.supplierName}</TableCell>
                      <TableCell>{leCase.title}</TableCell>
                      <TableCell>{leCase.formTemplateName}</TableCell>
                      <TableCell>
                        <CaseStatusIconLabel status={leCase.status} />
                      </TableCell>
                      <TableCell>
                        {leCase.processedAt ? format(leCase.processedAt, "PPP") : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}

        {inspections.length > 0 && (
          <div className="flex flex-col space-y-4 print:break-after-page">
            <TypographyH4 className="mb-4 text-center">
              {t("inspections")} ({inspections.length})
            </TypographyH4>

            <Table className="print:break-before-avoid">
              <TableHeader>
                <TableRow>
                  <TableHead>{t("supplier")}</TableHead>
                  <TableHead>{t("form_template")}</TableHead>
                  <TableHead>{t("status")}</TableHead>
                  <TableHead>{t("processed_at")}</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {inspections.map((inspection) => {
                  return (
                    <TableRow key={inspection.id}>
                      <TableCell>{inspection.supplierName}</TableCell>
                      <TableCell>{inspection.formTemplateName}</TableCell>
                      <TableCell>
                        <InspectionStatusIconLabel status={inspection.status} />
                      </TableCell>
                      <TableCell>
                        {inspection.processedAt ? format(inspection.processedAt, "PPP") : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </Portal>
  );
}

function SummaryStep({
  form,
}: {
  form: ReturnType<typeof useForm<FormValues>>;
}) {
  const { format } = useDateFns();
  const { t } = useTranslation();

  const caseIds = form.getValues("caseIds");
  const documentIds = form.getValues("documentIds");
  const inspectionIds = form.getValues("inspectionIds");
  return (
    <div className="space-y-6">
      <div className="flex flex-col space-y-4">
        <div className="space-y-4">
          <div className="space-y-1">
            <p className="text-sm text-muted-foreground">{t("report_generated_at")}</p>
            <p className="font-medium">{format(new Date(), "PPP")}</p>
          </div>
          <div className="space-y-1">
            <p className="text-sm text-muted-foreground">{t("cases")}</p>
            <p className="font-medium">{caseIds.length}</p>
          </div>
          <div className="space-y-1">
            <p className="text-sm text-muted-foreground">{t("documents")}</p>
            <p className="font-medium">{documentIds.length}</p>
          </div>
          <div className="space-y-1">
            <p className="text-sm text-muted-foreground">{t("inspections")}</p>
            <p className="font-medium">{inspectionIds.length}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

function DescriptionStep() {
  return (
    <div className="overflow-y-auto">
      <div className="flex flex-col space-y-2">
        <TypographyP className="text-muted-foreground">
          <Trans
            i18nKey="supplier_status_report_description"
            components={{
              1: (
                // biome-ignore lint/a11y/useAnchorContent: react-i18next injects the correct text content (taken from the translation key)
                <a
                  href="https://lovdata.no/dokument/NL/lov/1993-06-04-58?q=almenngj%C3%B8ringsloven"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline hover:text-primary"
                />
              ),
            }}
          />
        </TypographyP>
      </div>
    </div>
  );
}

function CasesStep({ form }: { form: ReturnType<typeof useForm<FormValues>> }) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();

  const casesQuery = trpc.tenantCasesList.useQuery({
    tenantId,
  });

  return (
    <FormField
      control={form.control}
      name="caseIds"
      render={({ field }) => (
        <FormItem className="overflow-y-hidden flex flex-1 flex-col">
          <div className="overflow-y-auto flex-1">
            <FormControl>
              <DataTable
                variant="borderless"
                columnsStorageKey="supplier_status_report_cases_columns"
                error={casesQuery.error}
                defaultColumnFilters={[{ id: "status", value: ["PROCESSED"] }]}
                defaultSortColumn="processedAt"
                defaultSortOrder="desc"
                data={casesQuery.data?.rows || []}
                paginationPageSize={15}
                isLoading={casesQuery.isLoading}
                defaultRowSelection={Object.fromEntries(field.value.map((id) => [id, true]))}
                getRowId={(row) => row.id}
                columns={[
                  {
                    id: "id",
                    title: "",
                    enableSorting: false,
                    enableHiding: false,
                    size: 40,
                    render(row, tableRow) {
                      return (
                        <Checkbox
                          checked={tableRow.getIsSelected()}
                          onCheckedChange={(value) => {
                            tableRow.toggleSelected(!!value);
                            if (value) {
                              field.onChange([...field.value, row.id]);
                            } else {
                              field.onChange(field.value.filter((id) => id !== row.id));
                            }
                          }}
                          aria-label="Select row"
                        />
                      );
                    },
                  },
                  {
                    id: "title",
                    title: t("title"),
                    enableSorting: false,
                    render: (row) => (
                      <DataTableLink to={`/tenants/${tenantId}/cases/${row.id}`} target="_blank">
                        {row.title}
                      </DataTableLink>
                    ),
                  },
                  {
                    id: "formTemplateName",
                    title: t("form_template"),
                    filter: true,
                  },
                  {
                    id: "supplierName",
                    title: t("supplier_name"),
                    filter: true,
                  },
                  {
                    id: "status",
                    title: t("status"),
                    filter: true,
                    valueToLabel: (status: CaseStatus) => t(`${status}`),
                    render: (row) => <CaseStatusIconLabel status={row.status} />,
                  },
                  {
                    id: "createdAt",
                    title: t("created_at"),
                    render: (row) => {
                      return <RelativeDate date={row.createdAt} />;
                    },
                    enableSorting: true,
                    timeFilter: true,
                  },
                  {
                    id: "processedAt",
                    title: t("processed_at"),
                    render: (row) => {
                      if (!row.processedAt) return null;
                      return <RelativeDate date={row.processedAt} />;
                    },
                    enableSorting: true,
                    timeFilter: true,
                  },
                ]}
              />
            </FormControl>
          </div>
        </FormItem>
      )}
    />
  );
}

function InspectionsStep({ form }: { form: ReturnType<typeof useForm<FormValues>> }) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();

  const inspectionsQuery = trpc.tenantInspectionsList.useQuery({
    tenantId,
  });

  return (
    <FormField
      control={form.control}
      name="inspectionIds"
      render={({ field }) => (
        <FormItem className="overflow-y-hidden flex flex-1 flex-col">
          <div className="overflow-y-auto flex-1">
            <FormControl>
              <DataTable
                variant="borderless"
                columnsStorageKey="supplier_status_report_inspections_columns"
                error={inspectionsQuery.error}
                defaultColumnFilters={[{ id: "status", value: ["PROCESSED"] }]}
                defaultSortColumn="processedAt"
                defaultSortOrder="desc"
                data={inspectionsQuery.data?.rows || []}
                paginationPageSize={15}
                isLoading={inspectionsQuery.isLoading}
                defaultRowSelection={Object.fromEntries(field.value.map((id) => [id, true]))}
                getRowId={(row) => row.id}
                columns={[
                  {
                    id: "id",
                    title: "",
                    enableSorting: false,
                    enableHiding: false,
                    size: 40,
                    render(row, tableRow) {
                      return (
                        <Checkbox
                          checked={tableRow.getIsSelected()}
                          onCheckedChange={(value) => {
                            tableRow.toggleSelected(!!value);
                            if (value) {
                              field.onChange([...field.value, row.id]);
                            } else {
                              field.onChange(field.value.filter((id) => id !== row.id));
                            }
                          }}
                          aria-label="Select row"
                        />
                      );
                    },
                  },
                  {
                    id: "formTemplateName",
                    title: t("form_template"),
                    enableSorting: false,
                    render: (row) => (
                      <DataTableLink
                        to={`/tenants/${tenantId}/inspections/${row.id}`}
                        target="_blank"
                      >
                        {row.formTemplateName}
                      </DataTableLink>
                    ),
                  },

                  {
                    id: "supplierName",
                    title: t("supplier_name"),
                    filter: true,
                  },
                  {
                    id: "status",
                    title: t("status"),
                    filter: true,
                    valueToLabel: (status: InspectionStatus) => t(`${status}`),
                    render: (row) => <InspectionStatusIconLabel status={row.status} />,
                  },
                  {
                    id: "createdAt",
                    title: t("created_at"),
                    render: (row) => {
                      return <RelativeDate date={row.createdAt} />;
                    },
                    enableSorting: true,
                    timeFilter: true,
                  },
                  {
                    id: "processedAt",
                    title: t("processed_at"),
                    render: (row) => {
                      if (!row.processedAt) return null;
                      return <RelativeDate date={row.processedAt} />;
                    },
                    enableSorting: true,
                    timeFilter: true,
                  },
                ]}
              />
            </FormControl>
          </div>
        </FormItem>
      )}
    />
  );
}

function DocumentsStep({ form }: { form: ReturnType<typeof useForm<FormValues>> }) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const documentsQuery = trpc.tenantDocumentsList.useQuery({ tenantId });

  return (
    <FormField
      control={form.control}
      name="documentIds"
      render={({ field }) => (
        <FormItem className="h-full overflow-y-hidden flex flex-1 flex-col">
          <div className="overflow-y-auto flex-1">
            <DataTable
              variant="borderless"
              columnsStorageKey="supplier_status_report_documents_columns"
              error={documentsQuery.error}
              defaultSortColumn="createdAt"
              defaultSortOrder="desc"
              data={documentsQuery.data?.rows || []}
              paginationPageSize={15}
              isLoading={documentsQuery.isLoading}
              defaultRowSelection={Object.fromEntries(field.value.map((id) => [id, true]))}
              getRowId={(row) => row.id}
              columns={[
                {
                  id: "id",
                  title: "",
                  enableSorting: false,
                  enableHiding: false,
                  size: 40,
                  render(row, tableRow) {
                    return (
                      <Checkbox
                        checked={tableRow.getIsSelected()}
                        onCheckedChange={(value) => {
                          tableRow.toggleSelected(!!value);
                          if (value) {
                            field.onChange([...field.value, row.id]);
                          } else {
                            field.onChange(field.value.filter((id) => id !== row.id));
                          }
                        }}
                        aria-label="Select row"
                      />
                    );
                  },
                },
                {
                  id: "name",
                  title: t("name"),
                  render: (row) => (
                    <DataTableLink
                      target="_blank"
                      to={`/tenants/${tenantId}/suppliers/${row.supplierId}/documents`}
                    >
                      {row.name}
                    </DataTableLink>
                  ),
                },
                {
                  id: "fileName",
                  title: t("file_name"),
                  render: (row) => row.fileName,
                },
                {
                  id: "supplierName",
                  title: t("supplier_name"),
                  filter: true,
                  valueToLabel: (value: string) => value,
                },
                {
                  id: "documentCategoryName",
                  title: t("document_category"),
                  render: (row) => row.documentCategoryName,
                  filter: true,
                  valueToLabel: (value: string) => value,
                },
                {
                  id: "createdAt",
                  title: t("created_at"),
                  render: (row) => {
                    return <RelativeDate date={row.createdAt} />;
                  },
                  enableSorting: true,
                  timeFilter: true,
                },
                {
                  id: "expiresAt",
                  title: t("expires_at"),
                  render: (row) => {
                    if (!row.expiresAt) return null;
                    return <RelativeDate date={row.expiresAt} />;
                  },
                  enableSorting: true,
                  timeFilter: true,
                },
              ]}
            />
          </div>
        </FormItem>
      )}
    />
  );
}
