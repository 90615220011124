import * as z from "zod";

export const tenantsInsertInputSchema = z.object({
  name: z.string().min(1).max(250),
  language: z.enum(["en", "no"]),
  enableAutoProcessInspections: z.boolean(),
  isDemo: z.boolean().optional().default(false),
  logo: z
    .object({
      name: z.string().max(500),
      type: z.enum(["image/jpg", "image/png", "image/gif", "image/jpeg"]),
      size: z.number().max(5 * 1024 * 1024), // 5 MB in bytes
      content: z.string(),
    })
    .optional()
    .nullable(),
  routineDescription: z.array(z.any()),
});

export const tenantsInsertOutputSchema = z.object({
  id: z.string().uuid(),
});
