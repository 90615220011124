import {
  SupplierStatusIconLabel,
  type SupplierStatus,
} from "@/components/labels/supplier-status-icon-label";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { RelativeDate } from "@/components/ui/relative-date";
import { LinkButton } from "@/components/buttons/link-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { DataTableLink } from "@/components/ui/data-table-link";
import { CollapsedBadges } from "@/components/badges/collapsed-badges";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { Badge } from "@/components/ui/badge";

export function SuppliersPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();

  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("suppliers_title")}</PageTitle>
        <PageActions>
          <LinkButton type="button" variant="secondary" to="./create-multiple">
            {t("supplier_create_multiple")}
          </LinkButton>
          <LinkButton to="./create">{t("supplier_create")}</LinkButton>
        </PageActions>
      </PageToolbar>
      <PageContent>
        <ServerDataTable
          columnsStorageKey="tenant-suppliers-page"
          query={trpc.tenantSuppliersList}
          params={{
            tenantId,
          }}
          paginationPageSize={50}
          columns={[
            {
              id: "name",
              title: t("supplier_name"),
              render(row) {
                return (
                  <DataTableLink to={`./${row.id}`}>
                    {row.name}
                    {row.isArchived && (
                      <Badge className="ml-1 border text-gray-500" variant="secondary">
                        {t("archived")}
                      </Badge>
                    )}
                  </DataTableLink>
                );
              },
            },
            {
              id: "status",
              title: t("risk_evaluation"),
              filter: true,
              render: (row) => <SupplierStatusIconLabel status={row.status} />,
              valueToLabel: (status: SupplierStatus) => t(`${status}`),
            },
            {
              id: "groups",
              title: t("groups"),
              filter: true,
              enableSorting: false,
              render: (row) => <CollapsedBadges variant="outline" items={row.groups} />,
            },
            {
              id: "departments",
              title: t("departments"),
              filter: true,
              enableSorting: false,
              render: (row) => <CollapsedBadges variant="outline" items={row.departments} />,
            },
            {
              id: "projects",
              title: t("projects"),
              filter: true,
              enableSorting: false,
              render: (row) => <CollapsedBadges variant="outline" items={row.projects} />,
            },

            {
              id: "lastActivity",
              title: t("last_activity"),
              render: (row) => <RelativeDate date={row.lastActivity} />,
            },
          ]}
          searchColumns={["name"]}
          defaultSortColumn="name"
        />
      </PageContent>
    </Page>
  );
}
