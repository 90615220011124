import { z } from "zod";

export const usersUpdateProfileInputSchema = z.object({
  firstName: z.string().min(1).max(250).trim().optional(),
  lastName: z.string().min(1).max(250).trim().optional(),
  language: z.enum(["en", "no"]).optional(),
  weeklyTasksEmails: z
    .object({
      enabled: z.boolean().optional(),
      tenantIds: z.array(z.string().uuid()).optional(),
    })
    .optional(),
});
