import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { SelectManageButton } from "./select-manage-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { useRole } from "@/hooks/useUser";
import { SelectMultipleBase, type ValueProps } from "./select-multiple";

export function SelectMultipleDocumentCategories({
  values,
  onChange,
  disabled,
  className,
}: ValueProps) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const role = useRole(tenantId);

  const {
    data = [],
    isLoading,
    error,
  } = trpc.tenantDocumentCategoriesList.useQuery({ tenantId }, { initialData: [] });

  const footer =
    role === "ADMIN" ? (
      <SelectManageButton to={`/tenants/${tenantId}/settings/document-categories`}>
        {t("manage_document_categories")}
      </SelectManageButton>
    ) : null;

  return (
    <SelectMultipleBase
      className={className}
      disabled={disabled}
      options={data}
      isLoading={isLoading}
      error={error}
      values={values}
      onChange={onChange}
      footer={footer}
    />
  );
}
