import {
  type PropsWithChildren,
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useState,
} from "react";
import { TypographyH3 } from "../ui/typography";
import { LinkButton } from "../buttons/link-button";
import { ChevronLeft } from "lucide-react";
import { cn } from "@/lib/utils";
import { Spinner } from "../icons/spinner";
import type { TRPCError } from "@/lib/providers/trpc";
import { Button } from "../ui/button";
import { XIcon } from "lucide-react";
import { useLocation } from "react-router-dom";
import { ScrollArea } from "../ui/scroll-area";
import { useAutoAnimate } from "@formkit/auto-animate/react";

type SizeType = "container" | "full";

interface PageProps {
  className?: string;
  isLoading?: boolean;
  error?: Error | TRPCError | null;
  size?: SizeType;
}

export const PageContext = createContext<{ size: SizeType }>(null!);

export function Page({
  children,
  isLoading,
  className,
  error,
  size = "full",
}: PropsWithChildren<PageProps>) {
  return (
    <PageContext.Provider
      value={{
        size,
      }}
    >
      <div
        className={cn(
          "flex h-full flex-1 flex-col gap-y-4 overflow-hidden py-2 md:py-4",
          className
        )}
      >
        {isLoading && (
          <div className="flex h-full w-full items-center justify-center">
            <Spinner size="lg" />
          </div>
        )}
        {error && (
          <div className="flex h-full w-full items-center justify-center">
            <span className="text-destructive">{error.message}</span>
          </div>
        )}
        {!isLoading && !error ? children : null}
      </div>
    </PageContext.Provider>
  );
}
const usePageContext = () => {
  return useContext(PageContext);
};

interface PageToolbarProps {
  className?: string;
}
export function PageToolbar({ children, className }: PropsWithChildren<PageToolbarProps>) {
  const { size } = usePageContext();

  return (
    <div
      className={cn(
        "grid min-h-10 flex-none auto-cols-auto grid-flow-col flex-wrap gap-x-2 overflow-auto",
        size === "container" && "container",
        size === "full" && "px-2 md:px-6",
        className
      )}
    >
      {children}
    </div>
  );
}

interface PageTitleProps {
  backLink?: true | string;
  className?: string;
}
export function PageTitle({ children, className, backLink }: PropsWithChildren<PageTitleProps>) {
  const location = useLocation();
  let backButtonPath: string | null = null;

  if (backLink) {
    if (backLink === true && location.state?.from) {
      backButtonPath = location.state.from;
    } else if (typeof backLink === "string") {
      backButtonPath = backLink;
    } else {
      backButtonPath = "../";
    }
  }

  const [animateRef] = useAutoAnimate();
  return (
    <div className={cn("flex items-center gap-x-2 text-nowrap", className)} ref={animateRef}>
      {backButtonPath && (
        <LinkButton to={backButtonPath} size="icon" variant="ghost" data-testid="back-button-link">
          <ChevronLeft />
        </LinkButton>
      )}
      <TypographyH3 className="flex items-center gap-x-2 text-xl md:text-2xl">
        {children}
      </TypographyH3>
    </div>
  );
}

export function PageActions({ children }: PropsWithChildren) {
  return <div className="flex flex-nowrap items-center gap-4 justify-self-end">{children}</div>;
}

interface PageSidebarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export function PageSidebar({ children, open, setOpen }: PropsWithChildren<PageSidebarProps>) {
  const location = useLocation();

  // Closes sidebar whenever a route is changed
  useEffect(() => {
    if (location.pathname) {
      setOpen(false);
    }
  }, [location.pathname, setOpen]);

  return (
    <div className={cn("relative z-50 h-full md:w-72")}>
      {/* <Button variant="outline" className="absolute left-0 top-0 size-7 items-center justify-center p-0 md:hidden" onClick={() => setIsOpen((c) => !c)}>
				<MenuIcon className="size-5 text-primary" strokeWidth={1.5} />
			</Button> */}
      <nav
        className={cn(
          open && "left-0",
          !open && "-left-72",
          "absolute z-50 h-full w-72 border-r border-background/5 bg-primary transition-all dark:border-primary/5 dark:bg-background md:static md:left-auto md:z-auto"
        )}
      >
        <ScrollArea className="max-h-full overflow-auto">
          <div className="relative flex flex-col gap-y-8 p-4">
            <Button
              variant="link"
              className="absolute right-1 block size-7 p-0 md:hidden"
              onClick={() => setOpen((c) => !c)}
            >
              <XIcon className="w-full text-background" />
            </Button>
            {children}
          </div>
        </ScrollArea>
      </nav>
      {open && (
        // Blurred background
        // biome-ignore lint/a11y/useKeyWithClickEvents: a simple onClick does the job
        <div
          className={cn(
            "absolute inset-0 z-20 h-screen w-screen bg-background/80 backdrop-blur-sm md:pointer-events-none md:static md:z-auto md:h-auto md:w-auto"
          )}
          onClick={() => setOpen(false)}
        />
      )}
    </div>
  );
}

interface PageContentProps {
  rootClassName?: string;
  className?: string;
  scroll?: boolean;
}
export const PageContent = forwardRef<HTMLDivElement, PropsWithChildren<PageContentProps>>(
  ({ children, className, rootClassName, scroll = false }, ref) => {
    const { size } = usePageContext();

    return (
      <div
        className={cn(
          "group flex flex-1 flex-col gap-y-4",
          scroll && "overflow-y-auto",
          !scroll && "overflow-y-hidden",
          rootClassName
        )}
      >
        <main
          className={cn(
            "flex flex-1 flex-col",
            size === "container" && "container",
            size === "full" && "px-2 md:px-6",
            !scroll && "overflow-hidden",
            className
          )}
          ref={ref}
        >
          {children}
        </main>
      </div>
    );
  }
);
PageContent.displayName = "PageContent";
