import { ExternalLinkButton } from "@/components/buttons/link-button";
import {
  type InspectionStatus,
  InspectionStatusIconLabel,
} from "@/components/labels/inspection-status-icon-label";
import { Page, PageTitle, PageToolbar, PageActions, PageContent } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { Badge } from "@/components/ui/badge";
import { DataTableLink } from "@/components/ui/data-table-link";
import { RelativeDate } from "@/components/ui/relative-date";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useSupplier, useSupplierIdSafe } from "@/hooks/useSupplier";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";

export function SupplierInspectionsPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const supplierId = useSupplierIdSafe();
  const supplier = useSupplier();

  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("inspections")}</PageTitle>

        <PageActions>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger type="button">
                <ExternalLinkButton
                  disabled={supplier.data?.isArchived}
                  target="_blank"
                  href={`/inspector/tenants/${tenantId}/forms`}
                >
                  {t("create_inspection")}
                </ExternalLinkButton>
              </TooltipTrigger>
              {supplier.data?.isArchived && (
                <TooltipContent>{t("disallowed_due_to_archived_supplier")}</TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </PageActions>
      </PageToolbar>

      <PageContent>
        <ServerDataTable
          columnsStorageKey="supplier-inspections-page"
          query={trpc.tenantInspectionsList}
          params={{
            tenantId,
            supplierId,
          }}
          searchColumns={["searchTags", "formTemplateName"]}
          columns={[
            {
              id: "formTemplateName",
              title: t("form_name"),
              filter: true,
              enableSorting: false,
              render(row) {
                return (
                  <DataTableLink to={`../../../inspections/${row.id}`}>
                    {row.formTemplateName}
                  </DataTableLink>
                );
              },
            },
            {
              id: "departmentName",
              title: t("department"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "projectName",
              title: t("project"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "locationName",
              title: t("location"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "equipmentName",
              title: t("equipment"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "status",
              title: t("status"),
              filter: true,
              enableSorting: false,
              render: (row) => <InspectionStatusIconLabel status={row.status} />,
              valueToLabel: (status: InspectionStatus) => t(`${status}`),
            },
            {
              id: "deviationsCount",
              title: t("deviations"),
              render: (row) => {
                if (row.deviationsCount) {
                  return (
                    <Badge variant="secondary">
                      {t("deviations_count", { count: row.deviationsCount })}
                    </Badge>
                  );
                }
                return null;
              },
            },
            {
              id: "createdByInspectorName",
              title: t("INSPECTOR"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
              enableSorting: true,
            },
          ]}
          defaultSortColumn="createdAt"
          defaultSortOrder="desc"
          paginationPageSize={50}
        />
      </PageContent>
    </Page>
  );
}
