import type { RouterOutput } from "@/lib/providers/trpc";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import {
  FileCheck2Icon,
  MailCheckIcon,
  MailOpenIcon,
  MailQuestionIcon,
  MailXIcon,
} from "lucide-react";
import { Trans } from "react-i18next";
import { cn } from "@/lib/utils";

interface ContactReminderStatusIconProps {
  status: RouterOutput["tenantCasesContactsByCaseId"][number]["status"];
  contactFullName: string;
  className?: string;
}

export function CaseContactReminderStatusIconLabel({
  status,
  contactFullName,
  className,
}: ContactReminderStatusIconProps) {
  let item = null;

  switch (status) {
    case "OPENED":
      item = (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <MailOpenIcon className={cn("size-5 text-blue-600", className)} />
            </TooltipTrigger>
            <TooltipContent>
              <Trans i18nKey="case_opened_by_supplier_contact" values={{ name: contactFullName }} />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
      break;
    case "ANSWERED":
      item = (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <FileCheck2Icon className={cn("size-5 text-green-600", className)} />
            </TooltipTrigger>
            <TooltipContent>
              <Trans
                i18nKey="case_answered_by_supplier_contact"
                values={{ name: contactFullName }}
              />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
      break;
    case "PROCESSING":
      item = (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <MailQuestionIcon className={cn("size-5 text-gray-600", className)} />
            </TooltipTrigger>
            <TooltipContent>
              <Trans
                i18nKey="case_mail_processed_supplier_contact"
                values={{ name: contactFullName }}
              />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
      break;
    case "DELIVERED":
      item = (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <MailCheckIcon className={cn("size-5 text-muted-foreground", className)} />
            </TooltipTrigger>
            <TooltipContent>
              <Trans i18nKey="case_sent_to_supplier_contact" values={{ name: contactFullName }} />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
      break;
    case "FAILED":
      item = (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <MailXIcon className={cn("size-5 text-red-600", className)} />
            </TooltipTrigger>
            <TooltipContent>
              <Trans
                i18nKey="case_mail_bounce_or_dropped_supplier_contact"
                values={{ name: contactFullName }}
              />
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      );
      break;
    default: {
      const _exhaustiveCheck: never = status;
    }
  }

  return item;
}
