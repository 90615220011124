import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { trpc } from "@/lib/providers/trpc";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import type { z } from "zod";
import { LinkButton } from "@/components/buttons/link-button";
import { tenantDeviationsInsertInputSchema } from "@timp/server/src/schemas/tenant-deviations-insert.schema";
import { Button } from "@/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { SelectSingleSupplier } from "@/components/selects/select-single";

export function DeviationsCreatePage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const tenantId = useTenantIdSafe();

  const { isLoading, mutate } = trpc.tenantDeviationsInsert.useMutation({
    onSuccess(data) {
      navigate(`../${data.id}`);
    },
  });

  const form = useForm<z.infer<typeof tenantDeviationsInsertInputSchema>>({
    resolver: zodResolver(tenantDeviationsInsertInputSchema),
    defaultValues: {
      description: "",
      severity: "LOW",
      supplierId: searchParams.get("supplierId") ?? undefined,
    },
  });

  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle backLink>{t("create_deviation")}</PageTitle>
      </PageToolbar>

      <PageContent scroll>
        <Form {...form}>
          <form onSubmit={form.handleSubmit((data) => mutate({ ...data, tenantId }))}>
            <div className="flex flex-col gap-y-4">
              <FormField
                control={form.control}
                name="supplierId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required={true}> {t("supplier")}</FormLabel>
                    <SelectSingleSupplier value={field.value} onChange={field.onChange} />
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="severity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required> {t("severity")}</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value} name={field.name}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="LOW">{t("LOW")}</SelectItem>
                        <SelectItem value="MEDIUM">{t("MEDIUM")}</SelectItem>
                        <SelectItem value="HIGH">{t("HIGH")}</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required>{t("reason")}</FormLabel>
                    <FormControl>
                      <Textarea rows={8} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex flex-wrap justify-end gap-4">
                <LinkButton variant="outline" to="../">
                  {t("cancel")}
                </LinkButton>

                <Button isLoading={isLoading} type="submit">
                  {t("create_deviation")}
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
