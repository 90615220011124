import * as z from "zod";

export const tenantsUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1).max(250).optional(),
  language: z.enum(["en", "no"]).optional(),
  enableAutoProcessInspections: z.boolean().optional(),
  logo: z
    .object({
      name: z.string().max(500),
      type: z.enum(["image/jpg", "image/png", "image/gif", "image/jpeg"]),
      size: z.number().max(5 * 1024 * 1024), // 5 MB in bytes
      content: z.string(),
    })
    .optional()
    .nullable(),
  routineDescription: z.array(z.any()).optional(),
});

export const tenantsUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
