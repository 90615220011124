import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { DataTableLink } from "@/components/ui/data-table-link";
import { RelativeDate } from "@/components/ui/relative-date";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";

export function CaseResponseTasksPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("case_response_tasks")}</PageTitle>
      </PageToolbar>
      <PageContent>
        <ServerDataTable
          columnsStorageKey="case-response-tasks-page"
          query={trpc.tenantTasksCasesResponseList}
          params={{
            tenantId,
          }}
          columns={[
            {
              id: "caseId",
              title: t("description"),
              render: (row) => {
                const description = t("case_response_task_desciption", {
                  supplierName: row.supplierName,
                  formTemplateName: row.formTemplateName,
                });
                return (
                  <DataTableLink to={`/tenants/${tenantId}/cases/${row.caseId}`}>
                    {description}
                  </DataTableLink>
                );
              },
            },
            {
              id: "supplierName",
              title: t("supplier"),
              filter: true,
              enableSorting: true,
            },
            {
              id: "formTemplateName",
              title: t("form_template"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "responseCreatedAt",
              title: t("received_at"),
              render: (row) => <RelativeDate date={row.responseCreatedAt} />,
            },
          ]}
          defaultSortColumn="responseCreatedAt"
          defaultSortOrder="asc"
          paginationPageSize={50}
        />
      </PageContent>
    </Page>
  );
}
