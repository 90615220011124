import { useForm } from "react-hook-form";
import { Button } from "../ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "../ui/form";
import { Input } from "../ui/input";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { tenantSuppliersContactUpdateByIdInputSchema } from "@timp/server/src/schemas/tenant-supplier-contact-update-by-id.schema";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Spinner } from "../icons/spinner";

interface Props {
  contactId: string | null;
  onClose?: () => void;
}

export function ChangeContactFormDialog({ contactId, onClose }: Props) {
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();

  const { data, isLoading, error } = trpc.tenantSupplierContactsById.useQuery(
    { id: contactId!, tenantId },
    { enabled: !!contactId }
  );
  const update = trpc.tenantSupplierContactsUpdateById.useMutation({
    onSuccess: () => {
      if (onClose) onClose();
    },
  });

  const form = useForm<z.infer<typeof tenantSuppliersContactUpdateByIdInputSchema>>({
    resolver: zodResolver(tenantSuppliersContactUpdateByIdInputSchema),
    values: data,
  });

  const emailValidation = trpc.validateEmail.useMutation({
    onSuccess(validationResult) {
      if (validationResult.suggestedEmail) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check_suggestion", {
            email: validationResult.suggestedEmail,
          }),
        });
      } else if (!validationResult.isValid) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check"),
        });
      } else {
        form.clearErrors("email");
      }
    },
  });

  return (
    <Dialog
      open={!!contactId}
      onOpenChange={(open) => {
        if (!open && onClose) onClose();
      }}
    >
      <form onSubmit={form.handleSubmit((data) => update.mutate({ ...data, tenantId }))}>
        <DialogContent isLoading={isLoading} error={error} className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>{t("edit_contact")}</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <FormField
              control={form.control}
              name="fullName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("full_name")}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("email")}</FormLabel>
                  <div className="relative">
                    <FormControl>
                      <Input
                        placeholder="olanordmann@example.no"
                        {...field}
                        onBlur={() => {
                          form.trigger("email").then((isValid) => {
                            if (isValid) {
                              emailValidation.mutate({ email: field.value });
                            }
                          });
                        }}
                      />
                    </FormControl>
                    {emailValidation.isLoading && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  {emailValidation.data?.suggestedEmail ? (
                    <button
                      type="button"
                      className="inline [&>p]:hover:underline"
                      onClick={() => {
                        field.onChange(emailValidation.data.suggestedEmail);
                        emailValidation.reset();
                        form.clearErrors("email");
                      }}
                    >
                      <FormMessage />
                    </button>
                  ) : (
                    <FormMessage />
                  )}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("phone")}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("role")}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </Form>
          <DialogFooter>
            <Button
              type="button"
              variant="ghost"
              onClick={() => {
                form.reset(data);
                if (onClose) onClose();
              }}
            >
              {t("cancel")}
            </Button>
            <Button isLoading={update.isLoading} type="submit">
              {t("save_changes")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </form>
    </Dialog>
  );
}
