import { LinkButton } from "@/components/buttons/link-button";
import {
  MutationActionsMenu,
  MutationDeleteAction,
  MutationEditAction,
} from "@/components/dropdown-menus/mutation-actions-menu";
import { LanguageTitleLabel, useLanguageTitle } from "@/components/labels/language-title-label";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { DataTableLink } from "@/components/ui/data-table-link";
import { RelativeDate } from "@/components/ui/relative-date";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useSupplier, useSupplierIdSafe } from "@/hooks/useSupplier";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";

export function SupplierContactsPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const supplierId = useSupplierIdSafe();
  const supplier = useSupplier();
  const getLanguageTitle = useLanguageTitle();

  const deleteContact = trpc.tenantSupplierContactsDeleteById.useMutation();

  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("supplier_contacts")}</PageTitle>
        <PageActions>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger type="button">
                <LinkButton disabled={supplier.data?.isArchived} to={"./create"}>
                  {t("create_supplier_contact")}
                </LinkButton>
              </TooltipTrigger>
              {supplier.data?.isArchived && (
                <TooltipContent>{t("disallowed_due_to_archived_supplier")}</TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </PageActions>
      </PageToolbar>
      <PageContent>
        <ServerDataTable
          columnsStorageKey="supplier-contacts-page"
          query={trpc.tenantSupplierContactsList}
          params={{ tenantId, supplierId }}
          columns={[
            {
              id: "fullName",
              title: t("full_name"),
              enableSorting: true,
              render: (row) => (
                <DataTableLink to={`./${row.id}/edit`} data-testid="supplier-contact-name">
                  {row.fullName}
                </DataTableLink>
              ),
            },
            { id: "email", title: t("email"), enableSorting: true },
            {
              id: "language",
              title: t("language"),
              enableSorting: true,
              filter: true,
              render: (row) => <LanguageTitleLabel language={row.language} />,
              valueToLabel: (value) => getLanguageTitle(value),
            },
            { id: "phone", title: t("phone"), enableSorting: true },
            {
              id: "role",
              title: t("role"),
              filter: true,
              render: (row) => {
                return <span data-testid="supplier-contact-role">{row.role}</span>;
              },
            },
            {
              id: "createdAt",
              title: t("created_at"),
              enableSorting: true,
              render: (row) => <RelativeDate date={row.createdAt} />,
            },
            {
              id: "id",
              title: t("actions"),
              actions: true,
              enableSorting: false,
              size: 40,
              render: (row) => {
                return (
                  <MutationActionsMenu data-testid={row.fullName}>
                    <MutationEditAction to={`./${row.id}/edit`} />
                    <MutationDeleteAction
                      name={row.fullName}
                      onConfirmDelete={() => deleteContact.mutate({ id: row.id, tenantId })}
                    />
                  </MutationActionsMenu>
                );
              },
            },
          ]}
          defaultSortColumn="createdAt"
          defaultSortOrder="desc"
          searchColumns={["fullName", "email", "phone"]}
          paginationPageSize={50}
        />
      </PageContent>
    </Page>
  );
}
