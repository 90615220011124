import { Badge, type BadgeProps } from "../ui/badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
type Option = { id: string; name: string };
interface Props {
  items: Option[] | string[];
  variant?: BadgeProps["variant"];
  collapseAt?: number;
}
export function CollapsedBadges({
  items,
  collapseAt = 1,
  variant = "default",
}: Props): JSX.Element | null {
  const itemsToRender = items.slice(0, collapseAt);
  const collapsedItems = items.slice(collapseAt);

  if (!itemsToRender.length) return null;
  return (
    <div className="flex items-center gap-x-1">
      {itemsToRender.map((item, index) => (
        <Badge className="max-w-[80px] cursor-default px-1" variant={variant} key={index}>
          <span className="truncate">{typeof item === "string" ? item : (item?.name ?? "-")}</span>
        </Badge>
      ))}

      {collapsedItems.length > 0 && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger type="button" className="cursor-default" tabIndex={-1}>
              <Badge className="max-w-[100px] px-1 text-gray-500" variant={variant}>
                <span className="truncate">+{collapsedItems.length}</span>
              </Badge>
            </TooltipTrigger>
            <TooltipContent className="max-h-96 max-w-96 overflow-auto">
              <ul className="flex flex-row flex-wrap gap-3">
                {collapsedItems.map((item, index) => (
                  <li key={index}>{typeof item === "string" ? item : (item?.name ?? "-")}</li>
                ))}
              </ul>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
}
