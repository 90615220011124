import * as z from "zod";

export const tenantLocationsUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1).max(250).optional(),
});

export const tenantLocationsUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
