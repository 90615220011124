import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useDateFns } from "@/hooks/useDateFns";
import { cn } from "@/lib/utils";
import { isFuture, isPast } from "date-fns";
import { useTranslation } from "react-i18next";

interface Props {
  date: string | Date;
  className?: string;
}

export function RelativeDate({ date, className }: Props) {
  const { format, formatRelative } = useDateFns();
  const d = new Date(date);
  return (
    <>
      <span className="hidden text-sm print:inline">{format(d, "Pp")}</span>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger
            tabIndex={-1}
            className={cn(className, "justify-start p-0 text-start print:hidden")}
          >
            {formatRelative(d, new Date())}
          </TooltipTrigger>
          <TooltipContent>
            <p>{format(d, "Pp")}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
}
interface DateTooltipProps {
  date: string | Date;
  label: string;
  className?: string;
}
export function DateTooltip({ date, label, className }: DateTooltipProps) {
  const { format } = useDateFns();
  const d = new Date(date);
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger tabIndex={-1} className={className}>
          {label}
        </TooltipTrigger>
        <TooltipContent>
          <p>{format(d, "Pp")}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}

export function DistanceToNowDate({ date, className }: Props) {
  const { t } = useTranslation();
  const { format, formatDistanceToNow } = useDateFns();
  const d = new Date(date);

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger tabIndex={-1} className={className}>
          {isFuture(d) && `${t("in")} `}
          {formatDistanceToNow(d)}
          {isPast(d) && ` ${t("ago")}`}
        </TooltipTrigger>
        <TooltipContent>
          <p>{format(d, "Pp")}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
