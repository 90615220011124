import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { RouteMenuLink } from "./route-menu-link";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { TypographyH5, TypographyP } from "../ui/typography";
import { useSupplier, useSupplierIdSafe } from "@/hooks/useSupplier";
import { PageSidebar } from "./page";
import {
  AlertCircleIcon,
  CircleUserRoundIcon,
  FileInputIcon,
  FileSearch2Icon,
  FileTextIcon,
  LineChartIcon,
  MenuIcon,
  SettingsIcon,
} from "lucide-react";
import { useState } from "react";
import { Button } from "../ui/button";

export function SupplierDetailsLayout() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const supplierId = useSupplierIdSafe();
  const supplier = useSupplier();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="flex h-full w-full flex-1 flex-grow overflow-hidden">
      <PageSidebar open={isSidebarOpen} setOpen={setIsSidebarOpen}>
        <div className="flex flex-col gap-y-1">
          <TypographyH5 className="line-clamp-2 text-background/75 dark:text-primary/50">
            {supplier.data?.name}
          </TypographyH5>
          {supplier.data?.isArchived && (
            <span className="text-muted-foreground">({t("archived")})</span>
          )}

          <TypographyP className="text-background/50 dark:text-primary/50">
            {t("general")}
          </TypographyP>
          <RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/overview`}>
            <LineChartIcon className="size-4 text-background/80 dark:text-primary/60" />
            {t("overview")}
          </RouteMenuLink>
          <RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/cases`}>
            <FileInputIcon className="size-4 text-background/80 dark:text-primary/60" />
            {t("cases")}
          </RouteMenuLink>
          <RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/inspections`}>
            <FileSearch2Icon className="size-4 text-background/80 dark:text-primary/60" />
            {t("inspections")}
          </RouteMenuLink>
          <RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/documents`}>
            <FileTextIcon className="size-4 text-background/80 dark:text-primary/60" />
            {t("documents")}
          </RouteMenuLink>
          <RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/deviations`}>
            <AlertCircleIcon className="size-4 text-background/80 dark:text-primary/60" />
            {t("deviations")}
          </RouteMenuLink>

          <TypographyP className="text-background/50 dark:text-primary/50">
            {t("settings")}
          </TypographyP>
          <RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/edit`}>
            <SettingsIcon className="size-4 text-background/80 dark:text-primary/60" />
            {t("supplier")}
          </RouteMenuLink>
          <RouteMenuLink to={`/tenants/${tenantId}/suppliers/${supplierId}/contacts`}>
            <CircleUserRoundIcon className="capitalized size-4 text-background/80 dark:text-primary/60" />
            {t("contacts")}
          </RouteMenuLink>
        </div>
      </PageSidebar>

      <div className="flex flex-1 flex-col overflow-auto">
        <Button
          variant="outline"
          className="m-4 mb-0 size-8 items-center justify-center p-0 md:hidden"
          onClick={() => setIsSidebarOpen((c) => !c)}
        >
          <MenuIcon className="size-5 text-primary" strokeWidth={1.5} />
        </Button>
        <Outlet />
      </div>
    </div>
  );
}
