import { useLocalStorageTenant } from "@/hooks/useLocalStorageTenant";
import { useUser } from "@/hooks/useUser";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

export function TenantLocalStorageSync({ children }: { children: React.ReactNode }) {
  const user = useUser();
  const previousTenant = useLocalStorageTenant();
  const { tenantId } = useParams();
  useEffect(() => {
    if (tenantId && user?.id) {
      previousTenant.set(user.id, tenantId);
    }
  }, [tenantId, user, previousTenant]);
  return children;
}
