import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useTranslation } from "react-i18next";
import { type PropsWithChildren, useState } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../ui/alert-dialog";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { cn } from "@/lib/utils";
import { PencilIcon, CopyPlusIcon, Trash2Icon } from "lucide-react";
import { Link } from "@/components/links/Link";
type MutationEditActionProps =
  | {
      to: string;
      onEdit?: never;
    }
  | {
      onEdit: () => void;
      to?: never;
    };
export function MutationEditAction(props: MutationEditActionProps) {
  const { t } = useTranslation();
  return (
    <DropdownMenuItem asChild className="hover:cursor-pointer">
      {props?.to ? (
        <Link to={props.to} className="gap-x-2">
          <PencilIcon strokeWidth={1.5} className="h-4 w-4" />
          {t("edit")}
        </Link>
      ) : (
        <Button className="w-full justify-start gap-x-2 p-2" onClick={props.onEdit} variant="ghost">
          <PencilIcon strokeWidth={1.5} className="h-4 w-4" />
          {t("edit")}
        </Button>
      )}
    </DropdownMenuItem>
  );
}
interface MutationCloneActionProps {
  onClick: () => void;
}
export function MutationCloneAction({ onClick }: MutationCloneActionProps) {
  const { t } = useTranslation();
  return (
    <DropdownMenuItem asChild className="hover:cursor-pointer">
      <button type="button" onClick={onClick} className="w-full gap-x-2">
        <CopyPlusIcon strokeWidth={1.5} className="h-4 w-4" />
        {t("clone")}
      </button>
    </DropdownMenuItem>
  );
}

interface MutationComboBoxActionProps<T extends string> {
  onSelect: (value: T) => void;
  title: string;
  options: {
    value: T;
    label: string;
  }[];
}

export function MutationComboBoxAction<T extends string>({
  options,
  title,
  onSelect,
}: MutationComboBoxActionProps<T>) {
  const { t } = useTranslation();

  return (
    <DropdownMenuSub>
      <DropdownMenuSubTrigger>{title}</DropdownMenuSubTrigger>
      <DropdownMenuSubContent className="p-0">
        <Command>
          <CommandInput placeholder={t("search")} autoFocus={true} className="h-9" />
          <CommandList>
            <CommandEmpty>{t("no_results")}</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.value}
                  onSelect={() => {
                    onSelect(option.value);
                  }}
                >
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </DropdownMenuSubContent>
    </DropdownMenuSub>
  );
}

interface MutationDeleteActionProps {
  name?: string;
  onConfirmDelete: () => void;
}

export function MutationDeleteAction({ name, onConfirmDelete }: MutationDeleteActionProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault();
          setOpen((c) => !c);
        }}
        className="gap-x-2 text-destructive hover:cursor-pointer dark:text-red-600"
      >
        <Trash2Icon strokeWidth={1.5} className="h-4 w-4" />
        {t("delete")}
      </DropdownMenuItem>
      <DeleteDialog open={open} setOpen={setOpen} onConfirmDelete={onConfirmDelete} name={name} />
    </>
  );
}
interface DeleteDialogProps {
  name?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirmDelete: () => void;
  isLoading?: boolean;
}
export function DeleteDialog({
  open,
  setOpen,
  name,
  onConfirmDelete,
  isLoading,
}: DeleteDialogProps) {
  const { t } = useTranslation();
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{t("confirm_delete_title")}</AlertDialogTitle>
          {name ? (
            <AlertDialogDescription>
              {t("confirm_delete_description", { name })}
            </AlertDialogDescription>
          ) : (
            <AlertDialogDescription>
              {t("confirm_delete_description_general")}
            </AlertDialogDescription>
          )}
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading}>{t("cancel")}</AlertDialogCancel>
          <AlertDialogAction asChild>
            <Button variant="destructive" isLoading={isLoading} onClick={onConfirmDelete}>
              {t("delete")}
            </Button>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

export function MutationActionsMenu({
  children,
  hideUnlessHover = true,
  contentClassName,
  ...restProps
}: PropsWithChildren<{ hideUnlessHover?: boolean; contentClassName?: string }>) {
  const { t } = useTranslation();

  const hideUnlessHoverClassNames =
    "pointer-events-none opacity-0 transition-opacity group-hover/actions:pointer-events-auto group-hover/actions:opacity-100 data-[state=open]:pointer-events-auto data-[state=open]:opacity-100";

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className={cn("flex h-8 w-8 px-0 py-0", hideUnlessHover && hideUnlessHoverClassNames)}
          {...restProps}
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">{t("open_menu")}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className={cn("w-[160px]", contentClassName)}>
        {children}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
