import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { tenantAutomatedCasesUpdateByIdInputSchema } from "@timp/server/src/schemas/tenant-automatic-cases-update-by-id.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate, useParams } from "react-router-dom";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { LinkButton } from "@/components/buttons/link-button";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { SelectMultipleSuppliers } from "@/components/selects/select-multiple-suppliers";
import { FormViewer } from "@/components/form-viewers/form-viewer";
import { PartialFormAutomaticCaseTimeScheduler } from "@/components/form-partials/partial-form-automatic-case-time-scheduler";
import { SelectSingleFormTemplate } from "@/components/selects/select-single";
import { Checkbox } from "@/components/ui/checkbox";

export function TenantSettingsAutomaticCasesEditPage() {
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { id } = useParams();

  const { mutate, isLoading } = trpc.tenantAutomaticCasesUpdateById.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const automaticCase = trpc.tenantAutomaticCasesById.useQuery({
    tenantId,
    id: id!,
  });

  const form = useForm<z.infer<typeof tenantAutomatedCasesUpdateByIdInputSchema>>({
    resolver: zodResolver(tenantAutomatedCasesUpdateByIdInputSchema),
    defaultValues: {
      supplierIds: [],
    },
    values: automaticCase.data,
  });

  const selectedFormTemplateId = form.watch("formTemplateId");
  const selectedFormContent = trpc.tenantFormTemplatesById.useQuery(
    {
      id: selectedFormTemplateId!,
      tenantId,
    },
    { enabled: !!selectedFormTemplateId }
  );

  // Autofill case title with form template name
  const formTemplateName = selectedFormContent.data?.name;
  useEffect(() => {
    if (formTemplateName) {
      form.setValue("title", formTemplateName);
    }
  }, [form, formTemplateName]);

  return (
    <Page
      size="container"
      isLoading={automaticCase.isLoading || !form.getValues("id")}
      error={automaticCase.error}
    >
      <PageToolbar>
        <PageTitle backLink>
          {t("edit")} {automaticCase.data?.title}
        </PageTitle>
      </PageToolbar>
      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) => mutate({ tenantId, ...values }))}
            className="flex flex-col gap-y-12"
          >
            <PartialFormAutomaticCaseTimeScheduler form={form} />

            <FormField
              control={form.control}
              name="supplierIds"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col">
                    <FormLabel required>{t("suppliers")}</FormLabel>
                    <SelectMultipleSuppliers
                      onChange={field.onChange}
                      values={field.value?.map((id) => ({ id })) ?? []}
                    />
                    <FormDescription>{t("case_suppliers_description")}</FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="formTemplateId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("form_template")}</FormLabel>
                  <div className="flex w-full items-center gap-x-2">
                    <SelectSingleFormTemplate
                      onChange={field.onChange}
                      value={field.value}
                      type="CASE"
                    />

                    <Button
                      type="button"
                      disabled={!selectedFormTemplateId}
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                    >
                      {t("preview")}
                    </Button>
                  </div>
                  <FormDescription>{t("automatic_case_form_template_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("case_title")}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("message_for_supplier")}</FormLabel>
                  <FormControl>
                    <Textarea {...field} rows={5} value={field.value as string} />
                  </FormControl>
                  <FormDescription>{t("case_message_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="autoProcessOnResponse"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-y-1 space-y-0">
                  <div className="flex items-center gap-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        onCheckedChange={field.onChange}
                        name={field.name}
                        checked={field.value}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("auto_process_on_response")}</FormLabel>
                  </div>
                  <FormDescription>{t("auto_process_on_response_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="autoProcessOnDeadline"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-y-1 space-y-0">
                  <div className="flex items-center gap-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        onCheckedChange={field.onChange}
                        name={field.name}
                        checked={field.value}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("enable_auto_process_on_deadline")}</FormLabel>
                  </div>
                  <FormDescription>
                    {t("enable_auto_process_on_deadline_description")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={isLoading} type="submit">
                {t("save_changes")}
              </Button>
            </div>

            <Dialog
              open={dialogOpen}
              onOpenChange={(open) => {
                setDialogOpen(open);
              }}
            >
              <DialogContent size="full">
                {selectedFormContent.data?.formContent && (
                  <FormViewer mode="answers-view" form={selectedFormContent.data?.formContent} />
                )}
              </DialogContent>
            </Dialog>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
