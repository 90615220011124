import { ChartContainer } from "@/components/charts/chart-layout";
import { ChartZone } from "@/components/charts/chart-zone";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { useTranslation } from "react-i18next";

const smallChartOptionsLeft = [
  "text_chart_supplier_status",
  "text_chart_cases_ready_for_processing",
  "text_chart_cases_no_response",
  "text_chart_inspections_ready_for_processing",
] as const;

const smallChartOptionsRight = [
  "text_chart_cases_opened_within_deadline",
  "text_chart_cases_answered_within_deadline",
] as const;

const mediumChartOptionsLeft = ["bar_chart_cases_12_months"] as const;
const mediumChartOptionsRight = ["bar_chart_inspections_12_months"] as const;

const largeChartOptions = ["calendar_chart_cases", "calendar_chart_inspections"] as const;

export function SupplierOverviewPage() {
  const { t } = useTranslation();

  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("supplier_overview")}</PageTitle>
      </PageToolbar>

      <PageContent scroll className="gap-4">
        <ChartContainer className="md:grid-cols-2 lg:grid-cols-4">
          <ChartZone
            size="sm"
            zoneId="supplierpage_small_zone_1"
            defaultChart="text_chart_supplier_status"
            chartOptions={smallChartOptionsLeft}
          />
          <ChartZone
            size="sm"
            zoneId="supplierpage_small_zone_2"
            defaultChart="text_chart_cases_ready_for_processing"
            chartOptions={smallChartOptionsLeft}
          />
          <ChartZone
            size="sm"
            zoneId="supplierpage_small_zone_3"
            defaultChart="text_chart_cases_opened_within_deadline"
            chartOptions={smallChartOptionsRight}
          />
          <ChartZone
            size="sm"
            zoneId="supplierpage_small_zone_4"
            defaultChart="text_chart_cases_answered_within_deadline"
            chartOptions={smallChartOptionsRight}
          />
        </ChartContainer>
        <ChartContainer className="lg:grid-cols-2">
          <ChartZone
            size="md"
            zoneId="supplierpage_medium_zone_1"
            defaultChart="bar_chart_cases_12_months"
            chartOptions={mediumChartOptionsLeft}
          />
          <ChartZone
            size="md"
            zoneId="supplierpage_medium_zone_2"
            defaultChart="bar_chart_inspections_12_months"
            chartOptions={mediumChartOptionsRight}
          />
        </ChartContainer>
        <ChartContainer>
          <ChartZone
            size="lg"
            zoneId="supplierpage_large_zone_2"
            defaultChart="calendar_chart_inspections"
            chartOptions={largeChartOptions}
          />
        </ChartContainer>
      </PageContent>
    </Page>
  );
}
