import * as z from "zod";

export const tenantAutomatedCasesInsertInputSchema = z.object({
  title: z.string().min(1),
  supplierIds: z.array(z.string().uuid()).min(1),
  formTemplateId: z.string().uuid(),
  message: z.string().optional(),
  startSendoutAt: z.string().datetime(),
  stopSendoutAt: z.string().datetime().nullable().optional(),
  interval: z.object({
    type: z.enum(["days", "months", "years"]),
    amount: z.number().int().positive(),
  }),
  autoProcessOnResponse: z.boolean(),
  autoProcessOnDeadline: z.boolean(),
});

export const tenantAutomatedCasesInsertOutputSchema = z.object({
  id: z.string().uuid(),
});
