import { z } from "zod";

export const usersVerifyInputSchema = z.object({
  id: z.string().uuid(),
  otp: z.coerce
    .string()
    .trim()
    .superRefine((otp, ctx) => {
      if (otp.length !== 6) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          type: "string",
          maximum: 6,
          inclusive: true,
          exact: true,
        });
        return false;
      }
    }),
});
