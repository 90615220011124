import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { tenantDepartmentsInsertInputSchema } from "@timp/server/src/schemas/tenant-departments-insert.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "@/components/buttons/link-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { Page, PageTitle, PageToolbar, PageContent } from "@/components/layouts/page";
import { SelectMultipleSuppliers } from "@/components/selects/select-multiple-suppliers";

export function TenantSettingsDepartmentsCreatePage() {
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.tenantDepartmentsInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const form = useForm<z.infer<typeof tenantDepartmentsInsertInputSchema>>({
    resolver: zodResolver(tenantDepartmentsInsertInputSchema),
    defaultValues: {
      supplierIds: [],
    },
  });

  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle backLink>{t("department_create")}</PageTitle>
      </PageToolbar>

      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) =>
              mutate({
                ...data,
                tenantId,
              })
            )}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("department_name")}</FormLabel>
                  <FormControl>
                    <Input placeholder="Demo" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="supplierIds"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel required={false}>{t("add_department_to_supplier")}</FormLabel>
                  <SelectMultipleSuppliers
                    {...field}
                    values={field?.value?.map((id) => ({ id })) ?? []}
                    onChange={(val) => field.onChange(val.map((v) => v.id))}
                  />
                  <FormDescription>{t("add_department_to_supplier_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={isLoading} type="submit">
                {t("department_create")}
              </Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
