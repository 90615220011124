import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { SelectManageButton } from "./select-manage-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { useRole } from "@/hooks/useUser";
import { SelectMultipleBase, type ValueProps } from "./select-multiple";

export function SelectMultipleProjects({ values, onChange, disabled, className }: ValueProps) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const role = useRole(tenantId);

  const {
    data = [],
    isLoading,
    error,
  } = trpc.tenantProjectsList.useQuery({ tenantId }, { initialData: [] });

  const allOptions = [
    ...data.map((project) => ({ id: project.id, name: project.name, isNew: false })),
    ...values
      .filter((val) => !data.some((project) => project.id === val.id))
      .map((val) => ({ ...val, isNew: true })),
  ];

  const footer =
    role === "ADMIN" ? (
      <SelectManageButton to={`/tenants/${tenantId}/settings/projects`}>
        {t("manage_projects")}
      </SelectManageButton>
    ) : null;

  return (
    <SelectMultipleBase
      className={className}
      disabled={disabled}
      options={allOptions}
      isLoading={isLoading}
      error={error}
      values={values}
      onChange={(selectedIds) => {
        const newValues = selectedIds
          .map((selected) => allOptions.find((opt) => opt.id === selected.id))
          .filter((v): v is NonNullable<typeof v> => v !== undefined);
        onChange(newValues);
      }}
      footer={footer}
    />
  );
}
