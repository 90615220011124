import * as z from "zod";

export const tenantSuppliersDocumentsUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1).optional(),
  file: z
    .object({
      name: z.string().min(1),
      type: z.string().min(0),
      content: z.string().min(0).optional(),
      size: z.number().int().min(0),
    })
    .optional(),
  expiresAt: z.string().datetime().optional().nullable(),
  documentCategoryId: z.string().uuid().optional().nullable(),
});

export const tenantSuppliersDocumentsUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
