import { t } from "i18next";
import { TypographyH3 } from "../ui/typography";
import { ChartContentLayout } from "./chart-layout";
import { Link } from "@/components/links/Link";
import { trpc } from "@/lib/providers/trpc";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { useSupplierId } from "@/hooks/useSupplier";
import { parseServerTableFiltersIntoURLParams } from "../tables/table-utils";

export function TextChartCasesReadyForProcessing() {
  const tenantId = useTenantIdSafe();
  const supplierId = useSupplierId();

  const query = trpc.tenantWidgetCasesReadyForProcessingCount.useQuery({
    tenantId,
    supplierId,
  });
  const supplierQuery = trpc.tenantSuppliersById.useQuery(
    { id: supplierId!, tenantId },
    {
      enabled: !!supplierId,
    }
  );
  const supplierFilter = supplierQuery.data?.name
    ? { id: "supplierName", value: [supplierQuery.data?.name] }
    : undefined;
  const params = parseServerTableFiltersIntoURLParams({
    filters: [supplierFilter],
    timeFilters: [],
    pageIndex: 0,
    search: "",
    sorting: [
      {
        id: "responseReceivedAt",
        desc: false,
      },
    ],
    filterOrder: supplierFilter ? ["supplierName"] : undefined,
  });

  return (
    <ChartContentLayout error={query.error} isLoading={query.isLoading}>
      <TypographyH3>{query.data?.count}</TypographyH3>

      <Link
        to={`/tenants/${tenantId}/tasks/case-response?${params.toString()}`}
        className="text-sm text-blue-600 hover:underline"
      >
        {t("go_to_tasks")}
      </Link>
    </ChartContentLayout>
  );
}
