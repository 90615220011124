import { UnsavedChangesBlocker } from "@/components/dialogs/unsaved-changes-blocker";
import { FormBuilder } from "@/components/form-builders/form-builder";
import { Page, PageTitle, PageToolbar, PageContent } from "@/components/layouts/page";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { trpc } from "@/lib/providers/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { formTemplatesCommonInsertSchemaInput } from "@timp/server/src/schemas/form-templates-common.schema";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useTenantIdSafe } from "@/hooks/useTenant";
import type { z } from "zod";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { LinkButton } from "@/components/buttons/link-button";
import { SelectSingleSysadminPublishedFormTemplate } from "@/components/selects/select-single";
import { FeatureFlagInput } from "@/components/inputs/feature-flag-input";

export function TenantSettingsInspectionFormsCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenantId = useTenantIdSafe();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isminimum14DayRollingUsageEnabled, setIsminimum14DayRollingUsageEnabled] = useState(false);

  const insertMutation = trpc.tenantFormTemplatesInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const form = useForm<
    Extract<z.infer<typeof formTemplatesCommonInsertSchemaInput>, { type: "INSPECTION" }>
  >({
    mode: "onChange",
    resolver: zodResolver(formTemplatesCommonInsertSchemaInput),
    defaultValues: {
      type: "INSPECTION",
      published: false,
      formContent: {
        sections: [],
      },
      enableEquipment: "OPTIONAL",
      enableLocations: "OPTIONAL",
      enableDepartments: "OPTIONAL",
      enableProjects: "OPTIONAL",
      enableSuppliers: "OPTIONAL",
    },
  });
  const copiedFromId = form.watch("copiedFromId");
  const copiedTemplateQuery = trpc.tenantSysadminFormTemplatesById.useQuery(
    {
      tenantId,
      id: copiedFromId!,
    },
    {
      enabled: !!copiedFromId,
    }
  );

  useEffect(() => {
    if (!copiedFromId) {
      form.setValue("formContent", { sections: [] });
    } else if (copiedTemplateQuery.data) {
      form.setValue("formContent", copiedTemplateQuery.data?.formContent);
    }
  }, [copiedTemplateQuery.data, form, copiedFromId]);

  const hasUnsavedChanges = !!form.formState.dirtyFields.formContent;

  return (
    <Page className="overflow-x-auto">
      <PageToolbar>
        <PageTitle backLink>{t("create_inspection_form")}</PageTitle>
      </PageToolbar>

      <PageContent scroll>
        <Form {...form}>
          <div className={cn("space-y-6")}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("form_name")}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="copiedFromId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("form_content")}</FormLabel>
                  <div className="flex w-full items-center gap-x-2">
                    <SelectSingleSysadminPublishedFormTemplate
                      treatNullAsEmptyOption
                      onChange={field.onChange}
                      value={field.value}
                      type="INSPECTION"
                    />
                    <Button
                      type="button"
                      onClick={() => {
                        form.trigger("formContent");
                        setDialogOpen(true);
                      }}
                    >
                      {t("edit_form")}
                    </Button>
                  </div>
                  <FormDescription>{t("create_form_template_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="minimum14DayRollingUsage"
              render={({ field }) => (
                <FormItem>
                  <div className="mb-2 flex items-center gap-x-2">
                    <FormControl>
                      <Checkbox
                        checked={isminimum14DayRollingUsageEnabled}
                        onCheckedChange={(checked) => {
                          setIsminimum14DayRollingUsageEnabled(checked === true);
                          field.onChange(null);
                        }}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("minimum_rolling_inspections")}</FormLabel>
                  </div>
                  {isminimum14DayRollingUsageEnabled && (
                    <FormControl>
                      <Input
                        {...field}
                        onChange={(ev) => {
                          const value = ev.target.value.replace(/[^\d]/g, "");
                          field.onChange(Number.parseInt(value, 10) || null);
                        }}
                        value={field.value ?? ""}
                        type="number"
                      />
                    </FormControl>
                  )}
                  <FormDescription>{t("minimum_rolling_inspections_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="published"
              render={({ field }) => (
                <FormItem className="flex flex-col">
                  <div className="flex items-center gap-x-2">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        name={field.name}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("published")}</FormLabel>
                  </div>
                  <FormDescription>{t("form_published_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="enableSuppliers"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("supplier")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableSuppliers"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("suppliers_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="enableDepartments"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("departments")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableDepartments"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("departments_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="enableProjects"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("projects")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableProjects"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("projects_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="enableLocations"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("locations")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableLocations"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("locations_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="enableEquipment"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={true}>{t("equipment")}</FormLabel>
                  <FormControl>
                    <FeatureFlagInput
                      name="enableEquipment"
                      value={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <FormDescription>{t("equipment_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button
                isLoading={insertMutation.isLoading}
                type="button"
                onClick={() =>
                  form.handleSubmit(
                    (formData) => {
                      insertMutation.mutate({ ...formData, tenantId });
                    },
                    (errors) => {
                      if (Object.keys(errors.formContent ?? {}).length > 0) {
                        setDialogOpen(true);
                      }
                    }
                  )()
                }
              >
                {t("form_create")}
              </Button>
            </div>
          </div>

          <Dialog
            open={dialogOpen}
            onOpenChange={(open) => {
              setDialogOpen(open);
            }}
          >
            <DialogContent size="full">
              <FormBuilder />
            </DialogContent>
          </Dialog>
        </Form>
      </PageContent>

      <UnsavedChangesBlocker hasUnsavedChanges={hasUnsavedChanges && insertMutation.isIdle} />
    </Page>
  );
}
