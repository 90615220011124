export const DEFAULT_TENANT_ROUTINE_DESCRIPTION = [
  {
    type: "paragraph",
    children: [{ bold: true, text: "Rutine for Oppfølging av Underleverandører ved Bruk av TIMP" }],
  },
  { type: "paragraph", children: [{ bold: true, text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "1. Innledning" }] },
  {
    type: "paragraph",
    children: [
      { text: "" },
      { type: "mention", children: [{ text: "" }], character: "COMPANY_NAME" },
      {
        text: " benytter TIMP for å sikre effektiv og lovpålagt oppfølging av sine underleverandører. TIMP er et system utviklet for å automatisere utsendelse og innhenting av nødvendig informasjon fra underleverandører. TIMP sender ut påminnelser til mottaker og registrerer automatisk avvik ved manglende svar. Systemet har støtte for å utføre inspeksjoner/stikkprøver av underleverandører, hvor avvik blir registrert direkte på leverandør. Videre passer TIMP på dokumentoppfølgning hvor selskapet blir varslet ved manglende registrert dokument på underleverandør. Informasjon som innhentes behandles internt og eventuelle avvik registreres og behandles systematisk for å sikre overholdelse av gjeldende regelverk. ",
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "2. Registrering av Underleverandører " }] },
  {
    type: "paragraph",
    children: [
      {
        text: "Alle underleverandører registreres i TIMP før samarbeid innledes. Dette sikrer at nødvendig informasjon kan formidles og at oppfølging kan gjennomføres i henhold til interne rutiner og lovkrav.",
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "3. Leverandøravtaler" }] },
  {
    type: "paragraph",
    children: [
      {
        text: "Alle underleverandører skal ha en leverandøravtale, som lastes opp eller innhentes via systemet. ",
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "4. Overholdelse av Lovpålagte Plikter " }] },
  { type: "paragraph", children: [{ bold: true, text: "4.1 Informasjonsplikt" }] },
  {
    type: "paragraph",
    children: [
      { text: "I henhold til allmenngjøringsloven er " },
      { type: "mention", children: [{ text: "" }], character: "COMPANY_NAME" },
      {
        text: ' forpliktet til å informere underleverandører om krav til lønns- og arbeidsvilkår. Dette ivaretas ved å sende ut skjemaet "Informasjonsplikt" via TIMP minst én gang årlig. Underleverandøren skal bekrefte mottak og forståelse av informasjonen. Manglende respons medfører automatisk avviksregistrering, som gjennomgås i risikovurderingen. ',
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "4.2 Påseplikt " }] },
  {
    type: "paragraph",
    children: [
      { text: "I samsvar med forskrift om informasjons- og påseplikt og innsynsrett skal " },
      { type: "mention", children: [{ text: "" }], character: "COMPANY_NAME" },
      {
        text: ' påse at underleverandører overholder allmenngjorte lønns- og arbeidsvilkår. Dette oppnås ved å sende ut skjemaet "Egenerklæring" gjennom TIMP. Dersom underleverandøren ikke besvarer egenerklæringen, registreres et automatisk avvik. Innsendte skjemaer vurderes, og eventuelle avvik registreres og gjennomgås i risikovurderingen. ',
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "4.3 Medvirkerplikt " }] },
  {
    type: "paragraph",
    children: [
      { text: "I tråd med kjøre- og hviletidsbestemmelsene har " },
      { type: "mention", children: [{ text: "" }], character: "COMPANY_NAME" },
      {
        text: " ansvar for å sikre at transportoppdrag utføres i samsvar med gjeldende regelverk. Dette innebærer jevnlige inspeksjoner av underleverandører. Ved identifisering av avvik registreres disse i TIMP og behandles i henhold til interne prosedyrer. ",
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "4.4 Åpenhetsloven " }] },
  {
    type: "paragraph",
    children: [
      { text: "I henhold til Åpenhetsloven er " },
      { type: "mention", children: [{ text: "" }], character: "COMPANY_NAME" },
      {
        text: ' forpliktet til å gjennomføre aktsomhetsvurderinger. Dette ivaretas ved å innhente dokumentasjon fra underleverandører gjennom skjemaet "Åpenhetsloven" via TIMP. Innsamlet informasjon vurderes og lagres i systemet for å sikre etterlevelse av lovens krav. ',
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "5. Risikovurdering " }] },
  {
    type: "paragraph",
    children: [
      { text: "" },
      { type: "mention", children: [{ text: "" }], character: "COMPANY_NAME" },
      {
        text: " gjennomfører en årlig risikovurdering av underleverandører. I denne vurderingen analyseres registrerte avvik, og det tas beslutning om videre samarbeid eller nødvendige tiltak for å adressere identifiserte avvik. Denne prosessen dokumenteres i TIMP for å sikre sporbarhet og kontinuerlig forbedring. ",
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  { type: "paragraph", children: [{ bold: true, text: "6. Dokumentasjon og Revisjon " }] },
  {
    type: "paragraph",
    children: [
      {
        text: "Alle aktiviteter, inkludert utsendelser, mottak av skjemaer, avviksbehandling og risikovurderinger, dokumenteres i TIMP. Denne dokumentasjonen er tilgjengelig for interne revisjoner og ved eventuelle kontroller fra Arbeidstilsynet, i tråd med lovpålagte krav. ",
      },
    ],
  },
  { type: "paragraph", children: [{ text: "" }] },
  {
    type: "paragraph",
    children: [
      { text: "Ved å følge denne rutinen sikrer " },
      { type: "mention", children: [{ text: "" }], character: "COMPANY_NAME" },
      {
        text: " en profesjonell og lovmessig oppfølging av sine underleverandører, i samsvar med gjeldende regelverk og beste praksis.",
      },
    ],
  },
];
