import * as z from "zod";

export const tenantSuppliersDocumentsInsertInputSchema = z.object({
  supplierId: z.string().uuid(),
  name: z.string().min(1),
  file: z.object({
    name: z.string().min(1),
    type: z.string().min(0),
    content: z.string().min(0),
    size: z.number().int().min(0),
  }),
  expiresAt: z.string().datetime().nullable().optional(),
  documentCategoryId: z.string().uuid().nullable().optional(),
});

export const tenantSuppliersDocumentsInsertOutputSchema = z.object({
  id: z.string().uuid(),
});
