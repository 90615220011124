import type { RouterOutput } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { AlertCircleIcon, CheckCircle2Icon, XCircleIcon } from "lucide-react";

export type SupplierStatus = RouterOutput["tenantSuppliersList"]["rows"][number]["status"];

interface Props {
  status: SupplierStatus;
}

export function SupplierStatusIconLabel({ status }: Props) {
  const { t } = useTranslation();
  switch (status) {
    case "OK":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <CheckCircle2Icon strokeWidth={1.5} className="size-[18px] text-green-700" />{" "}
          {t(`${status}`)}
        </span>
      );
    case "CAN_USE":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <AlertCircleIcon strokeWidth={1.5} className="size-[18px] text-yellow-700" />{" "}
          {t(`${status}`)}
        </span>
      );
    case "SHOULD_NOT_BE_USED":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <XCircleIcon strokeWidth={1.5} className="size-[18px] text-red-700" /> {t(`${status}`)}
        </span>
      );
  }
}
