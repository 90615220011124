import { useCallback, useMemo } from "react";
import {
  format,
  formatDistance,
  formatDuration,
  formatRelative,
  formatDistanceToNow,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  type Day,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import { enGB } from "date-fns/locale/en-GB";
import { nb as nbNO } from "date-fns/locale/nb";
import { useTranslation } from "react-i18next";

/** List of Locales */
const locales = {
  en: enGB,
  no: nbNO,
};

type FormatDurationOptions = NonNullable<Parameters<typeof formatDuration>[1]>;
interface FormatDistanceCustomOptions extends FormatDurationOptions {
  onlyNumeric?: boolean;
  addSuffix?: boolean;
}

/**
 * Wrapper on top of date-fns that provides some helper functions and localized wrapper functions for formatting functions found in date-fns.
 *
 * @return {*} Mamoized list of all the wrappers and utilities for `date-fns`.
 */
const useDateFns = () => {
  const { i18n, t } = useTranslation();

  /** Memoize current date-fns locale. */
  const locale = useMemo(() => {
    if (i18n.language in locales) {
      return locales[i18n.language as keyof typeof locales];
    }
    return locales.en;
  }, [i18n.language]);

  /** Wrapper to inject the current user language into `format` function.  */
  const formatWithLocale: typeof format = useCallback(
    (date, formatStr, options) => {
      return format(date, formatStr, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDuration` function.  */
  const formatDurationWithLocale: typeof formatDuration = useCallback(
    (duration, options) => {
      return formatDuration(duration, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDistance` function.  */
  const formatDistanceWithLocale = useCallback(
    (date: Date, baseDate: Date, options?: FormatDistanceCustomOptions) => {
      return formatDistance(date, baseDate, {
        ...options,
        locale,
        onlyNumeric: options?.onlyNumeric,
      } as FormatDistanceCustomOptions);
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDistanceStrict` function.  */
  const formatDistanceStrictWithLocale: typeof formatDistanceStrict = useCallback(
    (date, baseDate, options) => {
      return formatDistanceStrict(date, baseDate, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDistanceToNow` function.  */
  const formatDistanceToNowWithLocale: typeof formatDistanceToNow = useCallback(
    (date, options) => {
      return formatDistanceToNow(date, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatDistanceToNowStrict` function.  */
  const formatDistanceToNowStrictWithLocale: typeof formatDistanceToNowStrict = useCallback(
    (date, options) => {
      return formatDistanceToNowStrict(date, { ...options, locale });
    },
    [locale]
  );

  /** Wrapper to inject the current user language into `formatRelative` function.  */
  const formatRelativeWithLocale: typeof formatRelative = useCallback(
    (date, baseDate, options) => {
      return formatRelative(date, baseDate, { ...options, locale });
    },
    [locale]
  );

  /** Array of localized weekdays names. */
  const weekdays: string[] = Array.from({ length: 7 }).map((_, i) =>
    locale.localize?.day(i as Day)
  );
  // Dinamically adjust day-name translations based on when the week starts.
  if (locale.options?.weekStartsOn === 1) {
    weekdays.push(weekdays.shift()!);
  }
  /** Array of localized weekday options. */
  const weekdaysOptions = useMemo(
    () => weekdays.map((day, i) => ({ value: i + 1, label: day })),
    [weekdays]
  );

  const padTimeWithZeroes = useCallback((time: number) => time.toString().padStart(2, "0"), []);

  const formatIntervalAsHHMM = useCallback(
    ({ hours, minutes }: { hours: number; minutes: number }) => {
      const hrs = padTimeWithZeroes(hours) + t("hour");
      const min = padTimeWithZeroes(minutes) + t("minute");
      return `${hrs} ${min}`;
    },
    [t, padTimeWithZeroes]
  );

  const formatRelativeLabel = useCallback(
    (timestamp: string | undefined) => {
      if (!timestamp) return `${t("never_used")}`.toLowerCase();
      const date = new Date(timestamp);
      const now = new Date();

      const years = differenceInYears(now, date);
      if (years > 0) {
        return `${years === 1 ? t("x_year_ago", { numYearsAgo: years }) : t("x_years_ago", { numYearsAgo: years })}`.toLowerCase();
      }

      const months = differenceInMonths(now, date);
      if (months > 0) {
        return `${months === 1 ? t("x_month_ago", { numMonthsAgo: months }) : t("x_months_ago", { numMonthsAgo: months })}`.toLowerCase();
      }

      const days = differenceInDays(now, date);
      if (days === 0) return `${t("today")}`.toLowerCase();
      if (days === 1) return `${t("yesterday")}`.toLowerCase();
      return `${t("x_days_ago", { numDaysAgo: days })}`.toLowerCase();
    },
    [t]
  );

  return useMemo(
    () => ({
      weekdaysOptions,
      locale,

      padTimeWithZeroes,
      formatIntervalAsHHMM,
      formatRelativeLabel,
      // Localized wrappers for formatters
      format: formatWithLocale,
      formatDistance: formatDistanceWithLocale,
      formatDuration: formatDurationWithLocale,
      formatRelative: formatRelativeWithLocale,
      formatDistanceToNow: formatDistanceToNowWithLocale,
      formatDistanceStrict: formatDistanceStrictWithLocale,
      formatDistanceToNowStrict: formatDistanceToNowStrictWithLocale,
    }),
    [
      formatDistanceStrictWithLocale,
      formatDistanceToNowStrictWithLocale,
      formatDistanceToNowWithLocale,
      formatDistanceWithLocale,
      formatDurationWithLocale,
      formatIntervalAsHHMM,
      formatRelativeWithLocale,
      formatWithLocale,
      locale,
      padTimeWithZeroes,
      weekdaysOptions,
      formatRelativeLabel,
    ]
  );
};

export { useDateFns };
