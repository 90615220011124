type PreviousTenant = Record<string, string>;

export function useLocalStorageTenant() {
  function parsePreviousTenant() {
    try {
      const result = JSON.parse(localStorage.getItem("previousTenant") as never) as PreviousTenant;
      return result;
    } catch (_error) {
      return {};
    }
  }
  function get(userId: string) {
    const result = parsePreviousTenant();
    return result?.[userId] || null;
  }

  function set(userId: string, tenantId: string) {
    const result = parsePreviousTenant();
    const newResult = { ...result, [userId]: tenantId };
    localStorage.setItem("previousTenant", JSON.stringify(newResult));
  }

  return { set, get };
}
