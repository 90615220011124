import { AlertCircleIcon, CheckCircle2Icon, ClockIcon, InfoIcon, XCircleIcon } from "lucide-react";

type Variant = "ok" | "warning" | "error" | "info" | "waiting";

interface Props {
  variant: Variant;
  label: React.ReactNode;
}

function getIcon(variant: Variant): JSX.Element {
  switch (variant) {
    case "ok":
      return <CheckCircle2Icon strokeWidth={1.5} className="size-4 text-green-700" />;
    case "warning":
      return <AlertCircleIcon strokeWidth={1.5} className="size-4 text-yellow-700" />;
    case "error":
      return <XCircleIcon strokeWidth={1.5} className="size-4 text-red-700" />;
    case "info":
      return <InfoIcon strokeWidth={1.5} className="size-4 text-blue-700" />;
    case "waiting":
      return <ClockIcon strokeWidth={1.5} className="size-4 text-gray-700" />;
  }
}

export function StatusIconLabel({ label, variant }: Props) {
  const icon = getIcon(variant);

  return (
    <span className="flex flex-row items-center gap-x-1.5">
      {icon}
      {label}
    </span>
  );
}
