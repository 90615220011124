import { Page } from "@/components/layouts/page";
import { TypographyH6 } from "@/components/ui/typography";
import { useLocalStorageTenant } from "@/hooks/useLocalStorageTenant";
import { useUser } from "@/hooks/useUser";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function TenantsChangePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const previousTenantHelper = useLocalStorageTenant();
  const user = useUser();
  const tenants = user.tenants;

  const previousTenantId = previousTenantHelper.get(user.id);

  useEffect(() => {
    const prevTenant = tenants.find((tenant) => tenant.tenantId === previousTenantId);
    const tenantToLogInto = prevTenant ?? tenants.at(0);

    if (tenantToLogInto) {
      navigate(`/tenants/${tenantToLogInto.tenantId}`);
    }
  }, [tenants, navigate, previousTenantId]);

  return (
    <Page className="container h-full">
      <div className="flex h-full flex-col items-center justify-center">
        {tenants.length === 0 && <TypographyH6>{t("you_have_no_tenants")}</TypographyH6>}
      </div>
    </Page>
  );
}
