import { useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Button } from "../ui/button";
import { cn } from "@/lib/utils";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { CheckIcon } from "lucide-react";
import { Command, CommandGroup, CommandItem } from "../ui/command";
import type { TRPCError } from "@/lib/providers/trpc";
import { Spinner } from "../icons/spinner";
import { TypographySmall } from "../ui/typography";

export function ChartContainer({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>) {
  return (
    <div className={cn("grid grid-cols-1 justify-items-center gap-4", className)}>{children}</div>
  );
}

interface ChartContentLayout {
  isLoading?: boolean;
  error?: Error | TRPCError | null;
}
export function ChartContentLayout({
  children,
  error,
  isLoading,
}: React.PropsWithChildren<ChartContentLayout>) {
  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <span className="text-destructive">{error.message}</span>
      </div>
    );
  }

  return (
    <div
      className={cn(
        "flex flex-1 flex-col justify-center",
        isLoading && "pointer-events-none select-none opacity-50"
      )}
    >
      {isLoading && (
        <div className="absolute inset-0 z-50 flex h-full w-full items-center justify-center">
          <Spinner size="sm" />
        </div>
      )}
      {!error && children}
    </div>
  );
}

interface ChartSelectProps {
  value: string;
  options: { id: string; name: string }[];
  onChange: (value: string) => void;
}
export function ChartSelect({ value, options, onChange }: ChartSelectProps) {
  const [open, setOpen] = useState(false);
  const selectedOption = options.find((option) => option.id === value);

  // Workaround: ref to the container element, necessary to fix scrolling of the popover inside dialogs
  // https://github.com/radix-ui/primitives/issues/1159#issuecomment-1741282769
  const containerRef = useRef<HTMLDivElement>(null);

  const canOpenDropdown = options.length > 1;
  return (
    <div ref={containerRef} className="overflow-x-hidden">
      <Popover open={open} onOpenChange={setOpen}>
        {canOpenDropdown ? (
          <PopoverTrigger asChild>
            <Button
              data-testid="chart-zone-select"
              type="button"
              variant="ghost"
              size="sm"
              // biome-ignore lint/a11y/useSemanticElements: we don't want a native <select> component, we use the shadcn Button
              role="combobox"
              className={cn(
                "justify-between gap-x-2 overflow-x-hidden truncate px-2 py-0",
                !value && "text-muted-foreground"
              )}
            >
              <TypographySmall className="-ml-2">{selectedOption?.name}</TypographySmall>
              <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
        ) : (
          <TypographySmall className="flex h-9 items-center">
            <span>{selectedOption?.name}</span>
          </TypographySmall>
        )}
        <PopoverContent
          container={containerRef.current}
          className="w-92 p-0"
          align="start"
          onOpenAutoFocus={(ev) => {
            ev.preventDefault();
          }}
        >
          <Command>
            <CommandGroup className="max-h-64 overflow-auto">
              {options.map((option) => (
                <CommandItem
                  value={option.id}
                  key={option.id}
                  onSelect={(id: string) => {
                    onChange(id);
                    setOpen(false);
                  }}
                  className="flex justify-between gap-x-2"
                >
                  <span>{option.name}</span>
                  <CheckIcon
                    className={cn("h-4 w-4", value === option.id ? "opacity-100" : "opacity-0")}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
