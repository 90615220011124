import { Outlet } from "react-router-dom";
import TimpLogo from "../icons/timp-logo";
import React, { createContext } from "react";
import { useTranslation } from "react-i18next";
import { useTenantId } from "@/hooks/useTenant";
import { AuthProvider } from "@/lib/providers/auth-provider";
import { TenantMenu } from "../dropdown-menus/tenant-menu";
import { UserMenu } from "../dropdown-menus/user-menu";
import {
  AlertCircleIcon,
  Building2Icon,
  FileInputIcon,
  FileSearch2Icon,
  SettingsIcon,
} from "lucide-react";
import { useRole, useRoleGuard } from "@/hooks/useUser";
import { Link } from "@/components/links/Link";
import { NotificationMenu } from "../dropdown-menus/notification-menu";
import { TasksMenu } from "../dropdown-menus/tasks-menu";
import { NavigationNavLink } from "../buttons/navigation-link";

interface DrawerArgs {
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const MainLayoutContext = createContext<DrawerArgs>(null!);

export function MainLayout() {
  return (
    <AuthProvider>
      <MainLayoutInternal />
    </AuthProvider>
  );
}

function MainLayoutInternal() {
  const { t } = useTranslation();

  const tenantId = useTenantId();
  const role = useRole(tenantId);

  useRoleGuard("ADMIN", "MANAGER");

  return (
    <div className="flex h-full flex-col overflow-hidden">
      {/* <ScrollArea> */}
      {/* <ScrollBar orientation="horizontal" /> */}
      <nav className="flex w-full flex-row items-center justify-between overflow-x-auto border-b border-background/10 bg-primary py-2 pl-2 pr-4 dark:border-primary/5 dark:bg-background">
        <div className="flex h-full items-center">
          <Link to="/tenants" className="flex items-center text-background">
            <TimpLogo />
            <div className="hidden text-primary-foreground dark:text-primary md:block">TIMP</div>
          </Link>

          {tenantId && (
            <div className="flex gap-x-2 md:ml-8">
              <NavigationNavLink to={`/tenants/${tenantId}/suppliers`}>
                <div className="flex items-center gap-x-1.5">
                  <Building2Icon className="size-4" />
                  {t("suppliers")}
                </div>
              </NavigationNavLink>
              <NavigationNavLink to={`/tenants/${tenantId}/cases`}>
                <div className="flex items-center gap-x-1.5">
                  <FileInputIcon className="size-4" />
                  {t("cases")}
                </div>
              </NavigationNavLink>
              <NavigationNavLink to={`/tenants/${tenantId}/inspections`}>
                <div className="flex items-center gap-x-1.5">
                  <FileSearch2Icon className="size-4" />
                  {t("inspections")}
                </div>
              </NavigationNavLink>
              <NavigationNavLink to={`/tenants/${tenantId}/deviations`}>
                <div className="flex items-center gap-x-1.5">
                  <AlertCircleIcon className="size-4" />
                  {t("deviations")}
                </div>
              </NavigationNavLink>
              {role === "ADMIN" && (
                <NavigationNavLink
                  to={`/tenants/${tenantId}/settings`}
                  data-testid="tenant-settings-link"
                >
                  <div className="flex items-center gap-x-1.5">
                    <SettingsIcon className="size-4" />
                    {t("tenant_settings")}
                  </div>
                </NavigationNavLink>
              )}
            </div>
          )}
        </div>

        <div className="flex flex-row items-center gap-4">
          {tenantId && <TasksMenu tenantId={tenantId} />}
          <NotificationMenu />
          <TenantMenu />
          <UserMenu />
        </div>
      </nav>
      {/* </ScrollArea> */}

      <div className="flex flex-1 flex-grow flex-col overflow-hidden">
        <Outlet />
      </div>
    </div>
  );
}
