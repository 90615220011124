import { Form } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import { FormViewerSectionContent } from "./form-viewer-section-content";
import { useTranslation } from "react-i18next";
import { formTemplatesCommonViewFormContentInputSchema } from "@timp/server/src/schemas/form-templates-common.schema";
import {
  FormBaseLayout,
  FormBaseMain,
  FormBaseMainContent,
  FormBaseMainHeader,
  FormBaseSectionTitle,
} from "../form-builders/form-base-layout";
import { TypographyMuted } from "../ui/typography";
import type { FormViewerShemaType } from "./form-viewer-types";

interface Props {
  form: FormViewerShemaType;
}

export function PrintFormViewer({ form: initialForm }: Props) {
  const { t } = useTranslation();

  const form = useForm<FormViewerShemaType>({
    resolver: zodResolver(formTemplatesCommonViewFormContentInputSchema),
    values: initialForm,
  });

  const { control } = form;
  const { fields: sections } = useFieldArray({
    control,
    name: "sections",
  });

  if (sections.length === 0) return null;

  return (
    <Form {...form}>
      <form className="flex flex-1 flex-col overflow-hidden" onSubmit={(e) => e.preventDefault()}>
        <FormBaseLayout>
          <FormBaseMain>
            {sections.map((section, index) => (
              <div className="print:break-after-page" key={section.id}>
                <FormBaseMainHeader>
                  <div className="flex items-center justify-between print:break-after-avoid">
                    <FormBaseSectionTitle>{section.name}</FormBaseSectionTitle>
                    {sections.length > 1 && (
                      <TypographyMuted className="min-w-24 text-nowrap">
                        ({t("page")} {index + 1}/{sections.length})
                      </TypographyMuted>
                    )}
                  </div>
                </FormBaseMainHeader>
                <FormBaseMainContent>
                  <FormViewerSectionContent
                    key={index}
                    sectionIndex={index}
                    control={control}
                    mode="print"
                  />
                </FormBaseMainContent>
              </div>
            ))}
          </FormBaseMain>
        </FormBaseLayout>
      </form>
    </Form>
  );
}
