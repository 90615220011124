import { zodStringOrNull } from "../utils/zod-extensions";
import * as z from "zod";

export const suppliersContactSchema = z.object({
  id: z.string().uuid().optional(),
  fullName: z.string().min(1).max(250).trim(),
  email: z.string().email().toLowerCase().trim(),
  phone: zodStringOrNull({ max: 50 }),
  role: zodStringOrNull({ max: 250 }),
  language: z.enum(["en", "no"]),
});

export const tenantSuppliersInsertInputSchema = z.object({
  tenantId: z.string().uuid(),
  name: z.string().min(1).max(250),
  language: z.enum(["en", "no"]),
  organizationNumber: z.string().min(1).max(250),
  internalId: z.string().min(1).max(250).optional(),
  groups: z.array(z.string().uuid()),
  projects: z.array(z.string().uuid()),
  departments: z.array(z.string().uuid()),
  mandatoryDocumentCategories: z.array(z.string().uuid()),
  status: z.enum(["CAN_USE", "SHOULD_NOT_BE_USED", "OK"]),
  contacts: z.array(suppliersContactSchema).min(1),
  automaticCases: z.array(z.string().uuid()),
  sendWelcomeEmail: z.boolean(),
  sendWelcomeEmailContent: z.array(z.any()).optional(),
});

export const tenantSuppliersInsertOutputSchema = z.object({
  id: z.string().uuid(),
});
