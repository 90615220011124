import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { tenantUsersInsertInputSchema } from "@timp/server/src/schemas/tenant-users-insert.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "@/components/buttons/link-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { Spinner } from "@/components/icons/spinner";

export function TenantSettingsUsersCreatePage() {
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.tenantUsersInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const emailValidation = trpc.validateEmail.useMutation({
    onSuccess(validationResult) {
      if (validationResult.suggestedEmail) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check_suggestion", {
            email: validationResult.suggestedEmail,
          }),
        });
      } else if (!validationResult.isValid) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check"),
        });
      } else {
        form.clearErrors("email");
      }
    },
  });

  const form = useForm<z.infer<typeof tenantUsersInsertInputSchema>>({
    resolver: zodResolver(tenantUsersInsertInputSchema),
  });

  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle backLink>{t("user_create")}</PageTitle>
      </PageToolbar>

      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => mutate({ ...data, tenantId }))}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("email")}</FormLabel>

                  <div className="relative">
                    <FormControl>
                      <Input
                        placeholder="olanordmann@example.no"
                        {...field}
                        onBlur={() => {
                          form.trigger("email").then((isValid) => {
                            if (isValid) {
                              emailValidation.mutate({ email: field.value });
                            }
                          });
                        }}
                      />
                    </FormControl>
                    {emailValidation.isLoading && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  {emailValidation.data?.suggestedEmail ? (
                    <button
                      type="button"
                      className="inline [&>p]:hover:underline"
                      onClick={() => {
                        field.onChange(emailValidation.data.suggestedEmail);
                        emailValidation.reset();
                        form.clearErrors("email");
                      }}
                    >
                      <FormMessage />
                    </button>
                  ) : (
                    <FormMessage />
                  )}
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("role")}</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    name={field.name}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="ADMIN">{t("ADMIN")}</SelectItem>
                      <SelectItem value="MANAGER">{t("MANAGER")}</SelectItem>
                      <SelectItem value="INSPECTOR">{t("INSPECTOR")}</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-wrap justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={isLoading}>{t("create")}</Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
