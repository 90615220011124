import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { DataTableLink } from "@/components/ui/data-table-link";
import { RelativeDate } from "@/components/ui/relative-date";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";

export function InspectionUnprocessedTasksPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("inspection_unprocessed_tasks")}</PageTitle>
      </PageToolbar>
      <PageContent>
        <ServerDataTable
          columnsStorageKey="inspection-unprocessed-tasks-page"
          query={trpc.tenantTasksInspectionsUnprocessedList}
          params={{ tenantId }}
          columns={[
            {
              id: "inspectionId",
              title: t("description"),
              render: (row) => {
                const description = t("inspection_unprocessed_task_desciption", {
                  inspectorName: row.inspectorName,
                  formTemplateName: row.formTemplateName,
                });
                return (
                  <DataTableLink to={`/tenants/${tenantId}/inspections/${row.inspectionId}`}>
                    {description}
                  </DataTableLink>
                );
              },
            },
            {
              id: "supplierName",
              title: t("supplier"),
              filter: true,
              enableSorting: true,
            },
            {
              id: "formTemplateName",
              title: t("form_template"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
            },
          ]}
          defaultSortColumn="createdAt"
          defaultSortOrder="asc"
          paginationPageSize={50}
        />
      </PageContent>
    </Page>
  );
}
