import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { type UseFormReturn, useController, useFieldArray } from "react-hook-form";
import type { FormViewerModes, FormViewerShemaType } from "./form-viewer-types";
import { RichTextEditor } from "@/components/rich-text-editor/rich-text-editor";
import { Button } from "../ui/button";
import { Trash2Icon } from "lucide-react";
import { TypographyError, TypographyMuted, TypographySmall } from "../ui/typography";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";
import { Checkbox } from "../ui/checkbox";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FileUploadInput } from "../inputs/file-upload-input";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "../icons/spinner";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { type PropsWithChildren, useState } from "react";
import { Textarea } from "../ui/textarea";
import { SignatureInput } from "../inputs/signature-input";
interface Props {
  sectionIdx: number;
  elementIdx: number;
  control: UseFormReturn<FormViewerShemaType>["control"];
  mode: FormViewerModes;
}

interface QuestionElementProps extends PropsWithChildren<Props> {
  hideComment?: boolean;
}

function QuestionElement(props: QuestionElementProps) {
  const { t } = useTranslation();

  const comment = useController({
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.comment`,
    control: props.control,
  });

  const question = useController({
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.question`,
    control: props.control,
  });

  const description = useController({
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.description`,
    control: props.control,
  });

  const [showComment, setShowComment] = useState(!!comment.field.value);

  const [animationRef] = useAutoAnimate();

  const field = useController({
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}` as const,
    control: props.control,
  });

  return (
    <div
      className={cn(
        "flex flex-col gap-y-3 rounded-md border p-4",
        !!field.fieldState.error && "border-destructive"
      )}
      data-testid="form-viewer-item-box"
    >
      <div>
        <FormField
          control={props.control}
          name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.isRequired`}
          render={({ field }) => (
            <FormLabel required={field.value}>{question.field.value}</FormLabel>
          )}
        />
        {description.field.value && <TypographyMuted>{description.field.value}</TypographyMuted>}
      </div>

      {props.children}

      {!props.hideComment && (
        <FormField
          control={props.control}
          name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.comment`}
          render={({ field }) => (
            <div>
              <div ref={animationRef}>
                {showComment && (
                  <FormItem>
                    <FormControl>
                      {props.mode === "answers-view" || props.mode === "print" ? (
                        <TypographySmall
                          className="opacity-70"
                          data-testid="form-viewer-item-comment-text"
                        >
                          {field.value}
                        </TypographySmall>
                      ) : (
                        <Textarea
                          name={field.name}
                          value={(field.value ?? "") as string}
                          onChange={field.onChange}
                        />
                      )}
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              </div>
              {props.mode !== "answers-view" && props.mode !== "print" && (
                <Button
                  variant="link"
                  className={cn("mt-2 h-auto p-0 text-sm text-blue-700")}
                  type="button"
                  onClick={() => {
                    if (showComment) {
                      field.onChange(null);
                      setShowComment(false);
                    } else {
                      setShowComment(true);
                    }
                  }}
                >
                  {showComment ? t("remove_comment") : t("add_comment")}
                </Button>
              )}
            </div>
          )}
        />
      )}
    </div>
  );
}

export function FormViewerTextQuestionElement(props: Props) {
  return (
    <QuestionElement {...props}>
      <FormField
        control={props.control}
        name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              {props.mode === "answers-view" || props.mode === "print" ? (
                <TypographySmall className="opacity-70">
                  {field.value as unknown as string}
                </TypographySmall>
              ) : (
                <Textarea
                  ref={field.ref}
                  name={field.name}
                  value={(field.value ?? "") as string}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                />
              )}
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </QuestionElement>
  );
}

export function FormViewerInformationElement(props: Props) {
  return (
    <QuestionElement {...props} hideComment>
      <FormField
        control={props.control}
        name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.text`}
        render={({ field }) => (
          <FormItem>
            <FormControl className="flex flex-col">
              <RichTextEditor
                readOnly
                initialValue={field.value as object[]}
                onChange={field.onChange}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </QuestionElement>
  );
}

export function FormViewerYesNoElement(props: Props) {
  const { t } = useTranslation();

  return (
    <QuestionElement {...props}>
      <FormField
        control={props.control}
        name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <RadioGroup
                disabled={props.mode === "answers-view" || props.mode === "print"}
                onValueChange={(value) => field.onChange(value === "true")}
                defaultValue={field.value?.toString()}
                className="flex flex-col"
                ref={field.ref}
              >
                <div className="flex flex-row">
                  <RadioGroupItem
                    value="true"
                    id={`${field.name}true`}
                    disabled={props.mode === "answers-view" || props.mode === "print"}
                  />
                  <FormLabel
                    required={false}
                    htmlFor={`${field.name}true`}
                    className="ml-1 flex-1 text-sm font-normal"
                  >
                    {t("yes")}
                  </FormLabel>
                </div>
                <div className="flex flex-row">
                  <RadioGroupItem
                    value="false"
                    id={`${field.name}false`}
                    disabled={props.mode === "answers-view" || props.mode === "print"}
                  />
                  <FormLabel
                    required={false}
                    htmlFor={`${field.name}false`}
                    className="ml-1 flex-1 text-sm font-normal"
                  >
                    {t("no")}
                  </FormLabel>
                </div>
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </QuestionElement>
  );
}

export function FormViewerMultipleChoiceElement(props: Props) {
  const { fields } = useFieldArray({
    control: props.control,
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.choices`,
  });

  const allowMultipleAnswers = useController({
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.allowMultipleAnswers`,
    control: props.control,
  });
  const answerField = useController({
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`,
    control: props.control,
  });

  const answerError =
    answerField.fieldState.error?.root?.message || answerField.fieldState.error?.message;

  return (
    <QuestionElement {...props}>
      {allowMultipleAnswers.field.value ? (
        <div className="flex flex-col gap-y-2">
          {fields.map((choice, index) => (
            <FormField
              key={choice.id}
              control={props.control}
              name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer.${index}`}
              render={({ field }) => (
                <FormItem className="w-full">
                  <div className="flex">
                    <FormControl>
                      <Checkbox
                        ref={answerField.field.ref} // important to keep the ref of the answer so we can focus on the first checkbox
                        onCheckedChange={field.onChange}
                        id={choice.id}
                        name={field.name}
                        checked={!!field.value}
                        disabled={props.mode === "answers-view" || props.mode === "print"}
                      />
                    </FormControl>
                    <FormLabel
                      required={false}
                      htmlFor={choice.id}
                      className="ml-1 flex-1 text-sm font-normal"
                    >
                      {choice.value}
                    </FormLabel>
                  </div>
                </FormItem>
              )}
            />
          ))}
        </div>
      ) : (
        <FormField
          control={props.control}
          name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`}
          render={({ field }) => (
            <FormItem className="w-full">
              <div className="flex">
                <FormControl className="flex flex-col">
                  <RadioGroup
                    ref={field.ref}
                    disabled={props.mode === "answers-view" || props.mode === "print"}
                    onValueChange={(selected) => {
                      const idx = fields.findIndex((choice) => choice.value === selected);
                      const array = Array.from({ length: fields.length }, (_, i) => i === idx);
                      field.onChange(array);
                    }}
                  >
                    {fields.map((choice, index) => (
                      <div key={choice.id} className="flex flex-row">
                        <RadioGroupItem
                          checked={(field.value as unknown as boolean[])[index]}
                          id={choice.id}
                          value={choice.value}
                          disabled={props.mode === "answers-view" || props.mode === "print"}
                        />
                        <FormLabel
                          required={false}
                          htmlFor={choice.id}
                          className={cn("ml-1 flex-1 text-sm font-normal")}
                        >
                          {choice.value}
                        </FormLabel>
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            </FormItem>
          )}
        />
      )}
      {answerError && <TypographyError>{answerError}</TypographyError>}
    </QuestionElement>
  );
}

export function FormViewerApprovalQuestionElement(props: Props) {
  const { t } = useTranslation();

  return (
    <QuestionElement {...props} hideComment>
      <FormField
        control={props.control}
        name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`}
        render={({ field }) => (
          <FormItem>
            <div className="flex gap-x-1">
              <FormControl className="flex flex-col">
                <Checkbox
                  onCheckedChange={field.onChange}
                  ref={field.ref}
                  name={field.name}
                  id={field.name}
                  checked={!!field.value}
                  disabled={props.mode === "answers-view" || props.mode === "print"}
                />
              </FormControl>
              <FormLabel required={false} htmlFor={field.name} className="ml-1">
                {t("i_accept")}
              </FormLabel>
            </div>
            <FormMessage />
          </FormItem>
        )}
      />
    </QuestionElement>
  );
}

type FileType = {
  id?: string;
  fileId: string;
  metadata: {
    name: string;
    size: number;
    type: string;
  };
};

export function FormViewerAttachmentElement(props: Props) {
  const { fields: _fields } = useFieldArray({
    control: props.control,
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.files`,
  });
  const fields = _fields as FileType[];

  return (
    <QuestionElement {...props} hideComment>
      <ul className="flex flex-col gap-y-2">
        {fields.map((file, index) => (
          <li key={index}>
            {props.mode === "print" ? (
              <TypographySmall className="text-primary/60">{file.metadata.name}</TypographySmall>
            ) : (
              <a href={`/api/rest/form-file-download/${file.fileId}`} download={file.metadata.name}>
                <TypographySmall className="text-primary/60 hover:underline">
                  {file.metadata.name}
                </TypographySmall>
              </a>
            )}
          </li>
        ))}
      </ul>
    </QuestionElement>
  );
}

export function FormViewerFileUploadElement(
  props: Props & { acceptFileTypes: string[]; uploadType: "file" | "image" }
) {
  const { t } = useTranslation();
  const [animationRef] = useAutoAnimate();
  const {
    fields: _fields,
    append: _append,
    remove,
    update: _update,
  } = useFieldArray({
    control: props.control,
    name: `sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`,
  });
  const fields = _fields as FileType[];
  const update = _update as (index: number, value: FileType) => void;
  const append = _append as (value: FileType[]) => void;

  const fileUpload = useMutation({
    mutationFn: async (data: { index: number; file: File }) => {
      const formData = new FormData();
      formData.append("file", data.file);
      const res = await fetch("/api/rest/form-file-upload", {
        method: "POST",
        body: formData,
      });
      const responseData = await res.json();
      return responseData as { fileId: string };
    },
    onSuccess(response, { index, file }) {
      update(index, {
        fileId: response.fileId,
        metadata: file as FileType["metadata"],
      });
    },
  });

  function onFilesSelected(files: File[]) {
    const newFormFiles = files.map((file) => ({ fileId: "", metadata: file }));
    append(newFormFiles);

    // since `fields` is not yet updated by `append` until next react render, we must make a copy so we can keep track of the indexes
    const currentStateFiles = [...fields, ...newFormFiles];

    currentStateFiles.forEach((formFile, index) => {
      const isUploadedToServer = !!formFile.fileId;
      // the runtime value is correct, typescript can't correctly infer that metadata is supposed to be a `File` so we must cast it
      const file = formFile.metadata as File;
      if (!isUploadedToServer) {
        fileUpload.mutate({
          index,
          file,
        });
      }
    });
  }
  function onFilesSelectedPreview(files: File[]) {
    const newFormFiles = files.map((file, idx) => ({
      fileId: idx.toString(),
      metadata: file,
    }));
    append(newFormFiles);
  }

  return (
    <QuestionElement {...props}>
      <FormField
        control={props.control}
        name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`}
        render={({ field }) => (
          <FormItem>
            {props.mode !== "answers-view" && props.mode !== "print" && (
              <FileUploadInput
                ref={field.ref}
                multiple
                accept={props.acceptFileTypes}
                onFilesSelected={
                  props.mode === "preview-builder" ? onFilesSelectedPreview : onFilesSelected
                }
                uploadType={props.uploadType}
              />
            )}
            <FormMessage />
          </FormItem>
        )}
      />
      <div>
        {fields.length ? (
          <ul ref={animationRef} className="list flex flex-col gap-y-2">
            {fields.map((item, fileIndex) => (
              <li key={item.id}>
                <FormField
                  control={props.control}
                  name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer.${fileIndex}.metadata`}
                  render={({ field }) => (
                    <FormItem>
                      <FormControl>
                        <div className="no-drag flex items-center gap-x-2">
                          {!item.fileId && <Spinner />}

                          {props.mode === "answers-view" && (
                            <a
                              href={`/api/rest/form-file-download/${item.fileId}`}
                              download={field.value.name}
                            >
                              <TypographySmall className="text-primary/60 hover:underline">
                                {field.value.name}
                              </TypographySmall>
                            </a>
                          )}

                          {(props.mode === "submit" ||
                            props.mode === "page" ||
                            props.mode === "preview-builder" ||
                            props.mode === "print") && (
                            <TypographySmall className="text-primary/60">
                              {field.value.name}
                            </TypographySmall>
                          )}

                          {(props.mode === "submit" || props.mode === "page") && (
                            <Button
                              type="button"
                              className="h-8 w-8 p-0"
                              variant="ghost"
                              disabled={fileUpload.isLoading}
                              onClick={() => remove(fileIndex)}
                            >
                              <Trash2Icon className="h-4 w-4" strokeWidth={1.5} />
                            </Button>
                          )}
                        </div>
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </li>
            ))}
          </ul>
        ) : (
          <TypographyMuted>{t("no_files_uploaded")}</TypographyMuted>
        )}
      </div>
    </QuestionElement>
  );
}

export function FormViewerSignatureElement(props: Props) {
  return (
    <QuestionElement {...props}>
      <FormField
        control={props.control}
        name={`sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              {props.mode === "answers-view" || props.mode === "print" ? (
                <SignatureInput disabled initialSignature={field.value as string} />
              ) : (
                <SignatureInput
                  onChange={field.onChange}
                  initialSignature={field.value as string}
                />
              )}
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </QuestionElement>
  );
}
