import React from "react";
import { Button, type ButtonProps } from "../ui/button";
import { PaperclipIcon } from "lucide-react";
import { TypographySmall } from "../ui/typography";

interface FileActionButtonProps extends ButtonProps {
  filename: string;
}

export const FileActionButton = React.forwardRef<HTMLButtonElement, FileActionButtonProps>(
  ({ filename, children, isLoading, ...props }, ref) => {
    return (
      <div className="flex">
        <div className="flex h-10 items-center gap-x-2 rounded-s-lg border border-e-0 bg-muted px-4">
          <PaperclipIcon className="size-5" />
          <TypographySmall>{filename}</TypographySmall>
        </div>
        <Button
          ref={ref}
          type="button"
          className="gap-x-2 rounded-e-lg rounded-s-none px-3 py-3"
          {...props}
          isLoading={isLoading}
        >
          {children}
        </Button>
      </div>
    );
  }
);
