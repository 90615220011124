import { z } from "zod";
import { timezones } from "./timezones";

// ! This file is imported by procedure schema files, meaning this will end up in the frontend, make sure to not expose any sensitive data here
export const zodTimestamp = z.date().transform((date) => date.toISOString());

export const zodDate = z.date().transform((date) => date.toISOString().split("T")[0]);

export const zodTimeZone = z
  .string()
  .transform((value) => (timezones.includes(value) ? value : "Europe/Oslo"));

export const zodStringOrNull = (opts: { max: number }) =>
  z
    .string()
    .trim()
    .max(opts.max)
    .transform((value) => (value === "" || value === undefined ? null : value))
    .nullable()
    .optional();

// export const zodNoEmptyString = (schema: z.ZodTypeAny) => z.string().transform(val => val || null).nullable().pipe(schema)
