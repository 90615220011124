import { type UseFormReturn, useFieldArray, useFormContext } from "react-hook-form";
import type { FormShemaType } from "./form-builder-types";
import {
  FormBuilderTextQuestionElement,
  FormBuilderInformationElement,
  FormBuilderYesNoElement,
  FormBuilderMultipleChoiceElement,
  FormBuilderApprovalQuestionElement,
  FormBuilderAttachmentElement,
  FormBuilderFileUploadElement,
  FormBuilderSignatureElement,
} from "./form-builder-elements";
import { Button } from "../ui/button";
import { PopoverTrigger, Popover, PopoverContent } from "../ui/popover";
import { PlusCircleIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { PopoverClose } from "@radix-ui/react-popover";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FormField, FormItem, FormMessage } from "../ui/form";

interface Props {
  sectionIndex: number;
  control: UseFormReturn<FormShemaType>["control"];
}
export function FormBuilderSectionContent({ sectionIndex, control }: Props) {
  const { t } = useTranslation();
  const [animationRef] = useAutoAnimate();
  const form = useFormContext();
  const {
    fields: elements,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: `formContent.sections.${sectionIndex}.elements`,
  });

  return (
    <div className="flex w-full flex-col gap-y-3">
      <div className="flex w-full flex-col gap-y-6" ref={animationRef}>
        <FormField
          key={sectionIndex}
          control={control}
          name={`formContent.sections.${sectionIndex}.elements`}
          render={() => {
            return (
              <FormItem>
                <FormMessage />
              </FormItem>
            );
          }}
        />
        {elements.map((element, index) => {
          return (
            <div key={element.id}>
              {element.type === "textQuestion" && (
                <FormBuilderTextQuestionElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}

              {element.type === "information" && (
                <FormBuilderInformationElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}
              {element.type === "yesNoQuestion" && (
                <FormBuilderYesNoElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}
              {element.type === "multipleChoiceQuestion" && (
                <FormBuilderMultipleChoiceElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}

              {element.type === "approvalQuestion" && (
                <FormBuilderApprovalQuestionElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}

              {element.type === "attachment" && (
                <FormBuilderAttachmentElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}

              {(element.type === "fileUpload" || element.type === "imageUpload") && (
                <FormBuilderFileUploadElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}
              {element.type === "signature" && (
                <FormBuilderSignatureElement
                  control={control}
                  sectionIdx={sectionIndex}
                  elementIdx={index}
                  remove={remove}
                  move={move}
                />
              )}
            </div>
          );
        })}
      </div>

      <Popover>
        <PopoverTrigger asChild>
          <Button variant="outline" className="w-full">
            <PlusCircleIcon size={16} strokeWidth={1} />
            {t("add_element")}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex flex-col gap-2 p-4">
          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "textQuestion",
                  isRequired: true,
                  question: "",
                  answer: null,
                  comment: null,
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("textQuestion")}
            </Button>
          </PopoverClose>
          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "information",
                  text: [
                    {
                      type: "paragraph",
                      children: [{ text: "" }],
                    },
                  ],
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("information")}
            </Button>
          </PopoverClose>

          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "yesNoQuestion",
                  answer: null,
                  comment: null,
                  isRequired: true,
                  question: "",
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("yesNoQuestion")}
            </Button>
          </PopoverClose>

          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "multipleChoiceQuestion",
                  choices: [],
                  answer: [],
                  comment: null,
                  isRequired: true,
                  question: "",
                  allowMultipleAnswers: false,
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("multipleChoiceQuestion")}
            </Button>
          </PopoverClose>

          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "approvalQuestion",
                  answer: null,
                  question: "",
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("approvalQuestion")}
            </Button>
          </PopoverClose>

          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "fileUpload",
                  answer: [],
                  comment: null,
                  question: "",
                  isRequired: true,
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("file_upload")}
            </Button>
          </PopoverClose>

          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "imageUpload",
                  answer: [],
                  comment: null,
                  question: "",
                  isRequired: true,
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("image_upload")}
            </Button>
          </PopoverClose>

          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "attachment",
                  files: [],
                  comment: null,
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("attachment")}
            </Button>
          </PopoverClose>

          <PopoverClose asChild>
            <Button
              type="button"
              onClick={() => {
                append({
                  type: "signature",
                  question: "",
                  description: "",
                  isRequired: true,
                  answer: null,
                  comment: null,
                });
                form.trigger(`formContent.sections.${sectionIndex}.elements`);
              }}
            >
              {t("signature")}
            </Button>
          </PopoverClose>
        </PopoverContent>
      </Popover>
    </div>
  );
}
