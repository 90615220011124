import { z } from "zod";

export const tenantEquipmentUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1).max(255),
});

export const tenantEquipmentUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
