import { CommandGroup, CommandItem, CommandList, CommandInput } from "@/components/ui/command";
import { Command as CommandPrimitive } from "cmdk";
import { useState, useCallback, forwardRef, useRef } from "react";
import { Check } from "lucide-react";
import { cn } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { CenteredSpinner, Spinner } from "../icons/spinner";
import { TypographyError } from "./typography";
import { Badge } from "./badge";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export type Option = {
  value: string;
  label: string;
};

type AutoCompleteProps = {
  options: Option[];
  emptyMessage: string;
  value?: Option;
  onValueChange?: (value: Option) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  disabled?: boolean;
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
  searchValue: string;
  isError?: boolean;
  isFetching?: boolean;
  className?: string;
  autoComplete?: string;
  name?: string;
};

export const AutoComplete = forwardRef<HTMLInputElement, AutoCompleteProps>(
  (
    {
      options,
      placeholder,
      emptyMessage,
      value,
      onValueChange,
      disabled,
      onSearch,
      searchValue,
      className,
      onKeyDown,
      autoComplete,
      name,
      isError = false,
      isLoading = false,
      isFetching = false,
    }: AutoCompleteProps,
    ref
  ) => {
    const { t } = useTranslation();
    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState<Option | null>(value || null);
    const [inputValue, setInputValue] = useState(searchValue || value?.label || "");

    const handleKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!isOpen) {
          setOpen(true);
        }

        if (event.key === "Enter") {
          const optionToSelect = options.find((option) => option.value === inputValue);
          if (optionToSelect) {
            setSelected(optionToSelect);
            onValueChange?.(optionToSelect);
          } else {
            setSelected({ value: "", label: inputValue });
            onValueChange?.({ value: "", label: inputValue });
          }
          setOpen(false);
        }

        if (event.key === "Escape") {
          setOpen(false);
        }

        if (event.key === "Tab") {
          setOpen(false);
        }
      },
      [isOpen, onValueChange, options, inputValue]
    );

    const closeList = useCallback(() => {
      setOpen(false);
    }, []);

    const handleSelectOption = useCallback(
      (selectedOption: Option) => {
        onSearch(selectedOption?.label);

        setSelected(selectedOption);
        onValueChange?.(selectedOption);
        setInputValue(selectedOption.label);
        setOpen(false);
      },
      [onValueChange, onSearch]
    );

    const handleInputChange = useCallback(
      (searchTerm: string) => {
        setInputValue(searchTerm);
        if (searchTerm.length === 0) {
          setOpen(false);
        } else {
          setOpen(true);
        }
        onSearch(searchTerm);
      },
      [onSearch]
    );

    const showEmptyMessage = !isLoading && options.length === 0 && isOpen;
    const showOptions = options.length > 0 && isOpen;
    const showError = isError && isOpen;
    const showLoading = isLoading && isOpen;
    const showFetchSpinner = isFetching && isOpen;

    const containerRef = useRef<HTMLDivElement>(null);
    return (
      <CommandPrimitive onKeyDown={handleKeyDown} filter={() => 1}>
        <div ref={containerRef} className="relative w-full">
          <Popover open={isOpen}>
            <PopoverTrigger asChild>
              <div className="relative">
                <CommandInput
                  autoComplete={autoComplete}
                  ref={ref}
                  name={name}
                  disableSearchIcon
                  wrapperClassName="border-none px-0"
                  className={cn("h-9 border border-input px-3", className)}
                  data-testid="autocomplete-input"
                  value={value?.value || inputValue}
                  onKeyDown={onKeyDown}
                  onValueChange={handleInputChange}
                  onBlur={closeList}
                  onFocus={() => {
                    if (options.length > 0) {
                      setOpen(true);
                    }
                  }}
                  placeholder={placeholder}
                  disabled={disabled}
                />
                {showFetchSpinner && <Spinner className="absolute right-3 top-3 z-50" />}
              </div>
            </PopoverTrigger>

            <PopoverContent
              container={containerRef.current}
              className="p-0"
              align="start"
              onOpenAutoFocus={(ev) => {
                ev.preventDefault();
              }}
            >
              <div
                className={cn(
                  "z-10 w-full rounded-xl bg-white outline-none animate-in fade-in-0 zoom-in-95"
                )}
              >
                <CommandList
                  className={cn(
                    "rounded-lg ring-slate-200",
                    (showOptions || showEmptyMessage || showError) && "ring-1"
                  )}
                >
                  {showLoading && (
                    <CommandPrimitive.Loading>
                      <div className="h-full p-1">
                        <CenteredSpinner />
                      </div>
                    </CommandPrimitive.Loading>
                  )}
                  {showOptions ? (
                    <CommandGroup>
                      {options.map((option) => {
                        const isSelected =
                          selected?.value === option.value && selected?.label === option.label;
                        return (
                          <CommandItem
                            key={option.value}
                            value={option.value}
                            onMouseDown={(event: React.MouseEvent) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            onSelect={() => handleSelectOption(option)}
                            className={cn(
                              "flex w-full items-center gap-2",
                              !isSelected ? "pl-2" : null
                            )}
                          >
                            {isSelected ? <Check className="w-4" /> : null}
                            <span className="truncate">{option.label}</span>
                            <Badge className="border p-0.5 text-gray-500" variant="secondary">
                              {option.value}
                            </Badge>
                          </CommandItem>
                        );
                      })}
                    </CommandGroup>
                  ) : null}
                  {showError && (
                    <TypographyError className="text-center">
                      {t("error_loading_companies")}
                    </TypographyError>
                  )}
                  {showEmptyMessage && (
                    <div className="select-none rounded-sm px-2 py-3 text-center text-sm">
                      <span>{emptyMessage}</span>
                    </div>
                  )}
                </CommandList>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </CommandPrimitive>
    );
  }
);
