import * as z from "zod";

export const tenantInspectionsProcessInputSchema = z.object({
  id: z.string().uuid(),
  deviations: z.array(
    z.object({
      id: z.string().uuid().optional(),
      severity: z.enum(["LOW", "MEDIUM", "HIGH"]),
      description: z.string().min(1),
    })
  ),
  comment: z.string().min(1),
  supplierId: z.string().uuid(),
  projectId: z.string().uuid().nullable().optional(),
  departmentId: z.string().uuid().nullable().optional(),
  locationId: z.string().uuid().nullable().optional(),
  equipmentId: z.string().uuid().nullable().optional(),
});

export const tenantInspectionsProcessOutputSchema = z.object({
  id: z.string().uuid(),
});
