import * as z from "zod";

const CONSTANT_CASE_REGEX = /^[A-Z_]+$/;

export const systemVariablesUpdateByIdInputSchema = z.object({
  id: z.string().min(1).max(250).regex(CONSTANT_CASE_REGEX),
  value: z.string().min(1).max(1000).optional(),
});

export const systemVariablesUpdateByIdOutputSchema = z.object({
  id: z.string(),
});
