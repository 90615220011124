import { useUser } from "@/hooks/useUser";
import { SelectMultipleBase, type ValueProps } from "./select-multiple";

export function SelectMultipleTenants({ values, onChange, disabled, className }: ValueProps) {
  const user = useUser();
  const tenants = user.tenants;

  const allOptions = [
    ...tenants.map((tenant) => ({ id: tenant.tenantId, name: tenant.name, isNew: false })),
    ...values
      .filter((val) => !tenants.some((tenant) => tenant.tenantId === val.id))
      .map((val) => ({ ...val, isNew: true })),
  ];

  return (
    <SelectMultipleBase
      className={className}
      disabled={disabled}
      options={allOptions}
      values={values}
      onChange={(selectedIds) => {
        const newValues = selectedIds
          .map((selected) => allOptions.find((opt) => opt.id === selected.id))
          .filter((v): v is NonNullable<typeof v> => v !== undefined);
        onChange(newValues);
      }}
    />
  );
}
