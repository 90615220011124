import { trpc } from "@/lib/providers/trpc";
import { useTenantId } from "./useTenant";

export function useSystemVariables(enabled?: boolean) {
  const tenantId = useTenantId();
  const variables = trpc.systemVariablesList.useQuery(
    {
      tenantId: tenantId,
    },
    {
      enabled,
    }
  );
  return variables;
}

export function useSystemVariable(id: string): string | null {
  const variables = useSystemVariables();
  return variables.data?.find((v) => v.id === id)?.value ?? null;
}
