import { trpc } from "@/lib/providers/trpc";
import { useParams } from "react-router-dom";

export function useTenantId() {
  const { tenantId } = useParams();
  return tenantId;
}

export function useTenantIdSafe() {
  const { tenantId } = useParams();
  return tenantId as string;
}

export function useTenant() {
  const tenantId = useTenantId();
  return trpc.tenantsById.useQuery({ tenantId: tenantId! }, { enabled: !!tenantId });
}
