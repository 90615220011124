import React, { type ReactNode, Ref, type PropsWithChildren } from "react";
import ReactDOM from "react-dom";
import { cn } from "@/lib/utils";

interface BaseProps {
  className?: string;
  onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const SlateButton = React.forwardRef<
  HTMLSpanElement,
  PropsWithChildren<{
    active?: boolean;
    reversed?: boolean;
    className?: string;
    onMouseDown?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  }>
>(({ className, active, reversed, onMouseDown, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    onMouseDown={onMouseDown}
    className={cn(className, "cursor-pointer", {
      "text-white": reversed && active,
      "text-black": !reversed && active,
      "text-gray-500": reversed && !active,
      "text-gray-300": !reversed && !active,
    })}
  />
));

export const SlateMenu = React.forwardRef<HTMLDivElement, Partial<PropsWithChildren<BaseProps>>>(
  ({ className, onMouseDown, ...props }, ref) => (
    <div
      {...props}
      data-test-id="menu"
      ref={ref}
      onMouseDown={onMouseDown}
      className={cn(className, "inline-block", "ml-15")}
    />
  )
);

export const SlatePortal = ({ children }: { children?: ReactNode }) => {
  return typeof document === "object" ? ReactDOM.createPortal(children, document.body) : null;
};

export const SlateToolbar = React.forwardRef<HTMLDivElement, PropsWithChildren<BaseProps>>(
  ({ className, onMouseDown, ...props }, ref) => (
    <SlateMenu
      {...props}
      ref={ref}
      onMouseDown={onMouseDown}
      className={cn(className, "relative", "px-18", "pb-17", "mx-0", "w-full")}
    />
  )
);
