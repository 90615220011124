import type { RouterOutput } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { AlertCircleIcon } from "lucide-react";
import { TypographySmall } from "../ui/typography";

export type Severity = RouterOutput["tenantDeviationsList"]["rows"][number]["severity"];

interface Props {
  severity: Severity;
}

export function DeviationSeverityIconLabel({ severity }: Props) {
  const { t } = useTranslation();
  switch (severity) {
    case "LOW":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <AlertCircleIcon className="size-5 text-gray-600" />{" "}
          <TypographySmall className="capitalize">{t(severity)}</TypographySmall>
        </span>
      );
    case "MEDIUM":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <AlertCircleIcon className="size-5 text-yellow-600" />{" "}
          <TypographySmall className="capitalize">{t(severity)}</TypographySmall>
        </span>
      );
    case "HIGH":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <AlertCircleIcon className="size-5 text-red-600" />{" "}
          <TypographySmall className="capitalize">{t(severity)}</TypographySmall>
        </span>
      );
  }
}
