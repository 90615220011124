import * as z from "zod";

export const tenantDocumentCategoriesUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1).max(250).optional(),
});

export const tenantDocumentCategoriesUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
