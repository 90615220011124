import React, { useEffect } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useState } from "react";
import { FormBuilderSectionContent } from "./form-builder-section-content";
import type { FormShemaType } from "./form-builder-types";
import { LockIcon } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";
import { FormViewer } from "../form-viewers/form-viewer";
import { cn } from "@/lib/utils";
import {
  FormBaseSidebar,
  FormBaseLayout,
  FormBaseMain,
  FormBaseMainContent,
  FormBaseSidebarSectionButton,
  FormBaseMainHeader,
  FormBaseSidebarSectionAddButton,
} from "./form-base-layout";
import { FormControl, FormField, FormItem, FormMessage } from "../ui/form";
import { Input } from "../ui/input";

type FormBuilderProps = {
  className?: string;
  locked?: boolean;
};

export function FormBuilder(props: FormBuilderProps) {
  const form = useFormContext<FormShemaType>();
  const [tab, setTab] = useState(() => (props.locked ? "preview" : "build"));
  const formContent = form.watch("formContent");
  const { t } = useTranslation();

  // This fixes issue of "No sections" flashing before the form is rendered.
  if (!formContent) return null;

  return (
    <Tabs
      value={tab}
      className={cn("flex h-full w-full flex-1 flex-col overflow-y-hidden", props.className)}
      onValueChange={setTab}
    >
      <TabsList className="mx-auto mb-2 grid w-[400px] flex-none grid-cols-2">
        <TabsTrigger disabled={props.locked} value="build" asChild={props.locked}>
          {props.locked ? (
            <div className="pointer-events-auto">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <div className="flex w-full items-center">
                      <LockIcon className="mr-1 h-4 w-4" />
                      <span>{t("build")}</span>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t("formbuilder_locked")}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          ) : (
            t("build")
          )}
        </TabsTrigger>

        <TabsTrigger value="preview" disabled={!formContent?.sections.length}>
          {t("preview")}
        </TabsTrigger>
      </TabsList>
      <div className="flex flex-1 flex-col overflow-hidden">
        <TabsContent
          value="build"
          className={cn("mt-0 flex flex-col overflow-hidden", tab === "build" && "flex-1")}
        >
          <FormBuilderInternal />
        </TabsContent>
        <TabsContent
          value="preview"
          className={cn("mt-0 flex flex-col overflow-hidden", tab === "preview" && "flex-1")}
        >
          <FormViewer form={formContent} mode="preview-builder" />
        </TabsContent>
      </div>
    </Tabs>
  );
}

function FormBuilderInternal() {
  const form = useFormContext<FormShemaType>();
  const { t } = useTranslation();
  const [activeSectionIdx, setActiveSectionIdx] = useState<null | number>(null);

  const { control } = form;
  const {
    fields: sections,
    append,
    remove,
    move,
  } = useFieldArray({
    control,
    name: "formContent.sections",
  });

  /**
   * Ensure that the active section index is within the bounds of the sections array
   * This can happen when the formContent is reset from the parent component
   */
  useEffect(() => {
    if (activeSectionIdx === null && sections.length > 0) {
      setActiveSectionIdx(0);
    }
    if (activeSectionIdx !== null && activeSectionIdx > sections.length - 1) {
      setActiveSectionIdx(sections.length - 1);
    }
    if (sections.length === 0) {
      setActiveSectionIdx(null);
    }
  }, [activeSectionIdx, sections.length]);

  /**
   * Focus the first element with an error when form is validated
   */

  useEffect(() => {
    const getFirstSectionError = (obj: any): any | undefined => {
      // Base case: if obj has a ref property
      if (obj?.ref) {
        return obj?.ref;
      }
      // Handle arrays
      if (Array.isArray(obj)) {
        return obj.map((item) => getFirstSectionError(item)).filter(Boolean)?.[0];
      }
      // Handle objects
      if (typeof obj === "object" && obj !== null) {
        return Object.values(obj)
          .map((value) => getFirstSectionError(value))
          .filter(Boolean)?.[0];
      }
    };

    const sectionErrors = form.formState.errors.formContent?.sections ?? [];
    const firstError = sectionErrors
      ?.map?.((section, sectionIdx) => ({ ref: getFirstSectionError(section), sectionIdx }))
      .filter((el) => !!el.ref)?.[0];
    if (firstError) {
      setActiveSectionIdx((current) => {
        if (!current) {
          firstError.ref?.focus?.();
        }
        return firstError.sectionIdx;
      });
    }
  }, [form.formState.errors.formContent]);

  const hasFieldSectionsError = !!form.formState.errors.formContent?.sections?.message;

  return (
    <FormBaseLayout>
      <FormBaseSidebar className={cn(hasFieldSectionsError && "border border-destructive")}>
        {sections.map((section, index) => {
          const sectionName = form.getValues("formContent.sections")[index]?.name; // Workaround to retrieve the latest name for the section
          return (
            <FormBaseSidebarSectionButton
              key={section.id}
              onClick={() => setActiveSectionIdx(index)}
              isActive={index === activeSectionIdx}
              move={(from, to) => {
                move(from, to);
                if (from === activeSectionIdx) {
                  setActiveSectionIdx(to);
                }
              }}
              sectionIdx={index}
              onSectionDelete={(idx) => {
                remove(idx);
                if (sections.length === 1) {
                  // If this is the last section, clear the sections array
                  form.setValue("formContent.sections", []);
                }
                form.trigger("formContent.sections");
                setActiveSectionIdx((c) => (c !== null ? Math.max(c - 1, 0) : null));
              }}
            >
              {sectionName}
            </FormBaseSidebarSectionButton>
          );
        })}

        <FormBaseSidebarSectionAddButton
          onClick={() => {
            const newSection: FormShemaType["formContent"]["sections"][number] = {
              name: `${t("section")} ${sections.length + 1}`,
              elements: [],
            };

            if (sections.length === 0) {
              // If there are no sections, set the sections array directly
              form.setValue("formContent.sections", [newSection]);
            } else {
              // Otherwise, append to the existing sections
              append(newSection);
            }
            form.trigger("formContent.sections");
            setActiveSectionIdx(sections.length);
          }}
        />

        <FormField
          control={form.control}
          name="formContent.sections"
          render={() => (
            <FormItem className="flex h-full">
              <FormMessage />
            </FormItem>
          )}
        />
      </FormBaseSidebar>

      <FormBaseMain>
        <FormBaseMainHeader>
          {sections.length > 0 && activeSectionIdx !== null && (
            <FormField
              key={activeSectionIdx}
              control={control}
              name={`formContent.sections.${activeSectionIdx}.name`}
              render={({ field }) => (
                <FormItem>
                  <FormControl className="flex flex-col">
                    <Input placeholder={t("section_name")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </FormBaseMainHeader>
        <FormBaseMainContent>
          {sections.length > 0 && activeSectionIdx !== null && (
            <FormBuilderSectionContent
              key={activeSectionIdx}
              sectionIndex={activeSectionIdx}
              control={control}
            />
          )}
        </FormBaseMainContent>
      </FormBaseMain>
    </FormBaseLayout>
  );
}
