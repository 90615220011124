import { useUser } from "@/hooks/useUser";
import { Avatar, AvatarFallback } from "../ui/avatar";
import {
  Menubar,
  MenubarContent,
  MenubarMenu,
  MenubarSeparator,
  MenubarTrigger,
} from "../ui/menubar";
import {
  LanguageSettingsMenu,
  LogoutButton,
  ProfileButton,
  ThemeSettingsMenu,
} from "./menu-settings-primitives";
import { type VariantProps, cva } from "class-variance-authority";
import { cn } from "@/lib/utils";

const userMenuVariants = cva("flex size-9 items-center justify-center rounded-full", {
  variants: {
    variant: {
      light: "",
      dark: "bg-black text-background",
    },
  },
  defaultVariants: {
    variant: "light",
  },
});

export interface UserMenuProps extends VariantProps<typeof userMenuVariants> {
  className?: string;
}

export function UserMenu({ variant, className }: UserMenuProps) {
  const user = useUser();
  return (
    <Menubar className={cn(userMenuVariants({ variant }), className)}>
      <MenubarMenu>
        <MenubarTrigger asChild>
          <Avatar
            className={cn(
              "flex size-8 cursor-pointer items-center justify-center rounded-full",
              variant === "dark" &&
                "bg-primary focus:bg-primary focus:text-background data-[state=open]:bg-primary data-[state=open]:text-background dark:bg-primary dark:text-black"
            )}
          >
            {/* <AvatarImage src="https://github.com/shadcn.png" alt="@shadcn" /> */}
            <AvatarFallback
              className="bg-transparent"
              style={
                {} // workaround: radix is applying a color: white by default, we're overriding that here
              }
            >
              {user.profile?.initials}
            </AvatarFallback>
          </Avatar>
        </MenubarTrigger>
        <MenubarContent>
          <ProfileButton />
          <MenubarSeparator />
          <LanguageSettingsMenu />
          <ThemeSettingsMenu />

          <MenubarSeparator />
          <LogoutButton />
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  );
}
