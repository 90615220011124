import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

import { systemVariablesInsertInputSchema } from "@timp/server/src/schemas/system-variables-insert.schema";

import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "@/components/buttons/link-button";
import { Textarea } from "@/components/ui/textarea";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";

export function SysAdminVariablesCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, isLoading } = trpc.systemVariablesInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const form = useForm<z.infer<typeof systemVariablesInsertInputSchema>>({
    resolver: zodResolver(systemVariablesInsertInputSchema),
  });

  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle backLink="../">{t("variables_title")}</PageTitle>
      </PageToolbar>
      <PageContent scroll>
        <Form {...form}>
          <form onSubmit={form.handleSubmit((data) => mutate(data))} className="space-y-8">
            <FormField
              control={form.control}
              name="id"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("variable_name")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("variable_name_example")} {...field} />
                  </FormControl>
                  <FormDescription>
                    {t("variable_name_description", { example: t("variable_name_example") })}
                  </FormDescription>

                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="value"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormLabel required>{t("value")}</FormLabel>
                  <FormControl>
                    <Textarea placeholder="" {...field} rows={10} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={isLoading} type="submit">
                {t("create")}
              </Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
