import { LinkButton } from "@/components/buttons/link-button";
import { CaseStatusIconLabel, type CaseStatus } from "@/components/labels/case-status-icon-label";
import { StatusIconLabel } from "@/components/labels/status-icon-label";
import { Page, PageTitle, PageToolbar, PageActions, PageContent } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { Badge } from "@/components/ui/badge";
import { DataTableLink } from "@/components/ui/data-table-link";
import { DateTooltip, DistanceToNowDate, RelativeDate } from "@/components/ui/relative-date";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useSupplier } from "@/hooks/useSupplier";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { isPast } from "date-fns";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export function SupplierCasesPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const { supplierId } = useParams();
  const supplier = useSupplier();

  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("supplier_cases")}</PageTitle>
        <PageActions>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger type="button">
                <LinkButton
                  disabled={supplier.data?.isArchived}
                  to={`../../../cases/create?supplierId=${supplierId}`}
                >
                  {t("create_case")}
                </LinkButton>
              </TooltipTrigger>
              {supplier.data?.isArchived && (
                <TooltipContent>{t("disallowed_due_to_archived_supplier")}</TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </PageActions>
      </PageToolbar>

      <PageContent>
        <ServerDataTable
          columnsStorageKey="supplier-cases-page"
          query={trpc.tenantCasesList}
          params={{
            tenantId,
            supplierId,
          }}
          searchColumns={["searchTags", "formTemplateName", "title"]}
          columns={[
            {
              id: "formTemplateName",
              title: t("form_name"),
              filter: true,
              enableSorting: false,
              render(row) {
                return (
                  <DataTableLink to={`../../../cases/${row.id}`}>
                    {row.formTemplateName}
                  </DataTableLink>
                );
              },
            },
            {
              id: "title",
              title: t("title"),
              enableSorting: true,
            },
            {
              id: "status",
              title: "Status",
              filter: true,
              enableSorting: false,
              render: (row) => <CaseStatusIconLabel status={row.status} />,
              valueToLabel: (status: CaseStatus) => t(`${status}`),
            },
            {
              id: "deviationsCount",
              title: t("deviations"),
              render: (row) => {
                if (row.deviationsCount) {
                  return (
                    <Badge variant="secondary">
                      {t("deviations_count", { count: row.deviationsCount })}
                    </Badge>
                  );
                }
                return null;
              },
            },
            {
              id: "processedAt",
              title: t("processed_at"),
              render: (row) => {
                if (!row.processedAt) return null;
                return <RelativeDate date={row.processedAt} />;
              },
              timeFilter: true,
              enableSorting: true,
            },
            {
              id: "responseDeadline",
              title: t("response_deadline"),
              render: (row) => {
                if (row.status === "PROCESSED" || row.status === "ANSWERED") return null;
                if (isPast(new Date(row.responseDeadline))) {
                  return (
                    <StatusIconLabel
                      variant="error"
                      label={<DateTooltip date={row.responseDeadline} label={t("expired")} />}
                    />
                  );
                }
                return (
                  <StatusIconLabel
                    variant="waiting"
                    label={<DistanceToNowDate date={row.responseDeadline} />}
                  />
                );
              },
              enableSorting: true,
            },

            {
              id: "lastActivityAt",
              title: t("last_activity_at"),
              render: (row) => <RelativeDate date={row.lastActivityAt} />,
              enableSorting: true,
            },
            {
              id: "createdByName",
              title: t("created_by"),
              filter: true,
              enableSorting: false,
            },
            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
              enableSorting: true,
            },
          ]}
          defaultSortColumn="createdAt"
          defaultSortOrder="desc"
          paginationPageSize={50}
        />
      </PageContent>
    </Page>
  );
}
