import { TextChartCasesReadyForProcessing } from "./text-chart-cases-ready-for-processing";
import { TextChartInspectionsReadyForProcessing } from "./text-chart-inspections-ready-for-processing";
import { TextChartCasesAnsweredWithinDeadline } from "./text-chart-cases-answered-within-deadline";
import { TextChartCasesOpenedWithinDeadline } from "./text-chart-cases-opened-within-deadline";
import { PieChartSuppliersStatusOverview } from "./pie-chart-suppliers-status-overview";
import { BarChartCasesDeviation12Months } from "./bar-chart-cases-deviation-12-months";
import { BarChartInspectionsDeviation12Months } from "./bar-chart-inspections-deviation-12-months";
import { CalendarChartInspections } from "./calendar-chart-inspections";
import {
  AlertCircleIcon,
  CalendarCheckIcon,
  ClockIcon,
  FileSearch2Icon,
  FileWarningIcon,
  MailQuestionIcon,
  MailXIcon,
  PieChartIcon,
  ShieldQuestionIcon,
} from "lucide-react";
import { CalendarChartCases } from "./calendar-chart-cases";
import { TextChartCasesNoResponse } from "./text-chart-cases-no-response";
import { TextChartSupplierStatus } from "./text-chart-supplier-status";
import { useTranslation } from "react-i18next";

interface ChartOption {
  id: string;
  component: React.ComponentType;
  icon: React.ComponentType;
  title: string;
  description: string;
}
export function useChartsList() {
  const { t } = useTranslation();
  return [
    {
      id: "text_chart_supplier_status",
      title: t("text_chart_supplier_status"),
      description: t("text_chart_supplier_status_description"),
      component: TextChartSupplierStatus,
      icon: ShieldQuestionIcon,
    },
    {
      id: "text_chart_inspections_ready_for_processing",
      title: t("text_chart_inspections_ready_for_processing"),
      description: t("text_chart_inspections_ready_for_processing_description"),
      component: TextChartInspectionsReadyForProcessing,
      icon: FileSearch2Icon,
    },
    {
      id: "text_chart_cases_ready_for_processing",
      title: t("text_chart_cases_ready_for_processing"),
      description: t("text_chart_cases_ready_for_processing_description"),
      component: TextChartCasesReadyForProcessing,
      icon: FileWarningIcon,
    },
    {
      id: "text_chart_cases_no_response",
      title: t("text_chart_cases_no_response"),
      description: t("text_chart_cases_no_response_description"),
      component: TextChartCasesNoResponse,
      icon: MailXIcon,
    },
    {
      id: "text_chart_cases_opened_within_deadline",
      title: t("text_chart_cases_opened_within_deadline"),
      description: t("text_chart_cases_opened_within_deadline_description"),
      component: TextChartCasesOpenedWithinDeadline,
      icon: MailQuestionIcon,
    },
    {
      id: "text_chart_cases_answered_within_deadline",
      title: t("text_chart_cases_answered_within_deadline"),
      description: t("text_chart_cases_answered_within_deadline_description"),
      component: TextChartCasesAnsweredWithinDeadline,
      icon: CalendarCheckIcon,
    },
    {
      id: "pie_chart_suppliers_status_overview",
      title: t("pie_chart_suppliers_status_overview"),
      description: t("pie_chart_suppliers_status_overview_description"),
      component: PieChartSuppliersStatusOverview,
      icon: PieChartIcon,
    },
    {
      id: "bar_chart_inspections_12_months",
      title: t("bar_chart_inspections_12_months"),
      description: t("bar_chart_inspections_12_months_description"),
      component: BarChartInspectionsDeviation12Months,
      icon: AlertCircleIcon,
    },
    {
      id: "bar_chart_cases_12_months",
      title: t("bar_chart_cases_12_months"),
      description: t("bar_chart_cases_12_months_description"),
      component: BarChartCasesDeviation12Months,
      icon: AlertCircleIcon,
    },
    {
      id: "calendar_chart_inspections",
      title: t("calendar_chart_inspections"),
      description: t("calendar_chart_inspections_description"),
      component: CalendarChartInspections,
      icon: ClockIcon,
    },
    {
      id: "calendar_chart_cases",
      title: t("calendar_chart_cases"),
      description: t("calendar_chart_cases_description"),
      component: CalendarChartCases,
      icon: ClockIcon,
    },
  ] as const satisfies readonly ChartOption[];
}
