import type { RouterOutput } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { CheckCircle2Icon, ClockIcon, InfoIcon } from "lucide-react";

export type CaseStatus = RouterOutput["tenantCasesList"]["rows"][number]["status"];

interface Props {
  status: CaseStatus;
}

export function CaseStatusIconLabel({ status }: Props) {
  const { t } = useTranslation();
  switch (status) {
    case "ANSWERED":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <InfoIcon strokeWidth={1.5} className="size-[18px] text-blue-700" />
          {t(`${status}`)}
        </span>
      );
    case "OPENED":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <ClockIcon strokeWidth={1.5} className="size-[18px] text-blue-700" />
          {t(`${status}`)}
        </span>
      );
    case "PROCESSED":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <CheckCircle2Icon strokeWidth={1.5} className="size-[18px] text-green-700" />
          {t(`${status}`)}
        </span>
      );
    case "SENT":
      return (
        <span className="flex flex-row items-center gap-x-1.5">
          <ClockIcon strokeWidth={1.5} className="size-[18px] text-gray-700" />
          {t(`${status}`)}
        </span>
      );
  }
}
