import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { SelectManageButton } from "./select-manage-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { useRole } from "@/hooks/useUser";
import { SelectMultipleBase, type ValueProps, type ActiveFilter } from "./select-multiple";
import { useState, useMemo, useEffect } from "react";

export function SelectMultipleSuppliers({ values, onChange, disabled, className }: ValueProps) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const role = useRole(tenantId);
  const canManage = role === "ADMIN";
  const [hideArchived, setHideArchived] = useState(true);
  const [activeFilters, setActiveFilters] = useState<ActiveFilter[]>([]);

  const { data, isLoading, error } = trpc.tenantSuppliersList.useQuery({
    tenantId,
    hideArchived,
  });

  // Create unfiltered options (all non-archived items)
  const options = useMemo(() => {
    if (!data?.rows) return [];
    return data.rows
      .filter((supplier) => !supplier.isArchived)
      .map((supplier) => ({
        id: supplier.id,
        name: supplier.name,
        disabled: supplier.isArchived,
      }));
  }, [data?.rows]);

  // Extract unique groups and departments from the suppliers data
  const uniqueGroups = useMemo(
    () => Array.from(new Set(data?.rows.flatMap((supplier) => supplier.groups) ?? [])).sort(),
    [data?.rows]
  );

  const uniqueDepartments = useMemo(
    () => Array.from(new Set(data?.rows.flatMap((supplier) => supplier.departments) ?? [])).sort(),
    [data?.rows]
  );

  const filters = useMemo(
    () => [
      {
        id: "status",
        label: t("status"),
        options: [
          { value: "all", label: t("all") },
          { value: "active", label: t("active") },
          { value: "archived", label: t("archived") },
        ],
        defaultValue: "active",
      },
      {
        id: "group",
        label: t("group"),
        options: [
          { value: "all", label: t("all") },
          ...uniqueGroups.map((group) => ({
            value: group,
            label: group,
          })),
        ],
        defaultValue: "all",
      },
      {
        id: "department",
        label: t("department"),
        options: [
          { value: "all", label: t("all") },
          ...uniqueDepartments.map((dept) => ({
            value: dept,
            label: dept,
          })),
        ],
        defaultValue: "all",
      },
    ],
    [t, uniqueGroups, uniqueDepartments]
  );

  // Initialize filters with default values
  useEffect(() => {
    if (filters.length > 0 && activeFilters.length === 0) {
      const initialFilters = filters.map((f) => ({
        id: f.id,
        value: f.defaultValue || f.options[0].value,
      }));
      setActiveFilters(initialFilters);
    }
  }, [filters, activeFilters.length]);

  // Apply filters to options
  const filteredOptions = useMemo(() => {
    if (!data?.rows) return [];

    return data.rows
      .filter((supplier) => {
        const statusFilter = activeFilters.find((f) => f.id === "status")?.value ?? "active";
        const groupFilter = activeFilters.find((f) => f.id === "group")?.value;
        const departmentFilter = activeFilters.find((f) => f.id === "department")?.value;

        // Status filter
        if (statusFilter === "active" && supplier.isArchived) return false;
        if (statusFilter === "archived" && !supplier.isArchived) return false;

        // Group filter
        if (groupFilter && groupFilter !== "all" && !supplier.groups.includes(groupFilter))
          return false;

        // Department filter
        if (
          departmentFilter &&
          departmentFilter !== "all" &&
          !supplier.departments.includes(departmentFilter)
        )
          return false;

        return true;
      })
      .map((supplier) => ({
        id: supplier.id,
        name: supplier.name,
        disabled: supplier.isArchived,
      }));
  }, [data?.rows, activeFilters]);

  const handleFiltersChange = (newFilters: ActiveFilter[]) => {
    setActiveFilters(newFilters);
    const statusFilter = newFilters.find((f) => f.id === "status")?.value;
    setHideArchived(statusFilter === "active");
  };

  const footer = canManage ? (
    <SelectManageButton to={`/tenants/${tenantId}/suppliers`}>
      {t("manage_suppliers")}
    </SelectManageButton>
  ) : null;

  return (
    <SelectMultipleBase
      className={className}
      disabled={disabled}
      options={options}
      filteredOptions={filteredOptions}
      isLoading={isLoading}
      error={error}
      values={values}
      onChange={onChange}
      footer={footer}
      filters={filters}
      activeFilters={activeFilters}
      onFiltersChange={handleFiltersChange}
    />
  );
}
