import { useTranslation } from "react-i18next";
import type { LanguageType } from "@timp/server/generated/db-types";

export function useLanguageTitle() {
  const { t } = useTranslation();
  const LANGUAGE_MAP: Record<LanguageType, string> = {
    no: t("language_norwegian"),
    en: t("language_english"),
  };

  return (language: LanguageType) => LANGUAGE_MAP[language];
}
interface Props {
  language: LanguageType;
}
export function LanguageTitleLabel({ language }: Props) {
  const getLanguageTitle = useLanguageTitle();
  return getLanguageTitle(language);
}
