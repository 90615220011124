import { LinkButton } from "@/components/buttons/link-button";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { trpc } from "@/lib/providers/trpc";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { tenantSuppliersContactInsertInputSchema } from "@timp/server/src/schemas/tenant-supplier-contact-insert.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import type { z } from "zod";
import { useSupplierIdSafe } from "@/hooks/useSupplier";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { Spinner } from "@/components/icons/spinner";
import { SelectLanguage } from "@/components/selects/select-language";

export function SupplierContactsCreatePage() {
  const supplierId = useSupplierIdSafe();
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const supplier = trpc.tenantSuppliersById.useQuery({ tenantId, id: supplierId });

  const createMutation = trpc.tenantSupplierContactsInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const emailValidation = trpc.validateEmail.useMutation({
    onSuccess(validationResult) {
      if (validationResult.suggestedEmail) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check_suggestion", {
            email: validationResult.suggestedEmail,
          }),
        });
      } else if (!validationResult.isValid) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check"),
        });
      } else {
        form.clearErrors("email");
      }
    },
  });

  const form = useForm<z.infer<typeof tenantSuppliersContactInsertInputSchema>>({
    resolver: zodResolver(tenantSuppliersContactInsertInputSchema),
    defaultValues: {
      supplierId,
      language: supplier.data?.language,
    },
  });

  return (
    <Page size="container" isLoading={supplier.isLoading}>
      <PageToolbar>
        <PageTitle backLink>{t("create_supplier_contact")}</PageTitle>
      </PageToolbar>
      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) => createMutation.mutate({ ...values, tenantId }))}
            className="flex flex-col gap-y-8"
          >
            <FormField
              control={form.control}
              name="fullName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("full_name")}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("email")}</FormLabel>
                  <div className="relative">
                    <FormControl>
                      <Input
                        placeholder="olanordmann@example.no"
                        {...field}
                        onBlur={() => {
                          form.trigger("email").then((isValid) => {
                            if (isValid) {
                              emailValidation.mutate({ email: field.value });
                            }
                          });
                        }}
                      />
                    </FormControl>
                    {emailValidation.isLoading && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  {emailValidation.data?.suggestedEmail ? (
                    <button
                      type="button"
                      className="inline [&>p]:hover:underline"
                      onClick={() => {
                        field.onChange(emailValidation.data.suggestedEmail);
                        emailValidation.reset();
                        form.clearErrors("email");
                      }}
                    >
                      <FormMessage />
                    </button>
                  ) : (
                    <FormMessage />
                  )}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("language")}</FormLabel>
                  <FormControl>
                    <SelectLanguage value={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("phone")}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("role")}</FormLabel>
                  <FormControl>
                    <Input {...field} value={field.value ?? ""} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={createMutation.isLoading} type="submit">
                {t("create_supplier_contact")}
              </Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
