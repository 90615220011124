import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { tenantUsersInsertInputSchema } from "@timp/server/src/schemas/tenant-users-insert.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate, useParams } from "react-router-dom";
import { LinkButton } from "@/components/buttons/link-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";

export function TenantSettingsUsersEditPage() {
  const { id } = useParams();

  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, error, isLoading } = trpc.tenantUsersById.useQuery({ userId: id!, tenantId });

  const updateTenantUser = trpc.tenantUsersUpdateById.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const form = useForm<z.infer<typeof tenantUsersInsertInputSchema>>({
    resolver: zodResolver(tenantUsersInsertInputSchema),
    values: data,
  });
  return (
    <Page size="container" isLoading={isLoading} error={error}>
      <PageToolbar>
        <PageTitle backLink>{t("edit_user")}</PageTitle>
      </PageToolbar>

      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) =>
              updateTenantUser.mutate({ ...data, tenantId, userId: id! })
            )}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("email")}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder="olanordmann@example.no"
                      disabled
                      name={field.name}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("role")}</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="ADMIN">{t("ADMIN")}</SelectItem>
                      <SelectItem value="MANAGER">{t("MANAGER")}</SelectItem>
                      <SelectItem value="INSPECTOR">{t("INSPECTOR")}</SelectItem>
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={updateTenantUser.isLoading}>{t("edit_user")}</Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
