import { DataTable } from "@/components/tables/data-table";
import {
  MutationActionsMenu,
  MutationDeleteAction,
  MutationEditAction,
} from "@/components/dropdown-menus/mutation-actions-menu";
import { RelativeDate } from "@/components/ui/relative-date";
import { useUser } from "@/hooks/useUser";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { DataTableLink } from "@/components/ui/data-table-link";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { Badge } from "@/components/ui/badge";
import { LinkButton } from "@/components/buttons/link-button";
import { LanguageTitleLabel, useLanguageTitle } from "@/components/labels/language-title-label";

export function SysAdminUsersPage() {
  const user = useUser();
  const getLanguageTitle = useLanguageTitle();
  const { t } = useTranslation();
  const {
    data = [],
    isLoading,
    error,
  } = trpc.sysadminUsersList.useQuery(undefined, {
    select: (data) =>
      data.map((user) => ({
        ...user,
        fullName:
          user.firstName !== null && user.lastName !== null
            ? `${user.firstName} ${user.lastName}`
            : t("unknown_name"),
      })),
  });

  const { mutate: deleteUser } = trpc.sysadminUsersDeleteById.useMutation();

  return (
    <Page isLoading={isLoading} error={error}>
      <PageToolbar>
        <PageTitle>{t("users_title")}</PageTitle>
        <PageActions>
          <LinkButton to="./create">{t("create_user")}</LinkButton>
        </PageActions>
      </PageToolbar>

      <PageContent>
        <DataTable
          columnsStorageKey="sys-admin-users-page"
          isLoading={isLoading}
          error={error}
          data={data}
          columns={[
            {
              id: "fullName",
              title: t("name"),
              render: (row) => {
                return <DataTableLink to={`./${row.userId}/edit`}>{row.fullName}</DataTableLink>;
              },
            },
            {
              id: "email",
              title: t("email"),
              render: (row) => (
                <a href={`mailto:${row.email}`} className="underline">
                  {row.email}
                </a>
              ),
            },
            {
              id: "language",
              title: t("language"),
              render: (row) =>
                row.language ? <LanguageTitleLabel language={row.language} /> : null,
              filter: true,
              valueToLabel: (value) => getLanguageTitle(value),
            },
            {
              id: "lastActiveAt",
              title: t("last_active_at"),
              render: (row) => {
                if (!row.lastActiveAt) return t("never");
                return <RelativeDate date={row.lastActiveAt} />;
              },
            },
            {
              id: "isSysAdmin",
              title: t("role"),
              filter: true,
              render: ({ isSysAdmin }) => (
                <Badge variant="outline">
                  {isSysAdmin ? t("is_sys_admin") : t("is_regular_user")}
                </Badge>
              ),
              valueToLabel: (isSysAdmin: boolean) =>
                isSysAdmin ? t("is_sys_admin") : t("is_regular_user"),
            },
            {
              id: "userCreatedAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.userCreatedAt} />,
            },
            {
              id: "userId",
              title: t("actions"),
              actions: true,
              enableSorting: false,
              size: 40,
              render: (row) => {
                const canDelete = row.userId !== user.id;
                return (
                  <MutationActionsMenu data-testid={row.email}>
                    <MutationEditAction to={`./${row.userId}/edit`} />
                    {canDelete && (
                      <MutationDeleteAction
                        name={row.email}
                        onConfirmDelete={() => deleteUser(row)}
                      />
                    )}
                  </MutationActionsMenu>
                );
              },
            },
          ]}
          searchColumn="fullName"
          defaultSortColumn="fullName"
          defaultSortOrder="asc"
        />
      </PageContent>
    </Page>
  );
}
