import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { BellIcon } from "lucide-react";
import { type RouterOutput, trpc } from "@/lib/providers/trpc";
import { RelativeDate } from "../ui/relative-date";
import { TypographyH6, TypographyMuted } from "../ui/typography";
import { useIntersectionObserver } from "usehooks-ts";
import { Separator } from "../ui/separator";
import { cn } from "@/lib/utils";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../ui/dialog";
import { RichTextEditor } from "../rich-text-editor/rich-text-editor";
import { useState } from "react";
import * as Portal from "@radix-ui/react-portal";
interface NotificationProps {
  notification: RouterOutput["usersNotifications"][number];
  setActiveNotificationId: (id: string | null) => void;
}

function NotificationItem({ notification, setActiveNotificationId }: NotificationProps) {
  const acknowledgeMutation = trpc.usersNotificationsAcknowledge.useMutation();

  const { ref } = useIntersectionObserver({
    threshold: 0.25,
    freezeOnceVisible: true,
    onChange(isIntersecting) {
      if (notification.isAcknowledged || acknowledgeMutation.isSuccess) {
        return null;
      }

      if (isIntersecting) {
        acknowledgeMutation.mutate({ id: notification.id });
      }
    },
  });

  return (
    <button
      type="button"
      className={cn("group relative flex w-full cursor-pointer flex-col p-4")}
      ref={ref}
      onClick={() => {
        if (notification.type === "TEXT") {
          setActiveNotificationId(notification.id);
        }
      }}
    >
      <div className="flex w-full flex-col">
        <div className="flex items-start justify-between gap-4">
          <TypographyH6
            className={cn("text-sm font-medium group-hover:underline text-left flex-1")}
            data-testid="notification-item-title"
          >
            {notification.title}
          </TypographyH6>
          <TypographyMuted className="shrink-0 whitespace-nowrap text-right">
            <RelativeDate date={notification.createdAt} />
          </TypographyMuted>
        </div>
        <TypographyMuted
          className="mt-2 text-start text-sm"
          data-testid="notification-item-descsription"
        >
          {notification.summary}
        </TypographyMuted>
      </div>
      {/* Allow users to click the entire card zone */}
      {notification.type === "LINK" && !!notification.link && (
        // biome-ignore lint/a11y/useAnchorContent: <explanation>
        <a
          className="absolute inset-0"
          href={notification.link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={notification.title}
        />
      )}
    </button>
  );
}

export function NotificationMenu() {
  const { data: notifications = [] } = trpc.usersNotifications.useQuery();

  const hasNewNotifications = notifications.some((notification) => !notification.isAcknowledged);

  const [activeNotificationId, setActiveNotificationId] = useState<string | null>(null);
  const notification = notifications.find(
    (notification) => notification.id === activeNotificationId
  );

  if (notifications.length === 0) {
    return null;
  }
  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <button type="button" className="relative p-2" data-testid="notification-menu-link">
            <BellIcon className="size-5 text-white dark:text-primary" />
            {hasNewNotifications && (
              <div
                className="absolute right-0.5 top-0.5 h-2 w-2 rounded-full bg-blue-500"
                data-testid="notification-menu-new"
              />
            )}
          </button>
        </PopoverTrigger>

        <PopoverContent
          data-testid="notification-menu-content"
          className="mr-4 w-auto overflow-auto p-0"
          align="start"
          onOpenAutoFocus={(ev) => {
            ev.preventDefault();
          }}
        >
          <div className="flex max-h-[250px] max-w-md flex-col overflow-auto">
            {notifications.map((notification, idx) => (
              <div key={notification.id}>
                <NotificationItem
                  notification={notification}
                  setActiveNotificationId={setActiveNotificationId}
                />
                {/* Add separator between notifications */}
                {idx < notifications.length - 1 && (
                  <div className="px-2">
                    <Separator />
                  </div>
                )}
              </div>
            ))}
          </div>
        </PopoverContent>
      </Popover>

      <Portal.Root>
        <Dialog open={!!notification} onOpenChange={() => setActiveNotificationId(null)}>
          <DialogContent className="max-h-screen overflow-hidden">
            <DialogHeader>
              <DialogTitle>{notification?.title}</DialogTitle>
            </DialogHeader>
            <div className="overflow-auto">
              {notification?.textContent && (
                <RichTextEditor
                  key={notification.id}
                  readOnly
                  initialValue={notification.textContent as unknown as object[]}
                />
              )}
            </div>
          </DialogContent>
        </Dialog>
      </Portal.Root>
    </>
  );
}
