import { type UseFormReturn, useFieldArray } from "react-hook-form";
import type { FormViewerModes, FormViewerShemaType } from "./form-viewer-types";
import {
  FormViewerTextQuestionElement,
  FormViewerInformationElement,
  FormViewerYesNoElement,
  FormViewerMultipleChoiceElement,
  FormViewerApprovalQuestionElement,
  FormViewerAttachmentElement,
  FormViewerFileUploadElement,
  FormViewerSignatureElement,
} from "./form-viewer-elements";

interface Props {
  sectionIndex: number;
  control: UseFormReturn<FormViewerShemaType>["control"];
  mode: FormViewerModes;
}
export function FormViewerSectionContent({ sectionIndex, control, mode }: Props) {
  const { fields: elements } = useFieldArray({
    control,
    name: `sections.${sectionIndex}.elements`,
  });

  return (
    <div className="flex w-full flex-grow flex-col gap-y-6">
      {elements.map((element, index) => {
        return (
          <div key={element.id}>
            {element.type === "textQuestion" && (
              <FormViewerTextQuestionElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
              />
            )}

            {element.type === "information" && (
              <FormViewerInformationElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
              />
            )}

            {element.type === "yesNoQuestion" && (
              <FormViewerYesNoElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
              />
            )}

            {element.type === "multipleChoiceQuestion" && (
              <FormViewerMultipleChoiceElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
              />
            )}

            {element.type === "approvalQuestion" && (
              <FormViewerApprovalQuestionElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
              />
            )}

            {element.type === "attachment" && (
              <FormViewerAttachmentElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
              />
            )}

            {element.type === "fileUpload" && (
              <FormViewerFileUploadElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
                uploadType="file"
                acceptFileTypes={[
                  "image/*",
                  "text/*",
                  "audio/*",
                  "video/*",
                  ".pdf",
                  ".doc",
                  ".docx",
                  ".xls",
                  ".xlsx",
                  ".ppt",
                  ".pptx",
                  ".zip",
                  ".rar",
                  ".7z",
                  ".csv",
                  ".txt",
                  ".json",
                  ".xml",
                  ".svg",
                  ".webp",
                  ".msg",
                ]}
              />
            )}
            {element.type === "imageUpload" && (
              <FormViewerFileUploadElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
                uploadType="image"
                acceptFileTypes={["image/*"]}
              />
            )}

            {element.type === "signature" && (
              <FormViewerSignatureElement
                control={control}
                sectionIdx={sectionIndex}
                elementIdx={index}
                mode={mode}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
