import { LinkButton } from "@/components/buttons/link-button";
import {
  MutationActionsMenu,
  MutationDeleteAction,
} from "@/components/dropdown-menus/mutation-actions-menu";
import { DeviationSeverityIconLabel } from "@/components/labels/deviation-severity-icon-label";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { ServerDataTable } from "@/components/tables/server-data-table";
import { DataTableLink } from "@/components/ui/data-table-link";
import { RelativeDate } from "@/components/ui/relative-date";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { useSupplier } from "@/hooks/useSupplier";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export function SupplierDeviationsPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const { supplierId } = useParams();
  const supplier = useSupplier();

  const { mutate: deleteDeviation } = trpc.tenantDeviationsDeleteById.useMutation();

  return (
    <Page>
      <PageToolbar>
        <PageTitle>{t("supplier_deviations")}</PageTitle>

        <PageActions>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger type="button">
                <LinkButton
                  disabled={supplier.data?.isArchived}
                  to={`../../../deviations/create?supplierId=${supplierId}`}
                >
                  {t("create_deviation")}
                </LinkButton>
              </TooltipTrigger>
              {supplier.data?.isArchived && (
                <TooltipContent>{t("disallowed_due_to_archived_supplier")}</TooltipContent>
              )}
            </Tooltip>
          </TooltipProvider>
        </PageActions>
      </PageToolbar>

      <PageContent>
        <ServerDataTable
          columnsStorageKey="supplier-deviations-page"
          query={trpc.tenantDeviationsList}
          params={{
            tenantId,
            supplierId,
          }}
          columns={[
            {
              id: "description",
              title: t("description"),
              enableSorting: false,
              render(row) {
                return (
                  <DataTableLink className="line-clamp-1" to={`../../../deviations/${row.id}`}>
                    {row.description}
                  </DataTableLink>
                );
              },
            },
            {
              id: "severity",
              title: t("severity"),
              filter: true,
              enableSorting: false,
              render(row) {
                return <DeviationSeverityIconLabel severity={row.severity} />;
              },
              valueToLabel: (type) => t(type),
            },
            {
              id: "type",
              title: t("type"),
              filter: true,
              enableSorting: false,
              render(row) {
                return t(row.type);
              },
              valueToLabel: (type) => t(type),
            },
            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
            },
            {
              id: "id",
              title: t("actions"),
              actions: true,
              enableSorting: false,
              size: 40,
              render: (row) => (
                <MutationActionsMenu>
                  <MutationDeleteAction
                    onConfirmDelete={() => deleteDeviation({ id: row.id, tenantId })}
                  />
                </MutationActionsMenu>
              ),
            },
          ]}
          searchColumns={["description"]}
          defaultSortColumn="createdAt"
          paginationPageSize={50}
          defaultSortOrder="desc"
        />
      </PageContent>
    </Page>
  );
}
