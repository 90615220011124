/* eslint-disable no-restricted-imports */
import { forwardRef } from "react";
import {
  Link as ReactRouterLink,
  type LinkProps,
  NavLink as ReactRouterNavLink,
  type NavLinkProps,
  useLocation,
} from "react-router-dom";

// This component wraps the original Link component from react-router-dom because we want to keep track of the current page location when navigating to a new page.
export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const location = useLocation();
  return (
    <ReactRouterLink {...props} state={{ from: location.pathname, ...props.state }} ref={ref} />
  );
});
// This component wraps the original NavLink component from react-router-dom because we want to keep track of the current page location when navigating to a new page.
export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const location = useLocation();
  return (
    <ReactRouterNavLink {...props} state={{ from: location.pathname, ...props.state }} ref={ref} />
  );
});
