import { zodStringOrNull } from "../utils/zod-extensions";
import * as z from "zod";

export const tenantSuppliersContactUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  fullName: z.string().min(1).max(250).trim(),
  email: z.string().email().toLowerCase().trim(),
  phone: zodStringOrNull({ max: 50 }),
  role: zodStringOrNull({ max: 250 }),
});

export const tenantSuppliersContactUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
