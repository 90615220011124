import * as z from "zod";

export const sysadminNotificationsUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  title: z.string().min(1).trim().optional(),
  summary: z.string().min(1).max(300).trim(),
  content: z.discriminatedUnion("type", [
    z.object({
      type: z.literal("LINK"),
      link: z.string().url().trim(),
    }),
    z.object({
      type: z.literal("TEXT"),
      textContent: z.array(z.any()).min(1),
    }),
  ]),
  expiresAt: z.string().datetime().optional().nullable(),
});

export const sysadminNotificationsUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
