import { ChartContentLayout } from "./chart-layout";
import { ResponsiveBar } from "@nivo/bar";
import { useTranslation } from "react-i18next";
import { useDateFns } from "@/hooks/useDateFns";
import { useNavigate } from "react-router-dom";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { useSupplierId } from "@/hooks/useSupplier";
import { TypographySmall } from "../ui/typography";
import { parseServerTableFiltersIntoURLParams } from "../tables/table-utils";
import { endOfMonth, startOfMonth } from "date-fns";

interface BarTooltip<T extends object> {
  id: string | number;
  value: number;
  formattedValue: string;
  index: number;
  indexValue: string | number;
  // datum associated to the current index (raw data)
  data: T;
}

export function BarChartInspectionsDeviation12Months() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenantId = useTenantIdSafe();
  const supplierId = useSupplierId();
  const { format } = useDateFns();
  const supplierQuery = trpc.tenantSuppliersById.useQuery(
    { id: supplierId!, tenantId },
    {
      enabled: !!supplierId,
    }
  );
  const query = trpc.tenantWidgetInspectionsDeviationLast12Months.useQuery(
    {
      tenantId,
      supplierId,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    {
      initialData: [],
      select(data) {
        return data.map((d) => {
          const month = format(new Date(d.date), "MMM");
          return { ...d, id: month };
        });
      },
    }
  );

  return (
    <ChartContentLayout error={query.error} isLoading={query.isLoading}>
      <div className="h-full w-full">
        {query.data.length === 0 && (
          <div className="pointer-events-none absolute inset-0 z-10 flex items-center justify-center">
            <TypographySmall className="text-muted-foreground">{t("no_results")}</TypographySmall>
          </div>
        )}
        <ResponsiveBar
          data={query.data}
          margin={{
            bottom: 22,
          }}
          onClick={(event) => {
            const supplierFilter = supplierQuery.data?.name
              ? { id: "supplierName", value: [supplierQuery.data.name] }
              : undefined;

            const params = parseServerTableFiltersIntoURLParams({
              filters: [supplierFilter],
              timeFilters: [
                {
                  id: "processedAt",
                  fromTime: startOfMonth(new Date(event.data.date)).toISOString(),
                  toTime: endOfMonth(new Date(event.data.date)).toISOString(),
                },
              ],
              pageIndex: 0,
              search: "",
              sorting: [
                {
                  desc: true,
                  id: "processedAt",
                },
              ],
              filterOrder: supplierFilter ? ["supplierName", "processedAt"] : ["processedAt"],
            });
            navigate(`/tenants/${tenantId}/inspections?${params.toString()}`);
          }}
          axisLeft={null}
          enableGridX={false}
          enableGridY={false}
          axisBottom={{
            // Overwrite the default tick, otherwise the text in dark mode is not visible
            renderTick: (props) => {
              return (
                <g transform={`translate(${props.x},${props.y})`}>
                  <text
                    x={0}
                    y={0}
                    dy={16}
                    textAnchor="middle"
                    fontSize={12}
                    fill="currentColor"
                    transform={`rotate(${props.rotate})`}
                  >
                    {props.value}
                  </text>
                </g>
              );
            },
          }}
          keys={["numberOfInspectionsWithDeviations", "numberOfInspectionsWithoutDeviations"]}
          enableLabel={false}
          borderRadius={0}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={["hsl(var(--timp))", "hsl(var(--primary))"]}
          tooltip={(e: BarTooltip<(typeof query)["data"][0]>) => {
            return (
              <div className="z-50 overflow-hidden rounded-md bg-primary px-1.5 py-1.5 text-xs text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2">
                <div className="flex flex-col gap-y-1">
                  <strong>{format(new Date(e.data.date), "MMM yyyy")}</strong>
                  <span>
                    {t("number_of_inspections")}: {e.data.numberOfInspections ?? 0}
                  </span>
                  <span>
                    {t("inspections_without_deviations")}:{" "}
                    {e.data.numberOfInspectionsWithoutDeviations ?? 0}
                  </span>
                  <span>
                    {t("inspections_with_deviations")}:{" "}
                    {e.data.numberOfInspectionsWithDeviations ?? 0}
                  </span>
                </div>
              </div>
            );
          }}
          valueFormat=" >-5,.1~%"
        />
      </div>
    </ChartContentLayout>
  );
}
