import { LinkButton } from "@/components/buttons/link-button";
import {
  MutationActionsMenu,
  MutationDeleteAction,
  MutationEditAction,
} from "@/components/dropdown-menus/mutation-actions-menu";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { DataTable } from "@/components/tables/data-table";
import { DataTableLink } from "@/components/ui/data-table-link";
import { RelativeDate } from "@/components/ui/relative-date";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";

export function TenantSettingsDepartmentsPage() {
  const tenantId = useTenantIdSafe();
  const {
    data = [],
    isLoading,

    error,
  } = trpc.tenantDepartmentsList.useQuery({
    tenantId,
  });
  const { t } = useTranslation();
  const { mutate: deleteGroup } = trpc.tenantDepartmentsDeleteById.useMutation();

  return (
    <Page isLoading={isLoading} error={error}>
      <PageToolbar>
        <PageTitle>{t("departments_title")}</PageTitle>
        <PageActions>
          <LinkButton to="./create">{t("department_create")}</LinkButton>
        </PageActions>
      </PageToolbar>
      <PageContent>
        <DataTable
          columnsStorageKey="tenant-settings-departments-page"
          isLoading={isLoading}
          error={error}
          data={data}
          columns={[
            {
              id: "name",
              title: t("department_name"),
              render(row) {
                return <DataTableLink to={`./${row.id}/edit`}>{row.name}</DataTableLink>;
              },
            },

            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
            },
            {
              id: "id",
              title: t("actions"),
              actions: true,
              enableSorting: false,
              size: 40,
              render: (row) => (
                <MutationActionsMenu data-testid={row.name}>
                  <MutationEditAction to={`./${row.id}/edit`} />
                  <MutationDeleteAction
                    name={row.name}
                    onConfirmDelete={() => deleteGroup({ id: row.id, tenantId })}
                  />
                </MutationActionsMenu>
              ),
            },
          ]}
          searchColumn="name"
          defaultSortColumn="name"
        />
      </PageContent>
    </Page>
  );
}
