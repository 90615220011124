import * as z from "zod";

export const tenantCasesProcessInputSchema = z.object({
  id: z.string().uuid(),
  deviations: z.array(
    z.object({
      id: z.string().uuid().optional(),
      severity: z.enum(["LOW", "MEDIUM", "HIGH"]),
      description: z.string().min(1),
    })
  ),
  processedComment: z.string().min(1),
});

export const tenantCasesProcessOutputSchema = z.object({
  id: z.string().uuid(),
});
