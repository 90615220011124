import { Button } from "@/components/ui/button";
import { FormControl } from "@/components/ui/form";
import { useTranslation } from "react-i18next";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { CheckIcon, ChevronDownIcon, SlidersHorizontalIcon, XIcon } from "lucide-react";
import { CollapsedBadges } from "@/components/badges/collapsed-badges";
import { type TRPCError, trpc } from "@/lib/providers/trpc";
import { Spinner } from "../icons/spinner";
import { useRef, useState } from "react";
import { Badge } from "../ui/badge";
import { CommandList } from "cmdk";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export type Option = {
  id: string;
  name?: string;
  isNew?: boolean;
  disabled?: boolean;
};

export type OptionWithName = {
  id: string;
  name: string;
  disabled?: boolean;
  isNew?: boolean;
};

export type Filter = {
  id: string;
  label: string;
  options: { value: string; label: string }[];
  defaultValue?: string;
};

export type ActiveFilter = {
  id: string;
  value: string;
};

export type ValueProps = {
  values: Option[];
  className?: string;
  disabled?: boolean;
  onChange: (values: Option[]) => void;
  collapseAt?: number;
};

export interface Props extends ValueProps {
  filteredOptions?: Option[];
  options: Option[];
  isLoading?: boolean;
  error?: Error | TRPCError | null;
  footer?: JSX.Element | null;
  onOpenAutoFocus?: (event: Event) => void;
  filters?: Filter[];
  activeFilters?: ActiveFilter[];
  onFiltersChange?: (filters: ActiveFilter[]) => void;
}

function FilterPopover({
  filters,
  activeFilters,
  onFilterChange,
}: {
  filters: Filter[];
  activeFilters: ActiveFilter[];
  onFilterChange: (filterId: string, value: string) => void;
}) {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="h-full px-2 hover:bg-transparent hover:text-muted-foreground"
        >
          <SlidersHorizontalIcon className="h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-2" align="end" side="right">
        <div className="flex flex-col gap-2">
          {filters.map((filter) => (
            <div key={filter.id} className="space-y-1">
              <span className="text-sm font-medium">{filter.label}</span>
              <Select
                value={activeFilters.find((f) => f.id === filter.id)?.value ?? filter.defaultValue}
                onValueChange={(value) => onFilterChange(filter.id, value)}
              >
                <SelectTrigger className="h-8">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {filter.options.map((option) => (
                    <SelectItem key={option.value} value={option.value}>
                      {option.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
}

export function SelectMultipleBase({
  values,
  onChange,
  filteredOptions,
  options,
  isLoading,
  error,
  footer,
  disabled,
  className,
  collapseAt,
  onOpenAutoFocus,
  filters = [],
  activeFilters = [],
  onFiltersChange,
}: Props) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [searchText, setSearchText] = useState("");

  const selectedOptions = values.map(
    (opt) => options.find((option) => option.id === opt.id) as OptionWithName
  );

  const baseFilteredOptions = filteredOptions ?? options;

  const selectableOptions = baseFilteredOptions.filter(
    (option) => !option.disabled || values.some((v) => v.id === option.id)
  );

  const searchFilteredOptions = selectableOptions.filter((option) =>
    (option.name || "").toLowerCase().includes(searchText.toLowerCase())
  );

  const handleFilterChange = (filterId: string, value: string) => {
    if (onFiltersChange) {
      const newFilters = activeFilters.map((f) => (f.id === filterId ? { ...f, value } : f));
      onFiltersChange(newFilters);
    }
  };

  const totalSelectableCount = options.length;
  const isAllSelected = values.length === totalSelectableCount;

  const handleSelectAll = () => {
    const current = new Map(values.map((val) => [val.id, val]));
    searchFilteredOptions.forEach((option) => {
      current.set(option.id, option);
    });
    onChange(Array.from(current.values()));
  };

  const handleDeselectAll = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange([]);
  };

  return (
    <div ref={containerRef} className={cn(disabled && "!cursor-not-allowed", "w-full", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              type="button"
              variant="outline"
              // biome-ignore lint/a11y/useSemanticElements: we don't want a native <select> component, we use the shadcn Button
              role="combobox"
              className={cn("h-9 w-full justify-between px-2", !values && "text-muted-foreground")}
              data-testid="select-multiple-trigger"
              disabled={disabled}
            >
              <CollapsedBadges
                variant="secondary"
                items={selectedOptions}
                collapseAt={collapseAt}
              />
              <ChevronDownIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent
          container={containerRef.current}
          className="p-0"
          align="start"
          onOpenAutoFocus={onOpenAutoFocus}
        >
          <Command
            loop
            className="w-full"
            filter={(value: string, search: string) => {
              if (value === "select-all") return 1;
              const searchKey =
                baseFilteredOptions.find((opti) => opti.id === value)?.name ?? value;
              return searchKey.toLowerCase().includes(search.toLowerCase()) ? 1 : 0;
            }}
          >
            <div className="flex w-full border-b relative">
              <div className="flex-auto">
                <CommandInput
                  placeholder={t("search")}
                  className="h-9 pr-10"
                  value={searchText}
                  onValueChange={setSearchText}
                />
              </div>
              {filters.length > 0 && (
                <div className="absolute right-0 top-0 bottom-0 flex items-center mr-2">
                  <FilterPopover
                    filters={filters}
                    activeFilters={activeFilters}
                    onFilterChange={handleFilterChange}
                  />
                </div>
              )}
            </div>

            <CommandList className="max-h-64 overflow-auto">
              <CommandEmpty>{t("no_results")}</CommandEmpty>
              {isLoading && (
                <CommandGroup>
                  <div className="flex h-full w-full items-center justify-center">
                    <Spinner size="sm" />
                  </div>
                </CommandGroup>
              )}
              {error && (
                <CommandGroup>
                  <div className="flex h-full w-full items-center justify-center">
                    <span className="text-destructive">{error.message}</span>
                  </div>
                </CommandGroup>
              )}
              {searchFilteredOptions.length > 0 && (
                <CommandItem
                  key="select-all"
                  value="select-all"
                  type="button"
                  className="p-2 group"
                  data-testid="select-multiple-select-all-toggle"
                  onSelect={handleSelectAll}
                >
                  <span className="flex-1 flex items-center gap-2 text-muted-foreground">
                    {t("select_all")}
                    <span className="text-sm">
                      ({values.length}/{totalSelectableCount})
                    </span>
                  </span>
                  {values.length > 0 && (
                    <Button
                      variant="ghost"
                      size="sm"
                      className="h-4 w-4 p-0 opacity-70 hover:opacity-100 hover:bg-destructive hover:text-destructive-foreground flex items-center justify-center"
                      onClick={handleDeselectAll}
                    >
                      <XIcon className="h-3.5 w-3.5" />
                    </Button>
                  )}
                </CommandItem>
              )}
              {searchFilteredOptions.map((option) => (
                <CommandItem
                  asChild
                  value={option.id}
                  key={option.id}
                  onSelect={() => {
                    const current = new Map(values.map((val) => [val.id, val]));
                    if (current.has(option.id)) {
                      current.delete(option.id);
                    } else {
                      current.set(option.id, option);
                    }
                    onChange(Array.from(current.values()));
                  }}
                >
                  {option.name || t("notAvailable")}
                  {option.isNew && (
                    <Badge className="ml-1 border text-gray-500" variant="secondary">
                      {t("new")}
                    </Badge>
                  )}
                  {option.disabled && (
                    <Badge className="ml-1 border text-gray-500" variant="secondary">
                      {t("disabled")}
                    </Badge>
                  )}
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      values.map((v) => v.id).includes(option.id) ? "opacity-100" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandList>
            {footer}
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
