import { DataTable } from "@/components/tables/data-table";
import { trpc } from "@/lib/providers/trpc";
import { useTranslation } from "react-i18next";
import { RelativeDate } from "@/components/ui/relative-date";
import { LinkButton } from "@/components/buttons/link-button";
import { DataTableLink } from "@/components/ui/data-table-link";
import {
  MutationActionsMenu,
  MutationDeleteAction,
  MutationEditAction,
} from "@/components/dropdown-menus/mutation-actions-menu";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { BookCheckIcon, BookDashedIcon } from "lucide-react";

export function SysAdminCaseFormsPage() {
  const { t } = useTranslation();

  const {
    data = [],
    isLoading,
    error,
  } = trpc.sysadminFormTemplatesList.useQuery({
    types: ["CASE"],
  });

  const { mutate: deleteMutation } = trpc.sysadminFormTemplatesDeleteById.useMutation();

  return (
    <Page isLoading={isLoading} error={error}>
      <PageToolbar>
        <PageTitle>{t("form_title_case")}</PageTitle>
        <PageActions>
          <LinkButton to="./create">{t("form_create")}</LinkButton>
        </PageActions>
      </PageToolbar>

      <PageContent>
        <DataTable
          columnsStorageKey="sys-admin-case-forms-page"
          isLoading={isLoading}
          error={error}
          data={data}
          columns={[
            {
              id: "name",
              title: t("name"),
              render(row) {
                return <DataTableLink to={`./${row.id}/edit`}>{row.name}</DataTableLink>;
              },
            },
            {
              id: "published",
              title: t("published"),
              render(row) {
                if (row.published) {
                  return (
                    <div className="flex items-center gap-x-1">
                      <BookCheckIcon className="size-4" />
                      {t("published")}
                    </div>
                  );
                }
                return (
                  <div className="flex items-center gap-x-1">
                    <BookDashedIcon className="size-4" />
                    {t("draft")}
                  </div>
                );
              },
            },
            {
              id: "updatedAt",
              title: t("updated_at"),
              render: (row) => <RelativeDate date={row.updatedAt} />,
            },
            {
              id: "createdAt",
              title: t("created_at"),
              render: (row) => <RelativeDate date={row.createdAt} />,
            },
            {
              id: "id",
              title: t("actions"),
              actions: true,
              enableSorting: false,
              size: 40,
              render(row) {
                return (
                  <MutationActionsMenu data-testid={row.name}>
                    <MutationEditAction to={`./${row.id}/edit`} />
                    <MutationDeleteAction
                      name={row.name}
                      onConfirmDelete={() => deleteMutation({ id: row.id })}
                    />
                  </MutationActionsMenu>
                );
              },
            },
          ]}
          searchColumn="name"
          defaultSortColumn="name"
        />
      </PageContent>
    </Page>
  );
}
