import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { SelectManageButton } from "./select-manage-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { useRole } from "@/hooks/useUser";
import { SelectMultipleBase, type ValueProps } from "./select-multiple";

export function SelectMultipleAutomaticCases({
  onChange,
  values,
  disabled,
  className,
}: ValueProps) {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const role = useRole(tenantId);

  const { data, isLoading, error } = trpc.tenantAutomaticCasesList.useQuery({
    tenantId,
  });

  const options =
    data?.rows?.map((opt) => ({
      name: opt.title,
      id: opt.id,
    })) ?? [];

  const footer =
    role === "ADMIN" ? (
      <SelectManageButton to={`/tenants/${tenantId}/settings/automatic-cases`}>
        {t("manage_automatic_cases")}
      </SelectManageButton>
    ) : null;

  return (
    <SelectMultipleBase
      className={className}
      disabled={disabled}
      options={options}
      isLoading={isLoading}
      error={error}
      values={values}
      onChange={onChange}
      footer={footer}
    />
  );
}
