import { BanIcon, CheckCircleIcon, CircleDotIcon } from "lucide-react";
import { forwardRef } from "react";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { useTranslation } from "react-i18next";
import { TypographyMuted } from "../ui/typography";
import { cn } from "@/lib/utils";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
import { Label } from "../ui/label";

export type FeatureFlagValue = "DISABLED" | "OPTIONAL" | "REQUIRED";

interface FeatureFlagInputProps {
  name: string;
  value: FeatureFlagValue;
  onValueChange: (value: FeatureFlagValue) => void;
}

export const FeatureFlagInput = forwardRef<HTMLDivElement, FeatureFlagInputProps>(
  ({ name, value, onValueChange }, ref) => {
    const { t } = useTranslation();
    return (
      <ToggleGroup
        ref={ref}
        type="single"
        value={value}
        onValueChange={(value) => {
          if (value) onValueChange(value as FeatureFlagValue);
        }}
        id={name}
        size="sm"
        className="w-fit gap-0 rounded-lg border"
      >
        <ToggleGroupItem
          value="DISABLED"
          aria-label={t("disabled")}
          className="gap-x-2 rounded-none px-3"
        >
          {/* <BanIcon className="h-4 w-4" /> */}
          <TypographyMuted className={cn(value === "DISABLED" && "text-primary")}>
            {t("disabled")}
          </TypographyMuted>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="OPTIONAL"
          aria-label={t("optional")}
          className="gap-x-2 rounded-none px-3"
        >
          {/* <CircleDotIcon className="h-4 w-4" /> */}
          <TypographyMuted className={cn(value === "OPTIONAL" && "text-primary")}>
            {t("optional")}
          </TypographyMuted>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="REQUIRED"
          aria-label={t("required")}
          className="gap-x-2 rounded-none px-3"
        >
          {/* <CheckCircleIcon className="h-4 w-4" /> */}
          <TypographyMuted className={cn(value === "REQUIRED" && "text-primary")}>
            {t("required")}
          </TypographyMuted>
        </ToggleGroupItem>
      </ToggleGroup>
    );
  }
);

FeatureFlagInput.displayName = "FeatureFlagInput";
