import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { tenantLocationsUpdateByIdInputSchema } from "@timp/server/src/schemas/tenant-locations-update-by-id.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate, useParams } from "react-router-dom";
import { LinkButton } from "@/components/buttons/link-button";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";

export function TenantSettingsLocationsEditPage() {
  const navigation = useNavigate();

  const { id } = useParams();
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();

  const { mutate: mutateLocation, isLoading: locationIsLoading } =
    trpc.tenantLocationsUpdateById.useMutation({
      onSuccess() {
        navigation("../");
      },
    });
  const { data, isLoading, error } = trpc.tenantLocationsById.useQuery({
    tenantId,
    id: id!,
  });

  const form = useForm<z.infer<typeof tenantLocationsUpdateByIdInputSchema>>({
    resolver: zodResolver(tenantLocationsUpdateByIdInputSchema),
    values: data,
  });

  return (
    <Page size="container" isLoading={isLoading} error={error}>
      <PageToolbar>
        <PageTitle backLink>
          {t("edit")} {data?.name}
        </PageTitle>
      </PageToolbar>

      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) =>
              mutateLocation({
                ...data,
                tenantId,
              })
            )}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("location_name")}</FormLabel>
                  <FormControl>
                    <Input placeholder="Demo" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={locationIsLoading} type="submit">
                {t("save_changes")}
              </Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
