import { cn } from "@/lib/utils";
import { FormControl } from "../ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { useTranslation } from "react-i18next";
import type { LanguageType } from "@timp/server/generated/db-types";

interface Props {
  onChange: (value: LanguageType) => void;
  value: LanguageType | undefined;
  className?: string;
  disabled?: boolean;
}
export function SelectLanguage({ onChange, value, disabled, className }: Props) {
  const { t } = useTranslation();
  return (
    <Select onValueChange={onChange} value={value} disabled={disabled}>
      <FormControl>
        <SelectTrigger
          className={cn(disabled && "cursor-not-allowed", "truncate", className)}
          data-testid="select-language"
        >
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectItem value="en" data-testid="select-language-item">
          {t("language_english")}
        </SelectItem>

        <SelectItem value="no" data-testid="select-language-item">
          {t("language_norwegian")}
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
