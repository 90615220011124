import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { sysadminUsersInsertInputSchema } from "@timp/server/src/schemas/sysadmin-users-insert.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "@/components/buttons/link-button";
import { Input } from "@/components/ui/input";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { Switch } from "@/components/ui/switch";
import { Spinner } from "@/components/icons/spinner";
import { SelectLanguage } from "@/components/selects/select-language";

export function SysAdminUsersCreatePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const insertUser = trpc.sysadminUsersInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const form = useForm<z.infer<typeof sysadminUsersInsertInputSchema>>({
    resolver: zodResolver(sysadminUsersInsertInputSchema),
    defaultValues: {
      isSysAdmin: false,
    },
  });

  const emailValidation = trpc.validateEmail.useMutation({
    onSuccess(validationResult) {
      if (validationResult.suggestedEmail) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check_suggestion", {
            email: validationResult.suggestedEmail,
          }),
        });
      } else if (!validationResult.isValid) {
        form.setError("email", {
          type: "manual",
          message: t("email_invalid_please_check"),
        });
      } else {
        form.clearErrors("email");
      }
    },
  });
  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle backLink>{t("create_user")}</PageTitle>
      </PageToolbar>
      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => insertUser.mutate({ ...data }))}
            className="space-y-8"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("email")}</FormLabel>

                  <div className="relative">
                    <FormControl>
                      <Input
                        placeholder="olanordmann@example.no"
                        {...field}
                        onBlur={() => {
                          form.trigger("email").then((isValid) => {
                            if (isValid) {
                              emailValidation.mutate({ email: field.value });
                            }
                          });
                        }}
                      />
                    </FormControl>
                    {emailValidation.isLoading && (
                      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <Spinner />
                      </div>
                    )}
                  </div>
                  {emailValidation.data?.suggestedEmail ? (
                    <button
                      type="button"
                      className="inline [&>p]:hover:underline"
                      onClick={() => {
                        field.onChange(emailValidation.data.suggestedEmail);
                        emailValidation.reset();
                        form.clearErrors("email");
                      }}
                    >
                      <FormMessage />
                    </button>
                  ) : (
                    <FormMessage />
                  )}
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("first_name")}</FormLabel>
                  <FormControl>
                    <Input placeholder="Ola" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("last_name")}</FormLabel>
                  <FormControl>
                    <Input placeholder="Nordmann" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="language"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("language")}</FormLabel>
                  <FormControl>
                    <SelectLanguage value={field.value} onChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="isSysAdmin"
              render={({ field }) => (
                <FormItem className="flex items-center gap-x-2 space-y-0">
                  <FormLabel required={false}>{t("is_sys_admin")}</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      name={field.name}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={insertUser.isLoading}>{t("create_user")}</Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
