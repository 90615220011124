import * as z from "zod";

export const tenantSuppliersUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  tenantId: z.string().uuid(),
  name: z.string().min(1).max(250),
  organizationNumber: z.string().min(1).max(250),
  language: z.enum(["en", "no"]),
  internalId: z.string().min(1).max(250).optional(),
  groups: z.array(z.string().uuid()),
  projects: z.array(z.string().uuid()),
  departments: z.array(z.string().uuid()),
  automaticCases: z.array(z.string().uuid()),
  mandatoryDocumentCategories: z.array(z.string().uuid()),
  status: z.enum(["CAN_USE", "SHOULD_NOT_BE_USED", "OK"]).optional(),
});

export const tenantSuppliersUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
