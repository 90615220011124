import { LinkButton } from "@/components/buttons/link-button";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { trpc } from "@/lib/providers/trpc";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { tenantSuppliersDocumentsInsertInputSchema } from "@timp/server/src/schemas/tenant-supplier-documents-insert.schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate, useSearchParams } from "react-router-dom";
import type { z } from "zod";
import { useSupplierIdSafe } from "@/hooks/useSupplier";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { SelectSingleDocumentCategory } from "@/components/selects/select-single";
import { FileUploadInput } from "@/components/inputs/file-upload-input";
import { Calendar } from "@/components/ui/calendar";
import { addDays, addYears, format, startOfDay } from "date-fns";
import { CalendarIcon } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn, fileToBase64Content } from "@/lib/utils";
import { useEffect, useState } from "react";
import { FileActionButton } from "@/components/buttons/file-action-button";

export function SupplierDocumentsCreatePage() {
  const supplierId = useSupplierIdSafe();
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showUploadInput, setShowUploadInput] = useState(false);

  const createMutation = trpc.tenantSupplierDocumentsInsert.useMutation({
    onSuccess() {
      navigate("../");
    },
  });

  const form = useForm<z.infer<typeof tenantSuppliersDocumentsInsertInputSchema>>({
    resolver: zodResolver(tenantSuppliersDocumentsInsertInputSchema),
    defaultValues: {
      supplierId,
      documentCategoryId: searchParams.get("documentCategoryId"),
      name: searchParams.get("name") ?? undefined,
    },
  });
  const documentCategoryId = form.watch("documentCategoryId");
  useEffect(() => {
    if (!documentCategoryId) {
      form.setValue("expiresAt", null);
    }
  }, [form, documentCategoryId]);

  const minStartDate = startOfDay(addDays(new Date(), 1));

  const locked = searchParams.get("locked") === "true";
  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle backLink>{t("create_document")}</PageTitle>
      </PageToolbar>
      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((values) =>
              createMutation.mutate({ ...values, tenantId, supplierId })
            )}
            className="flex flex-col gap-y-8"
          >
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("name")}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="documentCategoryId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={locked}>{t("document_category")}</FormLabel>
                  <FormControl>
                    <SelectSingleDocumentCategory
                      disabled={locked}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                  <FormDescription>{t("document_category_description")}</FormDescription>
                </FormItem>
              )}
            />

            {!!documentCategoryId && (
              <FormField
                control={form.control}
                name="expiresAt"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel
                      required={false}
                      className={cn(!documentCategoryId && "text-muted-foreground")}
                    >
                      {t("expires_at")}
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button variant="outline" disabled={!documentCategoryId}>
                            {field.value ? format(new Date(field.value), "PPP") : null}
                            <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value ? new Date(field.value) : undefined}
                          defaultMonth={new Date(field.value ?? minStartDate)}
                          onSelect={(date) =>
                            date ? field.onChange(startOfDay(date).toISOString()) : null
                          }
                          fromDate={
                            field.value
                              ? new Date(
                                  Math.min(minStartDate.getTime(), new Date(field.value).getTime())
                                )
                              : minStartDate
                          }
                          toDate={addYears(new Date(), 10)}
                          initialFocus
                          captionLayout="dropdown-buttons"
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                    <FormDescription>
                      {t("supplier_document_expires_at_description")}
                    </FormDescription>
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="file"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-center">
                  <FormLabel required>{t("upload_document")}</FormLabel>
                  <FormControl>
                    {!field.value || showUploadInput ? (
                      <FileUploadInput
                        onFilesSelected={async (files) => {
                          setShowUploadInput(false);
                          if (files[0]) {
                            field.onChange({
                              name: files[0].name,
                              type: files[0].type,
                              size: files[0].size,
                              content: await fileToBase64Content(files[0]),
                            });
                          } else {
                            field.onChange(null);
                          }
                        }}
                      />
                    ) : (
                      <FileActionButton
                        filename={`${field.value?.name}`}
                        type="button"
                        className="flex-grow-0"
                        onClick={() => setShowUploadInput(true)}
                      >
                        {t("change")}
                      </FileActionButton>
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={createMutation.isLoading} type="submit">
                {t("create_document")}
              </Button>
            </div>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
