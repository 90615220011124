import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { tenantCasesInsertInputSchema } from "@timp/server/src/schemas/tenant-cases-insert.schema";
import { useTranslation } from "react-i18next";
import { trpc } from "@/lib/providers/trpc";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { LinkButton } from "@/components/buttons/link-button";
import { Page, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { SelectSingleFormTemplate } from "@/components/selects/select-single";
import { Checkbox } from "@/components/ui/checkbox";
import { FormBuilder } from "@/components/form-builders/form-builder";
import { SelectMultipleSuppliers } from "@/components/selects/select-multiple-suppliers";

export function CasesCreatePage() {
  const [searchParams] = useSearchParams();
  const tenantId = useTenantIdSafe();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const prefilledSupplierId = searchParams.get("supplierId");
  const { mutate, isLoading } = trpc.tenantCasesInsert.useMutation({
    onSuccess(data) {
      if (data.length > 1) {
        navigate("../");
      } else {
        navigate(`../${data[0]}`);
      }
    },
  });

  const form = useForm<z.infer<typeof tenantCasesInsertInputSchema>>({
    resolver: zodResolver(tenantCasesInsertInputSchema),
    defaultValues: {
      supplierIds: prefilledSupplierId ? [prefilledSupplierId] : [],
      formContent: {},
      autoProcessOnDeadline: false,
      autoProcessOnResponse: false,
    },
  });

  const selectedFormTemplateId = form.watch("formTemplateId");
  const selectedFormContent = trpc.tenantFormTemplatesById.useQuery(
    {
      id: selectedFormTemplateId || "",
      tenantId,
    },
    { enabled: !!selectedFormTemplateId }
  );

  useEffect(() => {
    if (!selectedFormContent.data) return;
    form.setValue("formContent", selectedFormContent.data?.formContent);
  }, [selectedFormContent.data, form]);

  // Autofill case title with form template name
  const formTemplateName = selectedFormContent.data?.name;
  useEffect(() => {
    if (formTemplateName) {
      form.setValue("title", formTemplateName);
    }
  }, [form, formTemplateName]);

  // Hack to re-render the FormBuilder when form template changes
  const [counterKey, setCounterKey] = useState(0);

  function resetFormContent() {
    if (selectedFormContent.data?.formContent) {
      form.setValue("formContent", selectedFormContent.data.formContent);
      setCounterKey((prev) => prev + 1);
    }
  }

  return (
    <Page size="container">
      <PageToolbar>
        <PageTitle
          backLink={prefilledSupplierId ? `../../suppliers/${prefilledSupplierId}/cases` : "../"}
        >
          {t("create_case")}
        </PageTitle>
      </PageToolbar>
      <PageContent scroll>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit((data) => mutate({ tenantId, ...data }))}
            className="flex flex-col gap-y-12"
          >
            <FormField
              control={form.control}
              name="supplierIds"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col">
                    <FormLabel required>{t("suppliers")}</FormLabel>
                    <SelectMultipleSuppliers
                      values={field.value?.map((id) => ({ id }))}
                      onChange={(val) => field.onChange(val.map((v) => v.id))}
                    />
                    <FormDescription>{t("case_suppliers_description")}</FormDescription>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />

            <FormField
              control={form.control}
              name="formTemplateId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("form_template")}</FormLabel>
                  <div className="flex w-full items-center gap-x-2">
                    <SelectSingleFormTemplate
                      onChange={field.onChange}
                      value={field.value}
                      type="CASE"
                    />

                    <Button
                      type="button"
                      disabled={!selectedFormTemplateId}
                      onClick={() => {
                        setDialogOpen(true);
                      }}
                    >
                      {t("edit_form")}
                    </Button>
                  </div>
                  <FormDescription>{t("case_form_template_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required>{t("case_title")}</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel required={false}>{t("message_for_supplier")}</FormLabel>
                  <FormControl>
                    <Textarea {...field} rows={5} />
                  </FormControl>
                  <FormDescription>{t("case_message_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="autoProcessOnResponse"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-y-1 space-y-0">
                  <div className="flex items-center gap-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        onCheckedChange={field.onChange}
                        name={field.name}
                        checked={field.value}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("auto_process_on_response")}</FormLabel>
                  </div>
                  <FormDescription>{t("auto_process_on_response_description")}</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="autoProcessOnDeadline"
              render={({ field }) => (
                <FormItem className="flex flex-col gap-y-1 space-y-0">
                  <div className="flex items-center gap-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        onCheckedChange={field.onChange}
                        name={field.name}
                        checked={field.value}
                      />
                    </FormControl>
                    <FormLabel required={false}>{t("enable_auto_process_on_deadline")}</FormLabel>
                  </div>
                  <FormDescription>
                    {t("enable_auto_process_on_deadline_description")}
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-4">
              <LinkButton variant="outline" to="../">
                {t("cancel")}
              </LinkButton>

              <Button isLoading={isLoading} type="submit">
                {t("create_case")}
              </Button>
            </div>

            <Dialog
              open={dialogOpen}
              onOpenChange={(open) => {
                setDialogOpen(open);
              }}
            >
              <DialogContent size="full">
                <FormBuilder key={counterKey} />
                <div className="flex w-full justify-end gap-x-2">
                  <Button type="button" variant="outlineDestructive" onClick={resetFormContent}>
                    {t("reset")}
                  </Button>
                  <Button
                    type="button"
                    onClick={() =>
                      form.trigger("formContent").then((success) => {
                        if (success) setDialogOpen(false);
                      })
                    }
                  >
                    {t("save_changes")}
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </form>
        </Form>
      </PageContent>
    </Page>
  );
}
