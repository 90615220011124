import { cn } from "@/lib/utils";
import { SupplierStatusIconLabel, type SupplierStatus } from "../labels/supplier-status-icon-label";
import { FormControl } from "../ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

interface Props {
  onChange: (value: SupplierStatus) => void;
  value: SupplierStatus | undefined;
  className?: string;
  disabled?: boolean;
}
export function SelectRiskEvaluation({ onChange, value, disabled, className }: Props) {
  return (
    <Select onValueChange={onChange} value={value} disabled={disabled}>
      <FormControl>
        <SelectTrigger
          className={cn(disabled && "cursor-not-allowed", "truncate", className)}
          data-testid="select-risk-evaluation"
        >
          <SelectValue />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <SelectItem value="OK" data-testid="select-risk-evaluation-item">
          <SupplierStatusIconLabel status="OK" />
        </SelectItem>

        <SelectItem value="CAN_USE" data-testid="select-risk-evaluation-item">
          <SupplierStatusIconLabel status="CAN_USE" />
        </SelectItem>

        <SelectItem value="SHOULD_NOT_BE_USED" data-testid="select-risk-evaluation-item">
          <SupplierStatusIconLabel status="SHOULD_NOT_BE_USED" />
        </SelectItem>
      </SelectContent>
    </Select>
  );
}
