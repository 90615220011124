import { AuthContext } from "@/lib/providers/auth-provider";
import { type RouterOutput, trpc } from "@/lib/providers/trpc";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTenantId } from "./useTenant";
import { useCurrentApp } from "./useCurrentApp";

type UserRole = RouterOutput["usersMe"]["tenants"][number]["role"];

export function useUser() {
  const user = useContext(AuthContext);

  if (!user) {
    throw new Error("useUser must be used within a AuthProvider");
  }

  return user;
}

export function useRole(tenantId: string | undefined) {
  const user = useUser();
  return user.tenants.find((tenant) => tenant.tenantId === tenantId)?.role ?? null;
}

export function isLoggedIn() {
  const user = trpc.usersMe.useQuery();
  return { ...user, data: !!user.data?.id };
}

export function useRoleGuard(...roles: UserRole[]) {
  const { isInspectorApp } = useCurrentApp();
  const navigate = useNavigate();
  const tenantId = useTenantId();
  const role = useRole(tenantId);
  useEffect(() => {
    const shouldRedirectToInspectorApp = !isInspectorApp && role === "INSPECTOR";

    if (shouldRedirectToInspectorApp) {
      window.location.href = "/inspector";
    } else if (role && !roles.includes(role)) {
      navigate("/", {
        replace: true,
      });
    }
  }, [roles, role, navigate, isInspectorApp]);
}

export function useSysAdminGuard() {
  const navigate = useNavigate();
  const user = useUser();
  const isSysAdmin = user.isSysAdmin;
  useEffect(() => {
    if (!isSysAdmin) {
      navigate("/", {
        replace: true,
      });
    }
  }, [isSysAdmin, navigate]);
}
