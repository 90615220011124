import { cn } from "@/lib/utils";
import { labelVariants } from "./label";
import { forwardRef } from "react";

interface Props {
  children: React.ReactNode;
  className?: string;
}
export const TypographyH1 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <h1
        ref={ref}
        className={cn(
          "no-drag scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl",
          className
        )}
        {...restProps}
      >
        {children}
      </h1>
    );
  }
);

export const TypographyH2 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <h2
        ref={ref}
        className={cn(
          "no-drag scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight first:mt-0",
          className
        )}
        {...restProps}
      >
        {children}
      </h2>
    );
  }
);

export const TypographyH3 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <h3
        ref={ref}
        className={cn("no-drag scroll-m-20 text-2xl font-semibold tracking-tight", className)}
        {...restProps}
      >
        {children}
      </h3>
    );
  }
);

export const TypographyH4 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <h4
        ref={ref}
        className={cn("no-drag scroll-m-20 text-xl font-semibold tracking-tight", className)}
        {...restProps}
      >
        {children}
      </h4>
    );
  }
);

export const TypographyH5 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <h4
        ref={ref}
        className={cn("no-drag scroll-m-20 text-lg font-semibold tracking-tight", className)}
        {...restProps}
      >
        {children}
      </h4>
    );
  }
);

export const TypographyH6 = forwardRef<HTMLHeadingElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <h4
        ref={ref}
        className={cn("no-drag text-md scroll-m-20 font-semibold tracking-tight", className)}
        {...restProps}
      >
        {children}
      </h4>
    );
  }
);

export const TypographyP = forwardRef<HTMLParagraphElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <p
        ref={ref}
        className={cn("no-drag leading-7 [&:not(:first-child)]:mt-6", className)}
        {...restProps}
      >
        {children}
      </p>
    );
  }
);

export const TypographyBlockquote = forwardRef<HTMLQuoteElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <blockquote
        ref={ref}
        className={cn("no-drag mt-6 border-l-2 pl-6 italic", className)}
        {...restProps}
      >
        {children}
      </blockquote>
    );
  }
);

export const TypographyLarge = forwardRef<HTMLDivElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <div ref={ref} className={cn("no-drag text-lg font-semibold", className)} {...restProps}>
        {children}
      </div>
    );
  }
);

export const TypographySmall = forwardRef<HTMLSpanElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <small
        ref={ref}
        className={cn("no-drag text-sm font-medium leading-none", className)}
        {...restProps}
      >
        {children}
      </small>
    );
  }
);

export const TypographyMuted = forwardRef<HTMLParagraphElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <p
        ref={ref}
        className={cn("no-drag text-sm text-muted-foreground", className)}
        {...restProps}
      >
        {children}
      </p>
    );
  }
);

export const TypographyLabel = forwardRef<HTMLSpanElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <span ref={ref} className={cn(labelVariants(), className)} {...restProps}>
        {children}
      </span>
    );
  }
);

export const TypographyError = forwardRef<HTMLParagraphElement, Props>(
  ({ children, className, ...restProps }, ref) => {
    return (
      <p
        ref={ref}
        className={cn("text-[0.8rem] font-medium text-destructive", className)}
        {...restProps}
      >
        {children}
      </p>
    );
  }
);

export const TypographyBlank = forwardRef<HTMLSpanElement, { className?: string }>(
  ({ className, ...restProps }, ref) => {
    return (
      <span ref={ref} className={cn("no-drag select-none", className)} {...restProps}>
        &nbsp;
      </span>
    );
  }
);
