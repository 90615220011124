import type React from "react";
import { useRef, useState } from "react";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";

interface SignatureInputProps {
  onChange?: (signature: string) => void;
  disabled?: boolean;
  initialSignature?: string;
}

export const SignatureInput: React.FC<SignatureInputProps> = ({
  onChange,
  disabled = false,
  initialSignature,
}) => {
  const [signaturePath, setSignaturePath] = useState(initialSignature || "");
  const [isDrawing, setIsDrawing] = useState(false);
  const svgRef = useRef<SVGSVGElement>(null);
  const { t } = useTranslation();

  const getCoordinates = (e: React.MouseEvent | React.TouchEvent): string => {
    if (!svgRef.current) return "";
    const rect = svgRef.current.getBoundingClientRect();

    if ("touches" in e) {
      const touch = e.touches[0];
      return `${touch.clientX - rect.left},${touch.clientY - rect.top}`;
    }
    return `${(e as React.MouseEvent).clientX - rect.left},${(e as React.MouseEvent).clientY - rect.top}`;
  };

  const startDrawing = (e: React.MouseEvent | React.TouchEvent) => {
    if (disabled) return;
    const coords = getCoordinates(e);
    setSignaturePath((prev) => (prev ? `${prev} M${coords}` : `M${coords}`));
    setIsDrawing(true);
    if ("touches" in e) e.preventDefault();
  };

  const draw = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing || disabled) return;
    const coords = getCoordinates(e);
    setSignaturePath((prev) => `${prev}L${coords} `);
    if ("touches" in e) e.preventDefault();
  };

  const stopDrawing = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDrawing) return;
    setIsDrawing(false);
    if (onChange) {
      onChange(signaturePath);
    }
    if ("touches" in e) e.preventDefault();
  };

  const clearSignature = () => {
    setSignaturePath("");
    if (onChange) {
      onChange("");
    }
  };

  return (
    <div className="w-full max-w-[500px]">
      {/* biome-ignore lint/a11y/noSvgWithoutTitle: we don't do accessibility */}
      <svg
        ref={svgRef}
        height={150}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={stopDrawing}
        className={cn(
          "h-full w-full touch-none overflow-hidden rounded-md",
          !disabled && "cursor-crosshair bg-yellow-100"
        )}
      >
        <line
          x1="5%"
          y1="80%"
          x2="95%"
          y2="80%"
          stroke="#666666"
          strokeWidth="1"
          strokeDasharray="2"
          shapeRendering="crispEdges"
          pointerEvents="none"
        />
        <path d={signaturePath} stroke="#000000" strokeWidth="2" fill="none" pointerEvents="none" />
      </svg>
      {!disabled && (
        <Button type="button" variant="outline" onClick={clearSignature} className="mt-2">
          {t("clear")}
        </Button>
      )}
    </div>
  );
};
