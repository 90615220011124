import { type VariantProps, cva } from "class-variance-authority";
import { Loader2Icon } from "lucide-react";

const spinnerVariants = cva("animate-spin", {
  variants: {
    size: {
      sm: "size-4",
      md: "size-5",
      lg: "size-8",
    },
  },
  defaultVariants: {
    size: "sm",
  },
});

interface Props extends VariantProps<typeof spinnerVariants> {
  className?: string;
}

export function Spinner({ className, size }: Props) {
  return <Loader2Icon className={spinnerVariants({ size, className })} />;
}

export function CenteredSpinner({ className, size }: Props) {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Spinner className={className} size={size} />
    </div>
  );
}
