import * as z from "zod";

export const tenantDepartmentsInsertInputSchema = z.object({
  name: z.string().min(1).max(250),
  supplierIds: z.array(z.string().uuid()).optional(),
});

export const tenantDepartmentsInsertOutputSchema = z.object({
  id: z.string().uuid(),
});
