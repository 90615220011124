import type { LinkProps } from "react-router-dom";
import { type ButtonProps, buttonVariants } from "../ui/button";
import { cn } from "@/lib/utils";
import { Link } from "@/components/links/Link";

interface LinkButtonProps
  extends LinkProps,
    Pick<ButtonProps, "variant" | "className" | "size" | "disabled"> {}

export function LinkButton({
  className,
  variant,
  size,
  children,
  disabled,
  ...props
}: LinkButtonProps) {
  return (
    <Link
      {...props}
      className={cn(
        disabled && "pointer-events-none select-none opacity-50",
        buttonVariants({ variant, className, size }),
        className
      )}
    >
      {children}
    </Link>
  );
}

interface ExternalLinkButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    Pick<ButtonProps, "variant" | "className" | "size" | "disabled"> {}
export function ExternalLinkButton({
  className,
  variant,
  size,
  children,
  disabled,
  ...props
}: ExternalLinkButtonProps) {
  return (
    <a
      {...props}
      className={cn(
        disabled && "pointer-events-none select-none opacity-50",
        "no-underline",
        buttonVariants({ variant, className, size }),
        className
      )}
    >
      {children}
    </a>
  );
}
