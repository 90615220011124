import { useBlocker } from "react-router-dom";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../ui/dialog";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import { TypographyMuted } from "../ui/typography";
import { useEffect } from "react";

interface Props {
  hasUnsavedChanges: boolean;
}

function onPageLeave(event: BeforeUnloadEvent) {
  event.preventDefault();
  return event;
}

export const UnsavedChangesBlocker = ({ hasUnsavedChanges }: Props) => {
  const { t } = useTranslation();
  const { state, proceed, reset } = useBlocker(hasUnsavedChanges);

  useEffect(() => {
    if (hasUnsavedChanges) {
      window.addEventListener("beforeunload", onPageLeave);
    }

    return () => {
      window.removeEventListener("beforeunload", onPageLeave);
    };
  }, [hasUnsavedChanges]);

  return (
    <Dialog onOpenChange={() => reset?.()} open={state === "blocked"}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t("you_have_unsaved_changes")}</DialogTitle>
        </DialogHeader>
        <TypographyMuted>{t("you_have_unsaved_changes_description")}</TypographyMuted>
        <DialogFooter className="justify-end gap-x-2 gap-y-2">
          <Button size="sm" variant="destructive" onClick={() => proceed?.()}>
            {t("discard_changes")}
          </Button>
          <Button size="sm" onClick={() => reset?.()}>
            {t("continue_editing")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
