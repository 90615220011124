import { cn } from "@/lib/utils";
import type { PropsWithChildren } from "react";
import { NavLink } from "@/components/links/Link";
import type { NavLinkProps } from "react-router-dom";

export function RouteMenuLink({
  to,
  children,
  className,
  ...props
}: PropsWithChildren<{ to: string; className?: string } & NavLinkProps>) {
  return (
    <NavLink
      {...props}
      to={to}
      className={({ isActive }: { isActive: boolean }) => {
        return cn(
          "flex w-full items-center gap-x-1.5 rounded-md p-2 text-sm text-background/80 transition-opacity hover:bg-zinc-800 dark:text-primary/80",
          className,
          isActive && "bg-zinc-800"
        );
      }}
    >
      {children}
    </NavLink>
  );
}
