/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spinner } from "@/components/icons/spinner";
import { DataTableFacetedFilter } from "@/components/ui/data-table-faceted-filter";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { Updater } from "@tanstack/react-table";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataTableColumnHeader } from "../ui/data-table-column-header";
import {
  Pagination,
  PaginationButton,
  PaginationContent,
  PaginationItem,
  PaginationNextButton,
  PaginationPreviousButton,
} from "../ui/pagination";
import { TypographyMuted } from "../ui/typography";
import {
  type ColumnTimeFilterState,
  filterFn,
  getServerFacetedUniqueValues,
  parseServerTableFiltersIntoURLParams,
  parseURLFiltersIntoServerTableFilters,
} from "./table-utils";
import type { Table as TableType } from "@tanstack/react-table";
import { useDebounceValue, useLocalStorage } from "usehooks-ts";

export interface ExtendedColumnFilter {
  id: string;
  value: string[];
}

type DataColumn<TData> = {
  disabled?: boolean;
  id: keyof TData extends string ? keyof TData : never;
  actions?: boolean;
  title: string;
  filter?: boolean;
  timeFilter?: boolean;
  render?: (value: TData) => React.ReactNode | string | number | React.ReactNode[];
  accessorFn?: (row: TData) => any;
  valueToLabel?: (value: any) => string;
  enableSorting?: boolean;
  size?: number;
  minSize?: number;
  maxSize?: number;
};

type ExtractRowsType<T> = T extends { rows: infer R } ? (R extends (infer U)[] ? U : never) : never;
interface Props<QueryProcedure extends AnyQueryProcedure, U, V extends string> {
  // Data fetching
  query: DecorateProcedure<QueryProcedure, U, V>;
  params: Omit<inferProcedureInput<QueryProcedure>, "query">;
  // Table stuff
  columns: DataColumn<ExtractRowsType<inferProcedureOutput<QueryProcedure>>>[];
  columnsStorageKey: string;
  searchColumns?: (keyof ExtractRowsType<inferProcedureOutput<QueryProcedure>> & string)[];
  defaultSortColumn: keyof ExtractRowsType<inferProcedureOutput<QueryProcedure>> & string;
  defaultSortOrder?: "desc" | "asc";
  paginationPageSize: number;
}

import type { DecorateProcedure } from "@trpc/react-query/shared";
import type { AnyQueryProcedure, inferProcedureInput, inferProcedureOutput } from "@trpc/server";
import { bufferToBase64String, cn } from "@/lib/utils";
import type { TRPCClientErrorLike } from "@trpc/react-query";
import { Button } from "../ui/button";
import { DownloadIcon, PlusCircleIcon, XIcon } from "lucide-react";
import { trpc } from "@/lib/providers/trpc";
import { toast } from "sonner";
import { DataTableDateFilter } from "../ui/data-table-time-filter";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { PopoverClose } from "@radix-ui/react-popover";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { DataTableViewOptions } from "../ui/data-table-view-options";

export function ServerDataTable<QueryProcedure extends AnyQueryProcedure, U, V extends string>({
  query,
  params,
  columns,
  searchColumns,
  defaultSortColumn,
  columnsStorageKey,
  paginationPageSize = 25,
  defaultSortOrder = "asc",
}: Props<QueryProcedure, U, V>) {
  const { t, i18n } = useTranslation();
  const urlParams = parseURLFiltersIntoServerTableFilters(window.location.search);

  // Use useLocalStorage hook for columnVisibility
  const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>(
    `storage-${columnsStorageKey}-columns-visibility`,
    Object.fromEntries(columns.map((c) => [c.id, true]))
  );

  const [sorting, setSorting] = useState<SortingState>(
    urlParams?.sort || [
      {
        desc: defaultSortOrder === "desc",
        id: defaultSortColumn,
      },
    ]
  );

  const [pageIndex, setPageIndex] = useState(urlParams?.page || 0);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(urlParams?.filters || []);
  const [columnTimeFilters, setColumnTimeFilters] = useState<ColumnTimeFilterState[]>(
    urlParams?.timeFilters || []
  );
  const [searchValue, setSearchValue] = useState(urlParams?.search || "");
  const [debouncedSearchValue] = useDebounceValue(searchValue, 300);

  const [variablesChangedAt, setVariablesChangedAt] = useState<number | null>(null);

  const [filterOrder, setFilterOrder] = useState<string[]>(urlParams?.filterOrder ?? []);
  const [openFilterId, setOpenFilterId] = useState<string | null>(null);

  const variables = useMemo(() => {
    setVariablesChangedAt(Date.now());
    const availableColumns = new Set(columns.map((c) => c.id as string));

    return {
      ...params,
      query: {
        // sorting
        sorting: sorting.filter((s) => availableColumns.has(s.id)),
        search:
          searchColumns?.length && debouncedSearchValue
            ? { columns: searchColumns, value: debouncedSearchValue }
            : undefined,
        pagination: {
          pageIndex,
          pageSize: paginationPageSize,
        },
        // filters
        activeFilters: columnFilters.filter((col) => availableColumns.has(col.id)),
        filters: columns.filter((c) => c.filter).map((c) => c.id),

        // time filters
        activeTimeFilters: columnTimeFilters.filter((col) => availableColumns.has(col.id)),
        timeFilters: columns.filter((c) => c.timeFilter).map((c) => c.id),
      },
    };
  }, [
    params,
    sorting,
    searchColumns,
    debouncedSearchValue,
    pageIndex,
    paginationPageSize,
    columnFilters,
    columns,
    columnTimeFilters,
  ]);

  const {
    data,
    error,
    isLoading,
    isRefetching,
    dataUpdatedAt,
    trpc: trpcQuery,
  } = query.useQuery(variables, {
    keepPreviousData: true,
    select(data): {
      rows: any;
      totalCount: number;
      filters: { id: string; values: { value: string; count: number }[] }[];
      timeFilters: { id: string; minTime: string; maxTime: string }[];
    } {
      return data;
    },
  });

  const showUpdateSpinner =
    variablesChangedAt && dataUpdatedAt < variablesChangedAt && isRefetching;

  const rows = useMemo(() => data?.rows ?? [], [data]);
  const totalCount = data?.totalCount ?? 0;
  const filters = data?.filters ?? [];
  const timeFilters = data?.timeFilters ?? [];

  const tableCols: ColumnDef<NonNullable<typeof data>["rows"][number]>[] = columns
    .filter((c) => !c.disabled)
    .map((c) => {
      return {
        accessorKey: c.id,
        enableSorting: c.enableSorting,
        header: ({ column }) => <DataTableColumnHeader column={column} title={c.title} />,
        size: c.size,
        maxSize: c.maxSize,
        minSize: c.minSize,
        cell: ({ row }) => (c.render ? c.render(row.original) : row.getValue(c.id)),
        filterFn,
        accessorFn: c.accessorFn,
        meta: filters.find((f) => f.id === c.id),
        enableHiding: true,
      } satisfies ColumnDef<NonNullable<typeof data>["rows"]>;
    });
  const columnTitles = columns.map((c) => ({ id: c.id, title: c.title }));

  const onSortingChange = useCallback((newSorting: Updater<SortingState>) => {
    setPageIndex(0);
    setSorting(newSorting);
  }, []);

  const onColumnFiltersChange = useCallback((newFilters: Updater<ColumnFiltersState>) => {
    setPageIndex(0);
    setColumnFilters((prevFilters) => {
      const updatedFilters =
        typeof newFilters === "function" ? newFilters(prevFilters) : newFilters;

      // Update filter order
      setFilterOrder((prevOrder) => {
        const newOrder = [...prevOrder];
        updatedFilters.forEach((filter) => {
          if (!newOrder.includes(filter.id)) {
            newOrder.push(filter.id);
          }
        });
        // Keep all existing filters in the order, including the open one
        return newOrder;
      });

      return updatedFilters;
    });
  }, []);

  useEffect(() => {
    const params = parseServerTableFiltersIntoURLParams({
      pageIndex,
      sorting,
      search: debouncedSearchValue,
      filters: columnFilters,
      timeFilters: columnTimeFilters,
      filterOrder,
    });
    window.history.replaceState({}, "", `?${params.toString()}`);
  }, [debouncedSearchValue, columnFilters, pageIndex, sorting, columnTimeFilters, filterOrder]);

  const table = useReactTable({
    data: rows,
    columns: tableCols,
    manualSorting: true,
    manualFiltering: true,
    manualPagination: true,
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getServerFacetedUniqueValues(),
    getFacetedRowModel: getFacetedRowModel(),
    onSortingChange: onSortingChange,
    onColumnFiltersChange: onColumnFiltersChange,
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
  });

  function clearAllFilters() {
    setColumnFilters([]);
    setColumnTimeFilters([]);
    setSearchValue("");
    setFilterOrder([]);
  }
  const utils = trpc.useUtils();
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const downloadAsExcel = useCallback(async () => {
    try {
      setIsLoadingDownload(true);

      const exceljs = await import("exceljs");

      // 1. call procedure without pagination
      // @ts-expect-error - ??
      const data = await utils[trpcQuery.path].fetch({
        ...variables,
        query: { ...variables.query, pagination: undefined },
      });
      const rows = data.rows as Record<string, any>[];

      // 2. create and populate worksheet
      const workbook = new exceljs.Workbook();
      const worksheet = workbook.addWorksheet();
      const visibleCols = columns.filter((c) => !c.disabled && c.title && !c.actions);
      worksheet.columns = visibleCols.map((col) => ({ key: col.id, header: col.title, width: 20 }));
      worksheet.addRows(
        rows.map((row) =>
          visibleCols.map((col) => {
            const value = row[col.id];
            if (!value) return "";
            if (Array.isArray(value)) {
              return value.join(";");
            }
            return value;
          })
        )
      );

      // 3. download .xlsx file
      const buffer = await workbook.xlsx.writeBuffer();
      const base64String = bufferToBase64String(buffer as Uint8Array);
      const link = document.createElement("a");
      link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64String}`;
      link.download = `data-${new Date().toLocaleString(i18n.language)}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      if (err instanceof Error) {
        toast.error(err.message);
      }
    } finally {
      setIsLoadingDownload(false);
    }
  }, [columns, i18n.language, trpcQuery.path, utils, variables]);

  const hasAnyOpenFilter = columns.some((c) => {
    const column = table.getColumn(c.id as string);
    return (
      (column && c.filter && column?.getFilterValue()) ||
      (column && c.timeFilter && columnTimeFilters.map((f) => f.id).includes(c.id))
    );
  });

  const filterableColumns = useMemo(() => {
    if (variablesChangedAt) {
      return columns.filter(
        (c) =>
          (c.filter || c.timeFilter) &&
          table.getColumn(c.id as string)?.getIsVisible() &&
          !c.disabled
      );
    }
    return [];
  }, [columns, table, variablesChangedAt]);

  const totalVisibleCols = table.getAllColumns().filter((c) => c.getIsVisible()).length;

  const availableFilters = useMemo(() => {
    return filterableColumns.filter((c) => {
      const column = table.getColumn(c.id as string);
      return (
        (column && c.filter && !column.getFilterValue()) ||
        (column && c.timeFilter && !columnTimeFilters.map((f) => f.id).includes(c.id))
      );
    });
  }, [filterableColumns, table, columnTimeFilters]);

  const sortedFilterableColumns = useMemo(() => {
    return [...filterableColumns].sort((a, b) => {
      const aIndex = filterOrder.indexOf(a.id);
      const bIndex = filterOrder.indexOf(b.id);

      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });
  }, [filterableColumns, filterOrder]);

  const [animateRef] = useAutoAnimate();
  return (
    <div className="flex flex-1 flex-col overflow-hidden">
      <div
        className="flex min-h-12 flex-row items-center gap-x-2 overflow-y-hidden pl-0.5"
        ref={animateRef}
      >
        {searchColumns?.length && (
          <Input
            placeholder={t("search")}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            className="w-[150px] lg:w-[250px]"
          />
        )}
        {availableFilters.length > 0 && (
          <div>
            <Popover>
              <PopoverTrigger asChild>
                <Button variant="outline" size="sm">
                  <PlusCircleIcon className="size-4" />
                  {t("add_filter")}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-60 p-2">
                <div className="flex flex-col gap-y-2">
                  {availableFilters.map((c) => (
                    <PopoverClose asChild key={c.id}>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setOpenFilterId(c.id);
                        }}
                      >
                        {c.title}
                      </Button>
                    </PopoverClose>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
        )}

        {sortedFilterableColumns.map((c) => {
          const column = table.getColumn(c.id as string);
          if (column && c.filter && (openFilterId === c.id || column.getFilterValue())) {
            return (
              <DataTableFacetedFilter
                key={c.id}
                column={column}
                title={c.title}
                valueToLabel={c.valueToLabel}
                open={openFilterId === c.id}
                onOpenChange={(val) => {
                  if (!val) {
                    setOpenFilterId(null);
                    // Remove from filter order if it's closed and has no value
                    if (!column.getFilterValue()) {
                      setFilterOrder((prevOrder) => prevOrder.filter((id) => id !== c.id));
                    }
                  } else {
                    setOpenFilterId(c.id);
                    // Ensure it's in the filter order when opened
                    setFilterOrder((prevOrder) =>
                      prevOrder.includes(c.id) ? prevOrder : [...prevOrder, c.id]
                    );
                  }
                }}
              />
            );
          }
          if (column && c.timeFilter) {
            const timeFilter = timeFilters.find((f) => f.id === c.id);
            const hasTimeRange = timeFilter?.minTime && timeFilter?.maxTime;
            const fromDate = columnTimeFilters.find((f) => f.id === c.id)?.fromTime ?? null;
            const toDate = columnTimeFilters.find((f) => f.id === c.id)?.toTime ?? null;
            if (hasTimeRange && (openFilterId === c.id || fromDate || toDate)) {
              return (
                <DataTableDateFilter
                  open={openFilterId === c.id}
                  key={c.id}
                  title={c.title}
                  minDate={timeFilter?.minTime}
                  maxDate={timeFilter?.maxTime}
                  fromDate={fromDate}
                  toDate={toDate}
                  onChange={(fromTime, toTime) => {
                    setColumnTimeFilters((prevFilters) => {
                      const newFilters = prevFilters.filter((f) => f.id !== c.id);
                      if (fromTime || toTime) {
                        newFilters.push({ id: c.id, fromTime, toTime });

                        // Update filter order
                        setFilterOrder((prevOrder) => {
                          if (!prevOrder.includes(c.id)) {
                            return [...prevOrder, c.id];
                          }
                          return prevOrder;
                        });
                      }
                      return newFilters;
                    });
                  }}
                  onOpenChange={(val) => {
                    if (!val) {
                      setOpenFilterId(null);
                      // Remove from filter order if it's closed and has no value
                      setColumnTimeFilters((prevFilters) => {
                        const timeFilter = prevFilters.find((f) => f.id === c.id);
                        if (!timeFilter || (!timeFilter.fromTime && !timeFilter.toTime)) {
                          setFilterOrder((prevOrder) => prevOrder.filter((id) => id !== c.id));
                        }
                        return prevFilters;
                      });
                    } else {
                      setOpenFilterId(c.id);
                      // Ensure it's in the filter order when opened
                      setFilterOrder((prevOrder) =>
                        prevOrder.includes(c.id) ? prevOrder : [...prevOrder, c.id]
                      );
                    }
                  }}
                />
              );
            }
          }
          return null;
        })}
        {hasAnyOpenFilter && (
          <Button
            type="button"
            variant="secondary"
            size="sm"
            className="h-8"
            onClick={() => clearAllFilters()}
          >
            <XIcon className="size-3.5" />
            {t("clear_filters")}
          </Button>
        )}
        <div className="ml-auto flex justify-end gap-x-1">
          <DataTableViewOptions table={table} columnTitles={columnTitles} />
          <Button
            type="button"
            variant="secondary"
            size="sm"
            className="ml-auto h-8"
            onClick={downloadAsExcel}
            isLoading={isLoadingDownload}
          >
            {!isLoadingDownload && <DownloadIcon className="size-4" />}
            {t("export_to_excel")}
          </Button>
        </div>
      </div>
      <div
        className={cn(
          "relative mb-0.5 flex flex-grow flex-col overflow-hidden rounded-md border",
          showUpdateSpinner && "pointer-events-none select-none opacity-50"
        )}
      >
        {showUpdateSpinner && (
          <div
            className="absolute inset-0 z-50 flex h-full w-full items-center justify-center"
            data-testid="server-data-table-update-spinner"
          >
            <Spinner size="lg" />
          </div>
        )}
        <div className="relative w-full flex-1 overflow-auto">
          <Table
            className="flex-1"
            data-testid="server-data-table-element"
            data-updated-at={dataUpdatedAt}
          >
            <ServerTableHeader table={table} totalVisibleCols={totalVisibleCols} />
            <ServerTableBody
              table={table}
              rows={rows}
              totalVisibleCols={totalVisibleCols}
              isLoading={isLoading}
              error={error}
            />
          </Table>
        </div>

        <ServerTablePagination
          pageIndex={pageIndex}
          pageSize={paginationPageSize}
          totalServersideRows={totalCount}
          setPageIndex={setPageIndex}
        />
      </div>
    </div>
  );
}

type ServerTablePaginationProps = {
  pageIndex: number;
  pageSize: number;
  totalServersideRows: number;
  setPageIndex: (index: number) => void;
};

function ServerTablePagination({
  pageIndex,
  pageSize,
  totalServersideRows,
  setPageIndex,
}: ServerTablePaginationProps) {
  const { t, i18n } = useTranslation();
  const numberOfPages = Math.ceil(totalServersideRows / pageSize);
  const canGetPreviousPage = pageIndex > 0;
  const canGetNextPage = pageIndex < numberOfPages - 1;

  const previousPage = () => {
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    setPageIndex(pageIndex + 1);
  };

  return (
    <div
      className="col-rows-2 grid grid-cols-3 items-center p-1.5"
      data-testid="server-data-table-pagination"
    >
      <Pagination className="col-span-1 col-start-2">
        <PaginationContent>
          <PaginationItem>
            <PaginationPreviousButton
              data-testid="server-data-table-pagination-previous-page"
              disabled={!canGetPreviousPage}
              onClick={() => previousPage()}
            />
          </PaginationItem>
          {!canGetNextPage && pageIndex - 2 >= 0 && (
            <PaginationItem>
              <PaginationButton
                data-testid={`server-data-table-pagination-page-${pageIndex - 1}`}
                onClick={() => setPageIndex(pageIndex - 2)}
              >
                {pageIndex - 1}
              </PaginationButton>
            </PaginationItem>
          )}
          {canGetPreviousPage && pageIndex - 1 >= 0 && (
            <PaginationItem>
              <PaginationButton
                data-testid={`server-data-table-pagination-page-${pageIndex}`}
                onClick={() => previousPage()}
              >
                {pageIndex}
              </PaginationButton>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationButton
              data-testid={`server-data-table-pagination-page-${pageIndex + 1}`}
              isActive
            >
              {pageIndex + 1}
            </PaginationButton>
          </PaginationItem>
          {canGetNextPage && pageIndex + 1 < numberOfPages && (
            <PaginationItem>
              <PaginationButton
                data-testid={`server-data-table-pagination-page-${pageIndex + 2}`}
                onClick={() => nextPage()}
              >
                {pageIndex + 2}
              </PaginationButton>
            </PaginationItem>
          )}
          {!canGetPreviousPage && pageIndex + 2 < numberOfPages && (
            <PaginationItem>
              <PaginationButton
                data-testid={`server-data-table-pagination-page-${pageIndex + 2}`}
                onClick={() => setPageIndex(pageIndex + 2)}
              >
                {pageIndex + 3}
              </PaginationButton>
            </PaginationItem>
          )}
          <PaginationItem>
            <PaginationNextButton
              data-testid="server-data-table-pagination-next-page"
              disabled={!canGetNextPage}
              onClick={() => nextPage()}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
      <TypographyMuted
        data-testid="server-data-table-pagination-stats"
        className="mb:col-span-1 col-span-3 row-start-2 w-full whitespace-nowrap pr-1 text-center md:col-span-1 md:col-start-3 md:row-start-1 md:text-right"
      >
        {t("pagination_summary", {
          from: pageIndex * pageSize,
          to: Math.min((pageIndex + 1) * pageSize, totalServersideRows),
          total: totalServersideRows.toLocaleString(i18n.language),
        })}
      </TypographyMuted>
    </div>
  );
}

const ServerTableHeader = memo(
  ({ table }: { table: TableType<unknown>; totalVisibleCols: number }) => {
    return (
      <TableHeader className="sticky top-0 z-20 bg-background shadow-sm">
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const { size, minSize, maxSize } = header.column.columnDef;
              return (
                <TableHead
                  key={header.id}
                  style={{ width: size, maxWidth: maxSize, minWidth: minSize }}
                  data-testid="server-data-table-header-cell"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHead>
              );
            })}
          </TableRow>
        ))}
      </TableHeader>
    );
  }
);

interface ServerTableBodyProps {
  table: TableType<unknown>;
  rows: any[];
  error: TRPCClientErrorLike<AnyQueryProcedure> | null;
  isLoading: boolean;
  totalVisibleCols: number;
}
const ServerTableBody = memo(
  ({ table, error, isLoading, totalVisibleCols }: ServerTableBodyProps) => {
    const { t } = useTranslation();
    return (
      <TableBody
        className={cn("relative flex-1 overflow-auto")}
        data-testid={!isLoading && !error ? "data-table-body-loaded" : "data-table-body-loading"}
      >
        {table.getRowModel().rows?.length ? (
          table.getRowModel().rows.map((row) => (
            <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
              {row.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} className="relative overflow-hidden">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={totalVisibleCols}>
              <span className="flex items-center justify-center">
                {error?.message}
                {isLoading && <Spinner size="sm" />}
                {!isLoading && !error && t("no_results")}
              </span>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  }
);
