import { cn } from "@/lib/utils";
import type { PropsWithChildren } from "react";
import { NavLink } from "@/components/links/Link";

export function NavigationNavLink({
  to,
  children,
  className,
  ...props
}: PropsWithChildren<{ to: string; className?: string }>) {
  return (
    <NavLink
      {...props}
      className={({ isActive }: { isActive: boolean }) => {
        return cn(
          "block p-2 text-sm font-semibold text-background/70 transition-opacity hover:text-background dark:text-primary/80",
          isActive && "text-background dark:text-primary",
          className
        );
      }}
      to={to}
    >
      {children}
    </NavLink>
  );
}
