import * as z from "zod";

export const tenantAutomatedCasesUpdateByIdInputSchema = z.object({
  id: z.string().uuid(),
  title: z.string().min(1).optional(),
  supplierIds: z.array(z.string().uuid()).min(1).optional(),
  formTemplateId: z.string().uuid().optional(),
  message: z.string().nullable().optional(),
  startSendoutAt: z.string().datetime().optional(),
  stopSendoutAt: z.string().datetime().nullable().optional(),
  interval: z.object({
    type: z.enum(["days", "months", "years"]),
    amount: z.number().int().positive(),
  }),
  autoProcessOnResponse: z.boolean().optional(),
  autoProcessOnDeadline: z.boolean().optional(),
});

export const tenantAutomatedCasesUpdateByIdOutputSchema = z.object({
  id: z.string().uuid(),
});
