import { useTranslation } from "react-i18next";
import { type RouterOutput, trpc } from "@/lib/providers/trpc";
import { useTenantIdSafe } from "@/hooks/useTenant";
import { Page, PageActions, PageContent, PageTitle, PageToolbar } from "@/components/layouts/page";
import { useParams, useNavigate } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  TypographyLabel,
  TypographyLarge,
  TypographyMuted,
  TypographySmall,
} from "@/components/ui/typography";
import { CheckCircle2Icon, Trash2Icon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RelativeDate } from "@/components/ui/relative-date";
import { FileActionButton } from "@/components/buttons/file-action-button";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { FormViewer } from "@/components/form-viewers/form-viewer";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { ScrollArea } from "@/components/ui/scroll-area";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { tenantInspectionsProcessInputSchema } from "@timp/server/src/schemas/tenant-inspections-process.schema";
import { Textarea } from "@/components/ui/textarea";
import {
  SelectSingleProject,
  SelectSingleDepartment,
  SelectSingleLocation,
  SelectSingleSupplier,
  SelectSingleEquipment,
} from "@/components/selects/select-single";
import { PartialFormDeviationsList } from "@/components/form-partials/partial-form-deviations-list";
import { DeleteDialog } from "@/components/dropdown-menus/mutation-actions-menu";
import { DeviationListSection, ProcessedFormSection } from "./inspections-details-components";
import { GenerateInspectionPdfContent } from "@/components/pdf/generate-inspection-pdf-content";

type Inspection = RouterOutput["tenantInspectionsById"];
type InspectionFormTemplate = Extract<
  RouterOutput["tenantFormTemplatesById"],
  { type: "INSPECTION" }
>;
export function InspectionsDetailsPage() {
  const { t } = useTranslation();
  const tenantId = useTenantIdSafe();
  const { inspectionId } = useParams();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);

  const inspectionQuery = trpc.tenantInspectionsById.useQuery({
    id: inspectionId!,
    tenantId,
  });
  const formTemplate = trpc.tenantFormTemplatesById.useQuery(
    {
      id: inspectionQuery.data?.formTemplateId!,
      tenantId,
    },
    {
      enabled: !!inspectionQuery.data?.formTemplateId,
      select: (data) => data as InspectionFormTemplate,
    }
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const deleteMutation = trpc.tenantInspectionDeleteById.useMutation({
    onSuccess() {
      navigate("../", {
        unstable_flushSync: true,
      });
    },
  });

  const currentTab =
    inspectionQuery.data?.status === "PROCESSED" ? "finished_processing" : "waiting_for_processing";
  const status = inspectionQuery.data?.status;

  return (
    <Page
      size="container"
      isLoading={inspectionQuery.isLoading || formTemplate.isInitialLoading}
      error={inspectionQuery.error || formTemplate.error}
    >
      <PageToolbar>
        <PageTitle backLink>{inspectionQuery.data?.formTemplateName}</PageTitle>
        <PageActions>
          <Button variant="outlineDestructive" onClick={() => setDeleteDialogOpen(true)}>
            <Trash2Icon className="size-4" />
            {t("delete_inspection")}
          </Button>

          <GenerateInspectionPdfContent
            buttonText={t("export_pdf")}
            inspection={inspectionQuery.data!}
          />
        </PageActions>
      </PageToolbar>
      <DeleteDialog
        isLoading={deleteMutation.isLoading}
        name={inspectionQuery.data?.formTemplateName}
        onConfirmDelete={() => {
          deleteMutation.mutate({ id: inspectionId!, tenantId });
        }}
        setOpen={setDeleteDialogOpen}
        open={deleteDialogOpen}
      />
      <PageContent scroll>
        <Tabs value={currentTab} className="space-y-8">
          <TabsList className="h-auto w-full overflow-x-auto p-2">
            <div className="flex w-full">
              <TabsTrigger
                value="waiting_for_processing"
                className="flex basis-1/2 flex-col items-start justify-center p-3"
                disabled={currentTab !== "waiting_for_processing"}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="font-semibold uppercase">{t("step")} 1</span>
                  {status === "PROCESSED" && <CheckCircle2Icon className="size-4 text-green-600" />}
                </div>
                <TypographyMuted>{t("waiting_for_processing")}</TypographyMuted>
              </TabsTrigger>
              <TabsTrigger
                value="finished_processing"
                className="flex basis-1/2 flex-col items-start justify-center p-3"
                disabled={currentTab !== "finished_processing"}
              >
                <div className="flex w-full items-center justify-between">
                  <span className="font-semibold uppercase">{t("step")} 2</span>
                  {status === "PROCESSED" && <CheckCircle2Icon className="size-4 text-green-600" />}
                </div>
                <TypographyMuted>{t("finished_processing")}</TypographyMuted>
              </TabsTrigger>
            </div>
          </TabsList>
          <TabsContent value="waiting_for_processing">
            <div className="flex flex-col gap-y-8 p-1">
              <ProcessFormSection
                inspection={inspectionQuery.data!}
                formTemplate={formTemplate.data!}
              />
            </div>
          </TabsContent>
          <TabsContent value="finished_processing">
            <div className="flex flex-col gap-y-8 p-1">
              <div className="flex flex-col gap-y-8 overflow-y-auto">
                <FileActionButton
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                  type="button"
                  filename={inspectionQuery.data?.formTemplateName!}
                >
                  {t("open_form")}
                </FileActionButton>
                <Dialog
                  open={dialogOpen}
                  onOpenChange={(open) => {
                    setDialogOpen(open);
                  }}
                >
                  <DialogContent size="full">
                    <DialogHeader>
                      <DialogTitle className="mb-2">
                        {inspectionQuery.data?.formTemplateName}
                      </DialogTitle>
                      <Separator />
                    </DialogHeader>
                    <div className="flex flex-1 overflow-hidden">
                      <FormViewer form={inspectionQuery.data?.formContent!} mode="answers-view" />
                    </div>
                  </DialogContent>
                </Dialog>
              </div>
              <ProcessedFormSection inspection={inspectionQuery.data!} />
              <DeviationListSection inspection={inspectionQuery.data!} />
            </div>
          </TabsContent>
        </Tabs>
      </PageContent>
    </Page>
  );
}

interface SectionProps {
  inspection: Inspection;
  formTemplate: InspectionFormTemplate;
}
function ProcessFormSection({ inspection, formTemplate }: SectionProps) {
  const tenantId = useTenantIdSafe();
  const [dialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();

  const schemaWithCorrectRequireFields = useMemo(() => {
    const originalSchema = tenantInspectionsProcessInputSchema;
    return originalSchema.extend({
      locationId:
        formTemplate.enableLocations === "REQUIRED"
          ? z.string().uuid()
          : originalSchema.shape.locationId,
      departmentId:
        formTemplate.enableDepartments === "REQUIRED"
          ? z.string().uuid()
          : originalSchema.shape.departmentId,
      equipmentId:
        formTemplate.enableEquipment === "REQUIRED"
          ? z.string().uuid()
          : originalSchema.shape.equipmentId,
      projectId:
        formTemplate.enableProjects === "REQUIRED"
          ? z.string().uuid()
          : originalSchema.shape.projectId,
    });
  }, [
    formTemplate.enableDepartments,
    formTemplate.enableEquipment,
    formTemplate.enableLocations,
    formTemplate.enableProjects,
  ]);

  const form = useForm<z.infer<typeof schemaWithCorrectRequireFields>>({
    resolver: zodResolver(schemaWithCorrectRequireFields),
    values: {
      id: inspection.id,
      deviations: inspection.deviations,
      comment: inspection.comment ?? "",
      supplierId: inspection.supplierId ?? undefined!,
      locationId: inspection.locationId ?? undefined,
      departmentId: inspection.departmentId ?? undefined,
      projectId: inspection.projectId ?? undefined,
      equipmentId: inspection.equipmentId ?? undefined,
    },
  });

  const processMutation = trpc.tenantInspectionsProcess.useMutation({
    onSuccess() {
      setDialogOpen(false);
    },
  });

  const [focusedDeviation, setFocusedDeviation] = useState<string | undefined>(undefined);

  return (
    <div className="flex flex-col gap-y-8 overflow-x-hidden">
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col items-center gap-x-1 md:flex-row">
          <TypographySmall>
            {t("inspection_created_by_user", {
              name: inspection.createdByInspectorName,
            })}{" "}
          </TypographySmall>
          <TypographyMuted>
            <RelativeDate date={inspection.createdAt} />
          </TypographyMuted>
        </div>
        {inspection.comment && (
          <div>
            <TypographyLabel>{t("comment")}</TypographyLabel>
            <TypographyMuted>{inspection.comment}</TypographyMuted>
          </div>
        )}
        <FileActionButton
          onClick={() => {
            setDialogOpen(true);
          }}
          type="button"
          filename={inspection.formTemplateName}
        >
          {t("process_inspection")}
        </FileActionButton>
      </div>

      <Dialog
        open={dialogOpen}
        onOpenChange={(open) => {
          setDialogOpen(open);
        }}
      >
        <DialogContent size="full">
          <DialogHeader>
            <DialogTitle className="mb-2">{inspection.formTemplateName}</DialogTitle>
            <Separator />
          </DialogHeader>

          <div className="flex flex-1 gap-x-4 overflow-hidden">
            <FormViewer form={inspection.formContent} mode="answers-view" />
            <Separator orientation="vertical" />
            <Form {...form}>
              <form
                className="flex w-1/4 flex-col gap-y-4 lg:w-1/5"
                onSubmit={form.handleSubmit((values) =>
                  processMutation.mutate({ ...values, tenantId })
                )}
              >
                <TypographyLarge>{t("process")}</TypographyLarge>
                <ScrollArea>
                  <div className="flex flex-col gap-y-8">
                    <div className="flex flex-col gap-y-2">
                      <FormField
                        control={form.control}
                        name="supplierId"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel required={true}> {t("supplier")}</FormLabel>
                            <SelectSingleSupplier value={field.value} onChange={field.onChange} />
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      {formTemplate.enableDepartments !== "DISABLED" && (
                        <FormField
                          control={form.control}
                          name="departmentId"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel required={formTemplate.enableDepartments === "REQUIRED"}>
                                {" "}
                                {t("department")}
                              </FormLabel>
                              <SelectSingleDepartment
                                value={field.value}
                                onChange={field.onChange}
                              />
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      {formTemplate.enableProjects !== "DISABLED" && (
                        <FormField
                          control={form.control}
                          name="projectId"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel required={formTemplate.enableProjects === "REQUIRED"}>
                                {" "}
                                {t("project")}
                              </FormLabel>
                              <SelectSingleProject value={field.value} onChange={field.onChange} />
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      {formTemplate.enableLocations !== "DISABLED" && (
                        <FormField
                          control={form.control}
                          name="locationId"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel required={formTemplate.enableLocations === "REQUIRED"}>
                                {" "}
                                {t("location")}
                              </FormLabel>
                              <SelectSingleLocation value={field.value} onChange={field.onChange} />
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      {formTemplate.enableEquipment !== "DISABLED" && (
                        <FormField
                          control={form.control}
                          name="equipmentId"
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel required={formTemplate.enableEquipment === "REQUIRED"}>
                                {" "}
                                {t("equipment")}
                              </FormLabel>
                              <SelectSingleEquipment
                                value={field.value}
                                onChange={field.onChange}
                              />
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}

                      <PartialFormDeviationsList
                        form={form}
                        setFocusedDeviation={setFocusedDeviation}
                        focusedDeviation={focusedDeviation}
                      />
                    </div>
                    <FormField
                      control={form.control}
                      name="comment"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel required>{t("comment")}</FormLabel>
                          <FormControl>
                            <Textarea {...field} rows={5} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </ScrollArea>

                <div className="flex gap-x-2">
                  <Button
                    type="button"
                    onClick={() => setDialogOpen(false)}
                    disabled={processMutation.isLoading}
                    variant="outline"
                    className="w-1/2"
                  >
                    {t("cancel")}
                  </Button>
                  <Button isLoading={processMutation.isLoading} type="submit" className="w-1/2">
                    {t("process")}
                  </Button>
                </div>
              </form>
            </Form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
