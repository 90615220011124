import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  useFormField,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  type UseFormReturn,
  type UseFieldArrayRemove,
  useController,
  useFieldArray,
  type UseFieldArrayMove,
  useFormContext,
  useWatch,
} from "react-hook-form";
import type { FormShemaType } from "./form-builder-types";
import { RichTextEditor } from "@/components/rich-text-editor/rich-text-editor";
import { type PropsWithChildren, useEffect, useRef, useState } from "react";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { Trash2Icon } from "lucide-react";
import { TypographyLabel, TypographySmall } from "../ui/typography";
import { useTranslation } from "react-i18next";
import { cn } from "@/lib/utils";
import { useDrag } from "@/hooks/useDrag";
import { Checkbox } from "../ui/checkbox";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { FileUploadInput } from "../inputs/file-upload-input";
import { useMutation } from "@tanstack/react-query";
import { Spinner } from "../icons/spinner";
import { Textarea } from "../ui/textarea";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { RadioGroup, RadioGroupItem } from "../ui/radio-group";
interface Props {
  sectionIdx: number;
  elementIdx: number;
  control: UseFormReturn<FormShemaType>["control"];
  remove: UseFieldArrayRemove;
  move: UseFieldArrayMove;
}

type FormBuilderBaseElementProps = PropsWithChildren<Props> & {
  hideRequired?: boolean;
};

function FormBuilderBaseElement({
  control,
  sectionIdx,
  elementIdx,
  children,
  remove,
  move,
  hideRequired,
}: FormBuilderBaseElementProps) {
  const elRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { isDragging, draggableProps } = useDrag({
    onDrag: move,
    currentIndex: elementIdx,
    group: "elements",
  });

  const type = useController({
    name: `formContent.sections.${sectionIdx}.elements.${elementIdx}.type` as const,
    control,
  });

  const field = useController({
    name: `formContent.sections.${sectionIdx}.elements.${elementIdx}` as const,
    control,
  });

  return (
    <div
      ref={elRef}
      {...draggableProps}
      data-testid="form-builder-item-box"
      className={cn(
        "group relative flex min-h-[160px] w-full flex-col gap-y-3",
        "rounded-md border p-4 shadow-sm",
        !!field.fieldState.error && "border-destructive",
        isDragging ? "border-2 border-dashed border-red-500 bg-primary/10" : "background-auto"
      )}
    >
      <div className={cn("flex select-text flex-row items-center justify-between")}>
        <TypographyLabel className="font-semibold text-muted-foreground">
          {t(`${type.field.value}`)}
        </TypographyLabel>

        {/* Actions */}
        <div className="flex items-center gap-x-2">
          {!hideRequired && (
            <FormField
              control={control}
              name={`formContent.sections.${sectionIdx}.elements.${elementIdx}.isRequired`}
              render={({ field }) => (
                <FormItem className="flex items-center gap-x-2 space-y-0">
                  <FormLabel required={false}>{t("required")}</FormLabel>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      name={field.name}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <Button
            className="h-5"
            type="button"
            variant="ghost"
            size="icon"
            onClick={() => remove(elementIdx)}
          >
            <Trash2Icon className="h-5 w-5" strokeWidth={1.5} />
          </Button>
        </div>
      </div>
      {children}
    </div>
  );
}

export function FormBuilderTextQuestionElement(props: Props) {
  const { t } = useTranslation();

  return (
    <FormBuilderBaseElement {...props}>
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.question` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required htmlFor="">
              {t("question")}
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.description` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required={false}>{t("description")}</FormLabel>
            <FormControl>
              <Textarea {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormBuilderBaseElement>
  );
}

export function FormBuilderInformationElement(props: Props) {
  return (
    <FormBuilderBaseElement {...props} hideRequired>
      <FormField
        control={props.control}
        name={`formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.text` as const}
        render={({ field }) => (
          <FormItem>
            <FormControl className="flex flex-col">
              <RichTextEditor initialValue={field.value as object[]} onChange={field.onChange} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormBuilderBaseElement>
  );
}

export function FormBuilderYesNoElement(props: Props) {
  const { t } = useTranslation();
  return (
    <FormBuilderBaseElement {...props}>
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.question` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required>{t("terms")}</FormLabel>
            <FormControl className="flex flex-col">
              <Input placeholder={t("question")} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.description` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required={false}>{t("description")}</FormLabel>
            <FormControl>
              <Textarea {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormBuilderBaseElement>
  );
}

export function FormBuilderMultipleChoiceElement(props: Props) {
  const [animationSingleRef] = useAutoAnimate();
  const [animationMultipleRef] = useAutoAnimate();
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control: props.control,
    name: `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.choices` as const,
  });

  const allowMultipleAnswers = useController({
    name: `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.allowMultipleAnswers`,
    control: props.control,
  });

  const [newChoice, setNewChoice] = useState("");

  const { setValue } = useFormContext();

  const form = useFormContext();
  /**
   * When new choice is created. Create new default answer for the element
   */
  useEffect(() => {
    const key = `sections.${props.sectionIdx}.elements.${props.elementIdx}.answer`;
    setValue(
      key,
      Array.from({ length: fields.length }).map(() => false)
    );
    form.trigger(`formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.choices`);
  }, [fields, setValue, props.sectionIdx, props.elementIdx, form.trigger]);

  const choicesField = useController({
    name: `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.choices`,
    control: props.control,
  });

  return (
    <FormBuilderBaseElement {...props}>
      <div className="flex flex-col gap-y-6">
        <FormField
          control={props.control}
          name={
            `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.question` as const
          }
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t("question")}</FormLabel>
              <FormControl className="flex flex-col">
                <Input placeholder={t("question")} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={props.control}
          name={
            `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.description` as const
          }
          render={({ field }) => (
            <FormItem>
              <FormLabel required={false}>{t("description")}</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="flex flex-col gap-y-2">
          <Tabs
            value={allowMultipleAnswers.field.value ? "multiple" : "single"}
            onValueChange={(ev) => {
              const allowMultiple = ev === "multiple";
              allowMultipleAnswers.field.onChange(allowMultiple);
            }}
          >
            <TabsList className="no-drag">
              <TabsTrigger value="single">{t("choose_one")}</TabsTrigger>
              <TabsTrigger value="multiple">{t("multiple_choice")}</TabsTrigger>
            </TabsList>
            <TabsContent value="single">
              <ul className="flex flex-col gap-y-1" ref={animationSingleRef}>
                <RadioGroup>
                  {fields.map((choice, index) => {
                    return (
                      <li key={choice.id} className="flex items-center">
                        <RadioGroupItem
                          id={choice.id}
                          value={choice.id}
                          className="no-drag mr-1 cursor-default"
                          checked={false}
                        />
                        <FormLabel htmlFor={choice.id} required={false} className="no-drag">
                          {choice.value}
                        </FormLabel>
                        <Button
                          type="button"
                          className="no-drag h-8 w-8 p-0"
                          variant="ghost"
                          onClick={() => remove(index)}
                        >
                          <Trash2Icon className="h-4 w-4" strokeWidth={1.5} />
                        </Button>
                      </li>
                    );
                  })}
                </RadioGroup>
              </ul>
            </TabsContent>
            <TabsContent value="multiple">
              <ul className="flex flex-col gap-y-2" ref={animationMultipleRef}>
                {fields.map((choice, index) => {
                  return (
                    <li key={choice.id} className="flex items-center">
                      <Checkbox
                        id={choice.id}
                        className="no-drag mr-1 cursor-default"
                        checked={false}
                      />
                      <FormLabel htmlFor={choice.id} required={false} className="no-drag">
                        {choice.value}
                      </FormLabel>
                      <Button
                        type="button"
                        className="no-drag h-8 w-8 p-0"
                        variant="ghost"
                        onClick={() => remove(index)}
                      >
                        <Trash2Icon className="h-4 w-4" strokeWidth={1.5} />
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </TabsContent>
          </Tabs>

          <div className="no-drag flex w-full max-w-[500px] items-center gap-x-1">
            <Input
              type="text"
              value={newChoice}
              className="no-drag"
              ref={choicesField.fieldState.error ? choicesField.field.ref : undefined}
              onChange={(ev) => {
                setNewChoice(ev.target.value);
              }}
              onKeyUp={(ev) => {
                if (ev.key === "Enter" && newChoice !== "") {
                  append({
                    value: newChoice,
                  });
                  setNewChoice("");
                }
              }}
            />
            <Button
              type="button"
              variant="default"
              size="sm"
              disabled={newChoice === ""}
              className="no-drag"
              onClick={() => {
                append({
                  value: newChoice,
                });
                setNewChoice("");
              }}
            >
              <span>{t("add_choice")}</span>
            </Button>
          </div>
          <FormField
            control={props.control}
            name={`formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.choices`}
            render={() => <FormMessage />}
          />
        </div>
      </div>
    </FormBuilderBaseElement>
  );
}

export function FormBuilderApprovalQuestionElement(props: Props) {
  const { t } = useTranslation();

  return (
    <FormBuilderBaseElement {...props} hideRequired>
      <div>
        <FormField
          control={props.control}
          name={
            `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.question` as const
          }
          render={({ field }) => (
            <FormItem>
              <FormLabel required>{t("terms")}</FormLabel>
              <FormControl className="flex flex-col">
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={props.control}
          name={
            `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.description` as const
          }
          render={({ field }) => (
            <FormItem>
              <FormLabel required={false}>{t("description")}</FormLabel>
              <FormControl>
                <Textarea {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </FormBuilderBaseElement>
  );
}

export function FormBuilderFileUploadElement(props: Props) {
  const { t } = useTranslation();

  return (
    <FormBuilderBaseElement {...props}>
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.question` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required>{t("question")}</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.description` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required={false}>{t("description")}</FormLabel>
            <FormControl>
              <Textarea {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormBuilderBaseElement>
  );
}

export function FormBuilderSignatureElement(props: Props) {
  const { t } = useTranslation();
  return (
    <FormBuilderBaseElement {...props}>
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.question` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required htmlFor="">
              {t("question")}
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.description` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required={false}>{t("description")}</FormLabel>
            <FormControl>
              <Textarea {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </FormBuilderBaseElement>
  );
}

export function FormBuilderAttachmentElement(props: Props) {
  const { t } = useTranslation();
  const [animationRef] = useAutoAnimate();
  const form = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control: props.control,
    name: `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.files` as const,
  });

  const fileUpload = useMutation({
    mutationFn: async (data: { index: number; file: File }) => {
      const formData = new FormData();
      formData.append("file", data.file);
      const res = await fetch("/api/rest/form-file-upload", {
        method: "POST",
        body: formData,
      });
      const responseData = await res.json();
      return responseData as { fileId: string };
    },
    onSuccess(response, { index, file }) {
      update(index, {
        fileId: response.fileId,
        metadata: file,
      });
      form.trigger(`formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.files`);
    },
  });

  function onFilesSelected(files: File[]) {
    const newFormFiles = files.map((file) => ({ fileId: "", metadata: file }));
    append(newFormFiles);

    // since `fields` is not yet updated by `append` until next react render, we must make a copy so we can keep track of the indexes
    const currentStateFiles = [...fields, ...newFormFiles];

    currentStateFiles.forEach((formFile, index) => {
      const isUploadedToServer = !!formFile.fileId;
      // the runtime value is correct, typescript can't correctly infer that metadata is supposed to be a `File` so we must cast it
      const file = formFile.metadata as File;
      if (!isUploadedToServer) {
        fileUpload.mutate({
          index,
          file,
        });
      }
    });
  }

  return (
    <FormBuilderBaseElement {...props} hideRequired>
      <FormField
        control={props.control}
        name={`formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.question`}
        render={({ field }) => (
          <FormItem>
            <FormLabel required={false}>{t("question")}</FormLabel>
            <FormControl className="flex flex-col">
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={props.control}
        name={
          `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.description` as const
        }
        render={({ field }) => (
          <FormItem>
            <FormLabel required={false}>{t("description")}</FormLabel>
            <FormControl>
              <Textarea {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={props.control}
        name={`formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.files`}
        render={({ field }) => (
          <FormItem>
            <FileUploadInput
              ref={field.ref}
              multiple
              accept={[
                "image/*",
                "text/*",
                "audio/*",
                "video/*",
                ".pdf",
                ".doc",
                ".docx",
                ".xls",
                ".xlsx",
                ".ppt",
                ".pptx",
                ".zip",
                ".rar",
                ".7z",
                ".csv",
                ".txt",
                ".json",
                ".xml",
                ".svg",
                ".webp",
                ".msg",
              ]}
              onFilesSelected={onFilesSelected}
            />

            <FormMessage />
          </FormItem>
        )}
      />

      <p className={cn("text-[0.8rem] font-medium text-destructive")} />

      <ul ref={animationRef} className="list mt-1 flex flex-col gap-y-2">
        {fields.map((item, fileIndex) => (
          <li key={item.id}>
            <FormField
              control={props.control}
              name={
                `formContent.sections.${props.sectionIdx}.elements.${props.elementIdx}.files.${fileIndex}.metadata` as const
              }
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <div className="no-drag flex items-center gap-x-2">
                      {!item.fileId && <Spinner />}
                      <TypographySmall
                        className={cn(item.fileId ? "text-primary" : "text-primary/30")}
                      >
                        {field.value.name}
                      </TypographySmall>
                      <Button
                        type="button"
                        className="h-8 w-8 p-0"
                        variant="ghost"
                        disabled={fileUpload.isLoading}
                        onClick={() => remove(fileIndex)}
                      >
                        <Trash2Icon className="h-4 w-4" strokeWidth={1.5} />
                      </Button>
                    </div>
                  </FormControl>

                  <FormMessage />
                </FormItem>
              )}
            />
          </li>
        ))}
      </ul>
    </FormBuilderBaseElement>
  );
}
