import * as z from "zod";

export const tenantUsersInsertInputSchema = z.object({
  email: z.string().email().toLowerCase().trim(),
  role: z.enum(["ADMIN", "INSPECTOR", "MANAGER"]),
});

export const tenantUsersInsertOutputSchema = z.object({
  tenantId: z.string().uuid(),
  userId: z.string().uuid(),
});
